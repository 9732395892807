import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { SimpleVariableCard } from '@/components/molecules';
import { SimpleFeatures, SimpleVariable} from "@/interfaces";
import { getIconFromName } from '@/utils/icons';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    key: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

type SimpleTabPanelProps = {
  data: SimpleFeatures;
  selectedCategory: number;
  selectedVariables: Array<SimpleVariable>;
  onCategory: (category: number) => void;
  onSelect: (variable: SimpleVariable) => void;
};

export const SimpleTabPanel: React.FC<SimpleTabPanelProps>  = ({data, selectedCategory, selectedVariables, onCategory, onSelect}) => {

  const [tabId, setTabId] = useState(selectedCategory);
  const [groupData, setGroupData] = useState<Array<any>>([]);

  useEffect(() => {
    setGroupData(data.groupData[tabId]);
  }, [data, tabId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onCategory(newValue);
    setTabId(newValue);
  };

  return (
    <Grid container spacing={2} sx={{height: '100%'}}>
      <Grid item xs={4} sx={{ height: '100%' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabId}
          onChange={handleTabChange}
          sx={{height: '100%'}}
        >
          {data.groups.map( (group, groupIdx) => (
            <Tab
              icon={getIconFromName(group, {fontSize: 'small'})}
              iconPosition="start"
              label={group}
              {...a11yProps(groupIdx)}
              sx={{ minHeight: '42px', height: '42px', justifyContent: 'flex-start', alignItems: 'flex-end'}}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={8} sx={{height: '100%', overflowY: 'auto'}}>
        <Stack spacing={2} sx={{ width: '100%' }}>
        {groupData && groupData.map((category, categoryIdx) => (
          //<Grid item xs={12} key={`category-data-${categoryIdx}`}>
            <SimpleVariableCard
              key={`category-data-${categoryIdx}`}
              title={category.name}
              variables={category.variables}
              selectedVariables={selectedVariables}
              onSelect={onSelect}
            />
          //</Grid>
        ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
