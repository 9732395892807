import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

type DrivetimeSliderProps = {
  drivetime: number;
  onDrivetime: (event: React.SyntheticEvent | Event, value: number | Array<number>) => void;
  color: 'primary' | 'secondary';
};

export const DrivetimeSlider: React.FC<DrivetimeSliderProps> = ({
  drivetime,
  onDrivetime,
  color
  }) => {

  const theme = useTheme();
  const [value, setValue] = useState<number>(drivetime);

  const handleChange = (event: Event, newValue: number | Array<number>) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  }

  return (
    <Stack direction='row' spacing={2} alignItems='center' sx={{ height: '40px' }}>
      <Slider
        aria-label="Drivetime"
        valueLabelDisplay="auto"
        color={color}
        value={value}
        onChange={handleChange}
        onChangeCommitted={onDrivetime}
        defaultValue={20}
        step={10}
        min={10}
        max={60}
        marks
        sx={{
          width: '100px',
          '& .MuiSlider-thumb': {
            backgroundColor: theme.palette[color].dark
          },
          '& .MuiSlider-track': {
            backgroundColor: theme.palette[color].dark
          },
          '& .MuiSlider-mark': {
            backgroundColor: '#FFFFFF'
          }
        }}
      />
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography sx={{ mr: 0.5, color: theme.palette[color].contrastText }}>{drivetime}</Typography>
        <Typography sx={{ mr: 0.5, color: theme.palette[color].contrastText}}>minutes</Typography>
      </Box>
    </Stack>
  )

}
