import React  from 'react';

import { DataGrid, GridColDef } from "@mui/x-data-grid";

type LoadTableProps = {
  rows: Array<any>;
  columns: GridColDef[];
};

export const LoadTable: React.FC<LoadTableProps> = ({rows, columns}) => {

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnMenu
      autoPageSize
      hideFooterSelectedRowCount
      rowHeight={36}
      sx={{
        border: 'none',
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none'
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-withBorder': {
          borderRight: 'none',
        },
        '& .MuiDataGrid-cellContent': {
          fontSize: 12,
        },
      }}
    />
  );

}
