import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ExploreVariable, ExploreFactor, Location
} from '@/interfaces';


export const ApiService = async (options: AxiosRequestConfig) => {
  try {
    const response: AxiosResponse = await axios(options);
    const { data } = response;
    return data;

  } catch (error) {
    // console.log(error);
    throw new Error();
  }
};

export const getPointFeatures = async (accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/points`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getData = async (mode: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/data?mode=${mode}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getFeatures = async (mode: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/feature?mode=${mode}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getFacts = async (level: string, ids: Array<string|number>, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/facts?level=${level}&ids=${ids.join('|')}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};


export const getFact = async (level: string, id: string|number, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/facts?level=${level}&ids=${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getScores = async (mode: string, selection: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/scores?mode=${mode}&selection=${selection}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};


export const getScorecard = async (geo_id: number, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/scorecard?geo_id=${geo_id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getComparison = async (catchment: Array<Location>, baseline: Array<Location>, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const catchmentParam = catchment.map(x => x.geo_id).join(',');
  const baselineParam = baseline[0].geo_id === 0 ? 'all' : baseline.map(x => x.geo_id).join(',');
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/compare?catchment=${catchmentParam}&baseline=${baselineParam}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getComparisonPost = async (catchment: Array<Location>, baseline: Array<Location>, baselineLocation: Location | null, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const result = {
    'catchment' : catchment.map(x => x.geo_id).join(','),
    'baseline': baseline.map(x => x.geo_id).join(','),
    'location': baselineLocation
  }
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/compare`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(result)
  };

  return await ApiService(apiConfig);
};

export const getFilterResult = async (selection: Array<ExploreVariable>, operator: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/select?selection=${selection.map(x => x.feature_id).join('|')}&operator=${operator}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getFactorResult = async (factor: ExploreFactor, buckets: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/build?factors=${factor.variables}&buckets=${buckets}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const saveMode = async (mode: string, result: object, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/${mode}/save`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(result)
  };

  return await ApiService(apiConfig);
}

export const deleteMode = async (mode: string, name: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/${mode}/delete?name=${name}`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
  };

  return await ApiService(apiConfig);
}

export const getLocations = async (base: boolean, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const url = base ? `${apiServerUrl}/locations/base` : `${apiServerUrl}/locations`;
  const apiConfig: AxiosRequestConfig = {
    url: url,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getPostcodes = async (accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/locations/postcode`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getLads = async (accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/locations/lad`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getLocationCatchment = async (id: number, level: string, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/locations/catchment?id=${id}&level=${level}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};

export const getLocationDrivetime = async (latitude: number, longitude: number, drivetime: number, accessToken: string) => {
  const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
  const apiConfig: AxiosRequestConfig = {
    url: `${apiServerUrl}/locations/drivetime?latitude=${latitude}&longitude=${longitude}&drivetime=${drivetime}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return await ApiService(apiConfig);
};
