import {CircleLayer, FillLayer, SymbolLayer} from "react-map-gl";

export const baseLayer: FillLayer = {
  id: 'base',
  type: 'fill',
  'source-layer': 'LAD_TCE_EWN',
  paint: {
    'fill-outline-color': 'rgba(0,0,0,0.1)',
    'fill-color': [
      'case', ['==', ['feature-state', 'score'], null], '#ECF3F4',
      [
      'interpolate',
      ['linear'],
      ['feature-state', 'score'],
      // 0, '#F3546D',
      // 0.2, '#ff8962',
      // 0.4, '#ffbc6c',
      // 0.5, '#ffeb90',
      // 0.6, '#c6d878',
      // 0.8, '#88c46c',
      // 1, '#3caf69',
      0, '#F3546D',
      0.375, '#FBB073',
      0.625, '#FBE566',
      1, '#3caf69',
    ]],
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'score'], null],
      0,
      [
        'case',
        ['<', ['feature-state', 'score'], 0],
        0,
        1
      ]
    ]
  }
};

export const hoverLayer: FillLayer = {
  id: 'highlight',
  type: 'fill',
  'source-layer': 'LAD_TCE_EWN',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.3
  }
};

export const selectLayer: FillLayer = {
  id: 'select',
  type: 'fill',
  'source-layer': 'LAD_TCE_EWN',
  paint: {
    'fill-outline-color': '#00b1cc',
    'fill-color': '#00b1cc',
    'fill-opacity': 1.0
  }
};

export const clusterLayer: CircleLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'points',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#212b3a', 50, '#485466', 250, '#728196'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 50, 30, 250, 40],
  }
}

export const clusterCountLayer: SymbolLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#fff'
  }
}

export const clusterPointLayer: CircleLayer = {
  id: 'cluster-point',
  type: 'circle',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    // 'circle-color': '#212b3a',
    'circle-color': [
      'match',
      ['get', 'type'],
      'Coastal',
      '#2DA419',
      'Offshore Wind',
      '#009ACE',
      'Regional',
      '#076B72',
      'Rural',
      '#2DA419',
      'Strategic Land',
      '#C3278A',
      '#212b3a'
    ],
    'circle-radius': 8,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}

export const pointLayer: CircleLayer = {
  id: 'point',
  type: 'circle',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    // 'circle-color': '#212b3a',
    'circle-color': [
      'match',
      ['get', 'type'],
      'Coastal',
      '#2DA419',
      'Offshore Wind',
      '#009ACE',
      'Regional',
      '#076B72',
      'Rural',
      '#2DA419',
      'Strategic Land',
      '#C3278A',
      '#212b3a'
    ],
    'circle-radius': 8,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}
