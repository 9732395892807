import React from 'react';

import {PageLayout} from "@/layouts/PageLayout";
import { ScorecardOverlay } from '@/components/molecules';


export const Scorecard: React.FC = () => {

  return (
    <PageLayout title='Scorecard'>
      <div className='bg-area'>
        <ScorecardOverlay />
      </div>
    </PageLayout>
  )
}
