import React from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


type ExploreFactorInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};


export const ExploreFactorInfoDialog: React.FC<ExploreFactorInfoDialogProps> = (
  {
    open,
    onClose
  }) => {

  return (
    <Dialog
      aria-labelledby="explore-factor-info-dialog"
      open={open}
      onClose={onClose}
      maxWidth='sm'
      PaperProps={{
        elevation: 2,
        sx: {
          position: 'fixed',
          top: 64,
          right: 68,
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="explore-factor-info-dialog"
      >
        Development Quality Scores
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Development Quality Scores are made up of 3/4 factors that define an sector's potential for development success.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Each factor is made up of one of more variables that a sector needs to "over index" for to be included.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Each sector is scored based on the number of factors they over index for (weight)
          and which decile their average index score across all factors sits in.
          A high weight and decile means the better the sector's development potential.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          The sectors are then assigned a bucket based on these two values.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
