import React from 'react';

import { ExploreFactScoreCard } from '@/components/molecules';

type FactSummaryProps = {
  data: any;
};

export const FactSummary: React.FC<FactSummaryProps> = ({
  data
}) => {

  return (
    <>
      <ExploreFactScoreCard
        title='Over Performing'
        scores={data['Over Performing']['Top Scores']}
        startExpanded={true}
      />
      <ExploreFactScoreCard
        title='Under Performing'
        scores={data['Under Performing']['Bottom Scores']}
        startExpanded={true}
      />
    </>
  );
};
