import React, { useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


type SelectedChipProps = {
  selected?: boolean;
}

const SelectedChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<SelectedChipProps>(({theme, selected}) => ({
  backgroundColor: selected ? theme.palette.primary.dark : theme.palette.primary.main
}));


type ChipListProps = {
  data: Array<Array<any>>;
  names: Array<string>;
  title?: string;
};


export const ExpandingGroupedChipList: React.FC<ChipListProps> = ({data, names, title}) => {

  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const onExpand = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <SelectedChip
        color='primary'
        size='small'
        label={title ? title : `${data.length} variables selected`}
        onClick={onExpand}
        onDelete={onExpand}
        deleteIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        selected={open}
        sx={{ borderRadius: '4px', mb: 1, boxShadow: theme.shadows[1]}}
      />
      <Collapse in={open}>
        {data.map((values, idx) => (
          <ListItem key={`selected-chip-${idx}`}>
            <Chip
              color='primary'
              size='small'
              // label={
              //   ['Proximity', 'Demographics', 'Lifestyle'].includes(value.group) ?
              //     value.category + ' | ' + value.type
              //     : value.source + ' | ' + value.type
              // }
              label={`${idx + 1}. ${names[idx]}`}
              sx={{ borderRadius: '4px', backgroundColor: theme.palette.primary.dark}}
            />
            {/*<Box>*/}
            {/*  <Typography variant='body2' sx={{ color: theme.palette.primary.contrastText}}>*/}
            {/*    {names[idx]}*/}
            {/*  </Typography>*/}
            {/*  {values.map((value, valueIdx) => (*/}
            {/*    <Chip*/}
            {/*      key={`selected-chip-${idx}-${valueIdx}`}*/}
            {/*      color='primary'*/}
            {/*      size='small'*/}
            {/*      // label={*/}
            {/*      //   ['Proximity', 'Demographics', 'Lifestyle'].includes(value.group) ?*/}
            {/*      //     value.category + ' | ' + value.type*/}
            {/*      //     : value.source + ' | ' + value.type*/}
            {/*      // }*/}
            {/*      label={value.type}*/}
            {/*      sx={{ borderRadius: '4px', mr: 1}}*/}
            {/*    />*/}
            {/*  ))}*/}
            {/*</Box>*/}
          </ListItem>
        ))}
      </Collapse>
    </Box>
  );

}
