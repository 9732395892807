import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ScoreCardCategory, ScoreCardScore } from "@/interfaces";
import { Tooltip } from '@/components/atoms';
import { formatPercentile, formatScore, toColor } from "@/utils/common";
import Chip from "@mui/material/Chip";
import { useTheme, darken } from "@mui/material/styles";

type ScorecardCardProps = {
  category: ScoreCardCategory;
  compare?: ScoreCardCategory;
  startExpanded: boolean;
};

export const ScorecardCard: React.FC<ScorecardCardProps> = (
  {
    category,
    compare,
    startExpanded
  }
) => {

  const theme = useTheme();
  const [subExpand, setSubExpand] = useState<boolean>(startExpanded);

  const onExpand = () => {
    setSubExpand(!subExpand);
  }

  const getScore = (category: ScoreCardCategory, value: string): ScoreCardScore => {
    return category.scores.filter(x => x.feature_name === value)[0];
  }

  return (
    <Card
      elevation={4}
      sx={{
        width: '100%'
      }}
    >
      <CardActionArea onClick={onExpand}>
        <CardHeader
          disableTypography
          title={
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography>{category.name}</Typography>
            <Box display="flex" flexDirection="row">
              <Chip
                size="small"
                label={formatScore(category.percentile_wt, 'pct_score')}
                sx={{borderRadius: '6px', minWidth: '32px', backgroundColor: toColor(category.percentile_wt), color: theme.palette.background.default}}
              />
              { compare &&
                <Chip
                  size="small"
                  label={formatScore(compare.percentile_wt, 'pct_score')}
                  sx={{ml: 5, borderRadius: '6px',  minWidth: '32px', backgroundColor: toColor(compare.percentile_wt), color: theme.palette.background.default}}
                />
              }
            </Box>
          </Box>
          }
          sx={{
            p: 1
          }}
        />
      </CardActionArea>
      {subExpand &&
        <CardContent sx={{ p: 1, '&:last-child': {pb: 1} }}>
          {category.scores.map((score, scoreIdx) => (
            <Box key={`score-card-${scoreIdx}`} display="flex" flexDirection="row" justifyContent="space-between">
              <Tooltip title={score.description} placement="left">
                <Typography variant="body2" sx={{fontSize: '12px'}}>{score.feature_name}</Typography>
              </Tooltip>
              <Box display="flex" flexDirection="row" >
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" width="65px" >
                  <Typography variant="body2" sx={{fontSize: '12px'}}>{formatScore(score.value, score.unit)}</Typography>
                  <Tooltip title={formatPercentile(score.percentile_wt)} placement="right">
                    <Box sx={{ ml: 1, height: '12px', width: '12px', backgroundColor: toColor(score.percentile_wt) }} />
                  </Tooltip>
                </Box>
                {compare ? getScore(compare, score.feature_name) ?
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" width="65px" ml={1}>
                    <Typography variant="body2" sx={{fontSize: '12px'}}>{formatScore(getScore(compare, score.feature_name).value, getScore(compare, score.feature_name).unit)}</Typography>
                    <Tooltip title={formatPercentile(getScore(compare, score.feature_name).percentile_wt)} placement="right">
                      <Box sx={{ ml: 1, height: '12px', width: '12px', backgroundColor: toColor(getScore(compare, score.feature_name).percentile_wt) }} />
                    </Tooltip>
                  </Box>
                  :
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" width="65px" ml={1}>
                    <Typography variant="body2" sx={{fontSize: '12px'}}>{'NA'}</Typography>
                    <Tooltip title={'NA'} placement="right">
                      <Box sx={{ ml: 1, height: '12px', width: '12px', backgroundColor: toColor(null) }} />
                    </Tooltip>
                  </Box>
                : null}
              </Box>
            </Box>
          ))}
        </CardContent>
      }
    </Card>
  );
}
