import React from 'react';

import Box from '@mui/material/Box';

export const Overlay = () => {

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        // backgroundColor: '#f78e0d',
        zIndex: 1000
      }}
    />
  )

}
