import React from 'react';

import { ExploreFactValueCard } from '@/components/molecules';

type Scores = {
  [key: string]: any;
}

type FactHousingProps = {
  data: Scores;
  level: string;
};

export const FactHousing:  React.FC<FactHousingProps> = ({
  data, level
}) => {

  // Combine data into one object
  const newData = Object.values(data).reduce((res, cur) => Object.assign(res, cur), {});

  // Fix some keys
  delete Object.assign(newData, {'House Stock (per person per km)': newData['House Stock'] })['House Stock'];
  delete Object.assign(newData, {'Sales (per person per km)': newData['Sales'] })['Sales'];
  delete Object.assign(newData, {'Sold Properties (per person per km)': newData['Sold Properties'] })['Sold Properties'];
  delete Object.assign(newData, {'New Builds (per person per km)': newData['New Builds'] })['New Builds'];

  return (
    <>
      <ExploreFactValueCard title='Housing' scores={newData} category={null} />
    </>
  );
};
