import React from 'react';
import Draggable from 'react-draggable';

import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";

import { ExploreVariableFact, ExploreFactResponse, ExploreVariable } from "@/interfaces";
import {
  ExploreFactorResultCard,
  ExploreFactRelatedCard, FactDemographics,
  FactHousing, FactLifestyle, FactOther,
  FactProximity,
  FactSummary
} from "@/components/molecules";
import { buildFactExport } from '@/utils/export';


export const fixSourceName = (name: string): string => {
  switch (name) {
    case 'University Subjects':
      return 'Universities Specialising In Subject';
    default:
      return name
  }
}

export const getSourceName = (category: string): string => {
  switch (category) {
    case 'Age':
      return 'Age Band';
    case 'Lifestage':
      return 'Lifestage';
    case 'Income':
      return 'Income Band';
    case 'Population':
      return 'Population';
    default:
      return category
  }
}

export const getVariableFacts = (variables: Array<ExploreVariable>, fact: ExploreFactResponse): Array<ExploreVariableFact> =>  {
  const data: Array<ExploreVariableFact> = []
  variables.forEach(variable => {
    const part = fact[variable.group][variable.category]
    const source = variable.source ? fixSourceName(variable.source) : getSourceName(variable.category)
    if (part && source && part[source]) {
      const scores = part[source].filter(x => x.type.replace(/^[0-9]+-? /m, '') === variable.type)[0]
      if (scores) {
        data.push({...variable, ...scores})
      }
    }
  });
  return data;
}

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreFactDialogProps = {
  fact: ExploreFactResponse;
  factorVariables: Array<Array<ExploreVariable>>;
  filterVariables: Array<ExploreVariable>;
  resultsOpen: boolean;
  onClose: () => void;
};


export const ExploreFactDialog: React.FC<ExploreFactDialogProps> = ({
  fact,
  factorVariables,
  filterVariables,
  resultsOpen,
  onClose,
}) => {

  const groups = ['Summary', 'Proximity', 'Housing', 'Demographics', 'Lifestyle', 'Other'];

  const handleClick = () => {
    buildFactExport(fact);
  }

  const groupComponents = [
    FactSummary,
    FactProximity,
    FactHousing,
    FactDemographics,
    FactLifestyle,
    FactOther
  ];

  const relatedVariables = factorVariables.length > 0
    ? getVariableFacts(factorVariables.flat(), fact)
    : getVariableFacts(filterVariables, fact);

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        top: resultsOpen ? 580 + 64 + 16 : 64,
        right: 68,
        width: '600px',
        height: resultsOpen ? 'calc(90% - 580px - 16px)' : '90%',
        // height: '90%',
        overflow: 'hidden',
        borderRadius: '6px',
    }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader
          id='explore-fact-dialog'
          title={fact.name.includes(',') ? 'Multiple Selections' : fact.name}
          action={
            <IconButton onClick={handleClick}>
              <DownloadIcon />
            </IconButton>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ overflowY: 'scroll' }} >
          <ExploreFactorResultCard results={fact.factors} />
          {relatedVariables.length > 0 &&
            <ExploreFactRelatedCard fact={relatedVariables}/>
          }
          {groups.map((group, groupIdx) => {
            const data = fact[group];
            const Component = groupComponents[groupIdx];
            return (
              data && <Component key={`fact-${group}`} data={data} level={fact.level} />
            )
          })}
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
    </DraggablePaper>
  );
}
