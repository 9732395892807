import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ExploreFactorCardNew } from '@/components/molecules';
import { ExploreFactor, ExploreVariable } from "@/interfaces";
import { getIconFromDQS } from "@/utils/icons";
import { cloneDeep } from "lodash";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    key: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function getFactorIndex(factor: ExploreFactor, factors: Array<ExploreFactor>) : number {
  return factors.map(f => f.name).indexOf(factor.name);
}

type ExploreFactorPanelProps = {
  selection: ExploreFactor | undefined;
  variables: Array<ExploreVariable>;
  factors: Array<ExploreFactor>;
  onSelect: (factor: ExploreFactor) => void;
};

export const ExploreFactorPanel: React.FC<ExploreFactorPanelProps>  = (
  {
    selection,
    variables,
    factors,
    onSelect
  }
) => {

  const [tabId, setTabId] = useState<number>(0);
  const [factorData, setFactorData] = useState<ExploreFactor>(factors[0]);

  useEffect(() => {
    selection && setTabId(getFactorIndex(selection, factors));
    selection && setFactorData(cloneDeep(selection));
  }, [selection, factors]);

  useEffect(() => {
    setFactorData(factors[tabId]);
    onSelect(factors[tabId]);
  },  [tabId, factors]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Tabs
          orientation="vertical"
          value={tabId}
          onChange={handleTabChange}
        >
          {factors.map( (factor, factorIdx) => (
            <Tab
              icon={getIconFromDQS(factor.name, {fontSize: 'small'})}
              iconPosition="start"
              label={factor.name}
              {...a11yProps(factorIdx)}
              sx={{ minHeight: '42px', height: '42px', justifyContent: 'flex-start', alignItems: 'flex-end'}}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item container xs={8} spacing={2}>
        <Stack spacing={2} mt={2} ml={2} sx={{ width: '100%' }}>
          {factorData.factors.split(',').map((varName, varIdx) => (
            <ExploreFactorCardNew
              key={`category-data-${varIdx}`}
              title={varName}
              variables={factorData.variables.split(',')[varIdx].split('|').map(y => variables.filter(v => v.feature_id === Number(y))[0])}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
