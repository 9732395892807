import {CircleLayer, FillLayer, SymbolLayer} from "react-map-gl";

export const baseSectorLayer: FillLayer = {
  id: 'base-sector',
  type: 'fill',
  source: 'postal-sector-source',
  'source-layer': 'Sector_TCE',
  paint: {
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      '#f2825c',
      [
        'case',
        ['==', ['feature-state', 'score'], null],
        'rgba(0,0,0,0)',
        [
          'interpolate',
          ['linear'],
          ['feature-state', 'score'],
          100, '#BEE5EB',
          110, '#5ACADB',
          120, '#00B1CC',
          130, '#008899',
          160, '#00656d'
        ]
      ]
    ],
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      '#F39879',
      [
        'case',
        ['==', ['feature-state', 'score'], null],
        'rgba(0,0,0,0)',
        [
          'interpolate',
          ['linear'],
          ['feature-state', 'score'],
          100, '#BEE5EB',
          110, '#5ACADB',
          120, '#00B1CC',
          130, '#008899',
          160, '#00656d'
        ]
      ]
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.5,
      0.8
    ]
  },
  // minzoom: 8,
  // maxzoom: 15
};

export const hoverSectorLayer: FillLayer = {
  id: 'highlight-sector',
  type: 'fill',
  'source-layer': 'postal_sector_test',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.3
  },
  minzoom: 8,
  maxzoom: 15
};

export const selectSectorLayer: FillLayer = {
  id: 'select-sector',
  type: 'fill',
  'source-layer': 'postal_sector_test',
  paint: {
    'fill-outline-color': '#725ba4',
    'fill-color': '#725ba4',
    'fill-opacity': 1.0
  },
  minzoom: 8,
  maxzoom: 15
};

export const baseDistrictLayer: FillLayer = {
  id: 'base-district',
  type: 'fill',
  source: 'postal-district-source',
  'source-layer': 'postal_district_test',
  paint: {
    'fill-outline-color': 'rgba(0,0,0,0.1)',
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'householdsdensity'],
      0, '#141938',
      50, '#223b89',
      100, '#316ba7',
      200, '#2b96c5',
      300, '#80ffff',
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.5,
      1
    ]
  },
  maxzoom: 8
};

export const hoverDistrictLayer: FillLayer = {
  id: 'highlight-district',
  type: 'fill',
  source: 'postal-district-source',
  'source-layer': 'postal_district_test',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.3
  },
  maxzoom: 8
};

export const selectDistrictLayer: FillLayer = {
  id: 'select-district',
  type: 'fill',
  'source-layer': 'postal_district_test',
  paint: {
    'fill-outline-color': '#725ba4',
    'fill-color': '#725ba4',
    'fill-opacity': 1.0
  },
  maxzoom: 8
};



export const clusterLayer: CircleLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'points',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#543b94', 50, '#725ba4', 250, '#ab9ec9'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 50, 30, 250, 40],
  }
}

export const clusterCountLayer: SymbolLayer = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  }
}

export const pointLayer: CircleLayer = {
  id: 'point',
  type: 'circle',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#543b94',
    'circle-radius': 8,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
}
