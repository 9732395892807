import React, { useState } from 'react';
import Draggable from "react-draggable";

import { useTheme } from "@mui/material/styles";
import Paper, {PaperProps} from "@mui/material/Paper";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';

import { ExploreFilterResult } from "@/interfaces";
import { ExploreFilterInfoDialog } from "@/components/molecules";

type ScoreRow = {
  category: string;
  source: string;
  type: string;
  index: number;
};

function createRow(fact: ExploreFilterResult): ScoreRow {
  return {
    category: fact.category,
    source: fact.source,
    type: fact.type,
    index: Math.round(fact.index),
  };
}

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#filter-summary-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreFilterSummaryDialogProps = {
  filterResult: Array<ExploreFilterResult>;
  onClose: () => void;
}

export const ExploreFilterSummaryDialog: React.FC<ExploreFilterSummaryDialogProps> = (
  {
    filterResult,
    onClose
}) => {

  const theme = useTheme();

  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 64,
        right: 68,
        width: '600px',
        height: '580px', //564
        overflow: 'hidden',
        borderRadius: '6px',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader
          id='filter-summary-dialog'
          title='Correlated Variables'
          sx={{ pb: 0 }}
          action={
            <IconButton onClick={handleInfoOpen}>
              <InfoIcon />
            </IconButton>
          }
        />
        <CardContent sx={{ overflowY: 'auto' }} >
          <Typography variant='caption' sx={{ mb: 1 }}>
            The following table shows other variables that the filtered sectors score highly for:
          </Typography>
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', py: 1, width: '10%', color: theme.palette.text.secondary}}>Group</TableCell>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Category</TableCell>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', width: '20%', color: theme.palette.text.secondary}}>Variable</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Index</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterResult.map((variable, variableIdx) => {
                const row = createRow(variable);
                return (
                  <TableRow key={`score-row-${variableIdx}`}>
                    <TableCell align='left' sx={{ fontSize: '12px', py: 1 }}>{row.category}</TableCell>
                    <TableCell align='left' sx={{ fontSize: '12px', py: 1 }}>{row.source}</TableCell>
                    <TableCell align='left' sx={{ fontSize: '12px', py: 1 }}>{row.type}</TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.index > 100 ? theme.palette.success.light : row.index === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.index}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
      <ExploreFilterInfoDialog open={infoOpen} onClose={handleInfoClose}/>
    </DraggablePaper>
  );
};
