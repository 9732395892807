import React from 'react';

import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Tooltip } from '@/components/atoms';

type ButtonIconGroupProps = {
  value: string;
  options: Array<string>;
  onClick: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  color: 'primary' | 'secondary' | 'info';
  size: string | number;
  icons: Array<React.ReactElement>;
  tooltips: Array<string>;
  disabled?: Array<boolean>;
};

export const ButtonIconGroup: React.FC<ButtonIconGroupProps> = ({
  value,
  options,
  onClick,
  color,
  size,
  icons,
  tooltips,
  disabled
  }) => {

  const theme = useTheme();

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={onClick}
      sx={{
        height: size,
      }}
    >
      {options.map((option, idx) => (
        <ToggleButton
          key={option}
          value={option}
          aria-label='sector'
          disabled={disabled && disabled[idx]}
          sx={{
            backgroundColor: theme.palette[color].main,
            color: theme.palette[color].contrastText,
            width: size,
            '&.Mui-selected': {
              backgroundColor: theme.palette[color].dark,
              color: theme.palette.text.primary
            },
            '&.Mui-selected:hover': {
              backgroundColor: theme.palette[color].dark,
              color: theme.palette.text.primary
            },
            '&:hover': {
              backgroundColor: theme.palette[color].dark,
              color: theme.palette.text.primary
            },
          }}>
          <Tooltip title={tooltips[idx]} placement='bottom'>
            {icons[idx]}
          </Tooltip>
        </ToggleButton>
      ))}

    </ToggleButtonGroup>
  )

}
