import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type UnderlineTextProps = {
  text: string,
  color: string,
  variant: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
  textProps: object;
};

export const UnderlineText: React.FC<UnderlineTextProps> = ({
  text,
  color,
  variant,
  textProps
}) => {

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant={variant} {...textProps}>{text}</Typography>
      <Box sx={{ height: '4px', width: '80%', backgroundColor: color }}/>
    </Box>
  )
}
