import React, { useState, useEffect } from 'react';
import { useMap, MapboxMap, MapRef } from "react-map-gl";
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Chip from '@mui/material/Chip';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import CheckIcon from '@mui/icons-material/Check';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import InfoIcon from "@mui/icons-material/Info";

import { LocationSearchBar, ButtonIcon, ButtonIconGroup, DrivetimeSlider, Overlay, CatchmentMapLegend } from '@/components/atoms';
import { CatchmentInfoDialog } from "@/components/molecules";
import { getLocations, getLocationCatchment, getLocationDrivetime } from '@/services/ApiService';
import { Location, MarkerType } from '@/interfaces';


type CatchmentOverlayProps = {
  disabled: boolean;
  enable: () => void;
  selected: Array<Location>;
  setSelected: (value: Array<Location>) => void;
  catchment: Array<Location>;
  setCatchment: (value: Array<Location>) => void;
  marker: MarkerType | null;
  updateMap: (map: MapboxMap | MapRef, sectors: Array<Location>, mode:string, type: string) => void;
  clearMap: (map: MapboxMap | MapRef, mode: string) => void;
  setMarker: (value: MarkerType | null) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const CatchmentOverlay: React.FC<CatchmentOverlayProps> = ({
  disabled,
  enable,
  selected,
  setSelected,
  catchment,
  setCatchment,
  marker,
  updateMap,
  clearMap,
  setMarker,
  loading,
  setLoading
}) => {

  const { current: map } = useMap();
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [location, setLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [mode, setMode] = useState<string>('sector');
  const [drivetime, setDrivetime] = useState<number>(20);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLocations(false, accessToken);
      setLocations(response);
    }
    fetchLocations().then();
  }, []);

  useEffect(() => {
    switch (mode) {
      case 'drivetime':
        marker &&
        updateSelection(
          mode,
          {geo_id: 0, level: '', geo_name: '', latitude: marker.latitude, longitude: marker.longitude},
          drivetime
        );
        break;
      case 'manual':
        setDrivetime(20)
        updateSelection(
          mode,
          {geo_id: 0, level: '', geo_name: '', latitude: 0, longitude: 0}
        );
        break;
      case 'sector':
        setDrivetime(20);
        location && updateSelection(mode, location);
        break;
    }
  }, [mode, location, drivetime]);

  useEffect(() => {
    marker && mode === 'drivetime' && (
      updateSelection(
      mode,
      {geo_id: 0, level: '', geo_name: '', latitude: marker.latitude, longitude: marker.longitude},
      drivetime
      )
    );
  }, [marker])

  const onSelect = (location: Location) => {
    map && map.flyTo({center: [location.longitude, location.latitude], duration: 1000, zoom: 11});
    setMode('sector');
    setLocation(location);
    setMarker({latitude: location.latitude, longitude: location.longitude});
  };

  const updateSelection = (mode: string, location: Location, drivetime?: number) => {
    const callApi = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      if (mode === 'drivetime') {
        return await getLocationDrivetime(
          location.latitude,
          location.longitude,
          drivetime ? drivetime : 20,
          accessToken
        );
      } else if (mode === 'manual') {
        return [];
      } else {
        return await getLocationCatchment(
          location.geo_id,
          location.level,
          accessToken
        );
      }
    }
    callApi().then((sectors) => {
      setLoading(false);
      if (map) {
        clearMap(map, 'catchment');
        if (sectors.length > 0) {
          setSelected(sectors);
          updateMap(map, sectors, 'catchment', 'add');
        } else {
          setSelected([]);
        }
      }
    }).catch((error) => {
      if ( map ) {
        setSelected([]);
        clearMap(map, 'catchment');
      }
      setError('drivetime');
    });
  };

  const handleApply = () => {
    setCatchment(selected);
    setSelected([]);
  };

  const handleEdit = () => {
    map && clearMap(map, 'baseline');
    enable();
  }

  const handleClear = () => {
    map && clearMap(map, 'catchment');
    setLocation(null);
    setSelected([]);
    setCatchment([]);
    setMarker(null);
    enable();
  };

  const handleModeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setMode(value);
  };

  const handleDrivetimeChange = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    if (typeof value === 'number') {
      setDrivetime(value);
    }
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <>
      {loading && <Overlay />}
      <Box sx={{ mt: 2, ml: 2, width: '100%'}}>
        <Typography
          variant='overline'
          sx={{ position: 'relative', color: theme.palette.secondary.dark, ml: '2px' }}
        >
          Catchment
        </Typography>
        <Stack direction='row' spacing={3}>
          <LocationSearchBar options={locations} showLabel={true} disabled={disabled} onSelect={onSelect} color={"secondary"}/>
          {catchment.length > 0 &&
            <Chip
              color='secondary'
              icon={<LocationOnIcon />}
              label={`${catchment.length} Sectors`}
              sx={{ position: 'relative', height: '40px', borderRadius: '6px' }}/>
          }
          {!disabled && location &&
            <ButtonIconGroup
              value={mode}
              onClick={handleModeChange}
              options={['sector', 'drivetime', 'manual']}
              icons={[<SelectAllIcon/>, <DirectionsCarIcon/>, <TouchAppIcon/>]}
              tooltips={['Contained', 'Drivetime', 'Manual']}
              color='secondary'
              size='40px'
            />
          }
          {!disabled && marker && mode === 'drivetime' &&
            <DrivetimeSlider drivetime={drivetime} onDrivetime={handleDrivetimeChange} color='secondary'/>
          }
          {catchment.length > 0 &&
            <ButtonIcon
              onClick={handleEdit}
              color='secondary'
              size='40px'
              Icon={EditLocationAltIcon}
              tooltip='Edit Catchment'
            />
          }
          {selected.length > 0 && !disabled &&
            <ButtonIcon
              onClick={handleClear}
              color='secondary'
              size='40px'
              Icon={DoDisturbIcon}
              tooltip='Clear Selection'
            />
          }
          {selected.length > 0 && !disabled &&
            <ButtonIcon
              onClick={handleApply}
              color='secondary'
              size='40px'
              Icon={CheckIcon}
              tooltip='Create Catchment'
            />
          }
          <ButtonIcon
            onClick={handleInfoOpen}
            color='secondary'
            size='40px'
            Icon={InfoIcon}
            tooltip='More Info'
          />
        </Stack>
      </Box>
      <CatchmentInfoDialog
        open={infoOpen}
        onClose={handleInfoClose}
      />
      <CatchmentMapLegend />
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        sx={{ mb: 2, ml: 2 }}
      >
        <Alert
          onClose={handleErrorClose}
          color='error'
          severity='error'
          sx={{ background: theme.palette.background.default, borderRadius: '6px'}}
        >
          {error === 'drivetime'
            ? 'Unable to calculate drivetime. Make sure the marker is near a road and try again.'
            : 'There has been an error creating the catchment. Please try again.'
          }
        </Alert>
      </Snackbar>
    </>
  );
};
