import React from "react";

export const TCELogo = () => (
  <svg viewBox="0 0 200 68">
    <path id="Imported Path"
          fill="#FFFFFF"
          d="M 199.07,1.00
           C 199.07,1.00 199.07,31.00 199.07,31.00
             199.07,31.00 196.41,31.00 196.41,31.00
             196.41,31.00 196.34,30.90 196.34,30.90
             196.34,30.90 180.53,10.30 180.53,10.30
             180.53,19.97 180.53,24.00 180.53,26.13
             180.53,28.37 180.53,28.47 180.53,30.70
             180.53,30.70 180.53,31.00 180.53,31.00
             180.53,31.00 177.24,31.00 177.24,31.00
             175.81,31.00 175.07,30.10 175.07,28.33
             175.07,28.33 175.07,5.90 175.07,5.90
             175.07,4.50 175.01,3.73 174.81,3.27
             174.51,2.60 174.11,2.27 173.58,2.27
             173.58,2.27 173.31,2.27 173.31,2.27
             173.31,2.27 173.31,1.03 173.31,1.03
             173.31,1.03 180.30,1.03 180.30,1.03
             180.30,1.03 180.40,1.13 180.40,1.13
             180.40,1.13 193.64,18.63 193.64,18.63
             193.64,18.63 193.64,3.30 193.64,3.30
             193.64,2.17 193.84,1.00 195.27,1.00
             195.27,1.00 199.07,1.00 199.07,1.00 Z
           M 170.72,1.00
           C 170.72,1.00 162.83,30.83 162.83,30.83
             162.83,30.83 160.00,30.83 160.00,30.83
             157.67,30.83 156.71,30.27 155.84,27.07
             155.84,27.07 152.35,13.40 152.35,13.40
             152.35,13.40 147.25,30.83 147.25,30.83
             147.25,30.83 144.43,30.83 144.43,30.83
             141.86,30.83 141.16,29.90 140.50,27.27
             140.50,27.27 134.58,3.90 134.58,3.90
             134.41,3.27 133.98,2.20 132.78,2.20
             132.78,2.20 132.51,2.20 132.51,2.20
             132.51,2.20 132.51,1.00 132.51,1.00
             132.51,1.00 137.57,1.00 137.57,1.00
             139.10,1.00 139.97,1.70 140.33,3.33
             140.33,3.33 144.76,22.73 144.76,22.73
             144.76,22.73 150.92,1.00 150.92,1.00
             150.92,1.00 154.48,1.00 154.48,1.00
             154.48,1.00 154.54,1.20 154.54,1.20
             154.54,1.20 160.10,22.70 160.10,22.70
             160.10,22.70 165.09,1.00 165.09,1.00
             165.09,1.00 170.72,1.00 170.72,1.00 Z
           M 66.49,4.87
           C 60.40,4.87 56.14,9.27 56.21,15.63
             56.21,22.20 60.60,26.43 67.42,26.43
             70.02,26.43 71.95,25.60 73.11,25.13
             73.34,25.03 73.54,24.97 73.71,24.90
             73.71,24.90 74.08,24.77 74.08,24.77
             74.08,24.77 74.08,29.87 74.08,29.87
             74.08,29.87 73.91,29.93 73.91,29.93
             71.21,31.00 68.29,31.23 66.32,31.23
             61.40,31.23 57.27,29.67 54.41,26.73
             51.75,24.00 50.28,20.17 50.28,15.93
             50.28,11.67 51.75,7.80 54.44,5.00
             57.30,2.00 61.40,0.40 66.19,0.40
             69.72,0.40 72.51,1.30 73.88,1.83
             73.88,1.83 74.04,1.90 74.04,1.90
             74.04,1.90 74.04,8.60 74.04,8.60
             74.04,8.60 72.98,8.60 72.98,8.60
             72.98,8.60 72.91,8.37 72.91,8.37
             72.31,5.77 69.08,4.87 66.49,4.87M 127.79,4.77
           C 130.32,7.60 131.71,11.50 131.75,15.80
             131.75,25.13 125.72,31.67 117.10,31.67
             112.61,31.67 108.82,30.10 106.12,27.17
             103.56,24.37 102.13,20.40 102.13,16.03
             102.13,11.77 103.49,7.83 106.02,4.93
             108.75,1.83 112.55,0.17 116.97,0.17
             121.36,0.17 125.09,1.77 127.79,4.77M 116.97,5.13
           C 111.78,5.13 108.25,9.47 108.25,15.93
             108.25,23.47 112.68,26.83 117.07,26.83
             123.00,26.83 125.69,21.23 125.69,16.03
             125.69,9.53 122.20,5.13 116.97,5.13M 102.53,30.83
           C 102.53,30.83 100.33,30.83 100.33,30.83
             97.50,30.83 95.51,30.67 93.58,28.50
             91.71,26.37 90.15,23.30 89.02,21.07
             88.75,20.50 88.49,20.03 88.29,19.63
             88.29,19.63 84.79,19.63 84.79,19.63
             84.79,20.80 84.79,21.97 84.79,23.13
             84.79,25.60 84.79,28.17 84.79,30.67
             84.79,30.67 84.79,30.97 84.79,30.97
             84.79,30.97 81.36,30.97 81.36,30.97
             79.87,30.97 79.07,30.10 79.07,28.43
             79.07,28.43 79.07,5.07 79.07,5.07
             79.07,3.47 78.80,2.47 77.40,2.17
             77.40,2.17 77.17,2.13 77.17,2.13
             77.17,2.13 77.17,1.00 77.17,1.00
             77.17,1.00 88.65,1.00 88.65,1.00
             89.98,1.00 91.25,1.13 92.41,1.47
             95.94,2.47 98.30,5.73 98.30,9.67
             98.30,13.90 97.04,16.30 93.68,18.40
             95.01,21.30 99.73,28.90 101.73,30.30
             101.73,30.30 102.53,30.83 102.53,30.83 Z
           M 88.62,5.37
           C 88.62,5.37 84.73,5.37 84.73,5.37
             84.73,5.37 84.73,15.27 84.73,15.27
             84.73,15.27 89.28,15.27 89.28,15.27
             91.55,15.27 92.58,12.67 92.58,10.27
             92.58,7.27 91.01,5.37 88.62,5.37 Z
           M 125.32,42.27
           C 125.32,42.27 115.81,42.27 115.81,42.27
             115.81,42.27 115.81,64.03 115.81,64.03
             115.81,65.87 116.17,66.20 117.57,66.40
             117.57,66.40 117.80,66.43 117.80,66.43
             117.80,66.43 117.80,67.63 117.80,67.63
             117.80,67.63 112.51,67.63 112.51,67.63
             110.92,67.63 110.12,66.87 110.12,65.23
             110.12,65.23 110.12,42.30 110.12,42.30
             110.12,42.30 103.26,42.30 103.26,42.30
             102.26,42.30 101.73,42.83 101.53,44.07
             101.53,44.07 101.50,44.30 101.50,44.30
             101.50,44.30 100.40,44.30 100.40,44.30
             100.40,44.30 100.40,37.57 100.40,37.57
             100.40,37.57 125.32,37.57 125.32,37.57
             125.32,37.57 125.32,42.27 125.32,42.27
             125.32,42.27 125.32,42.27 125.32,42.27 Z
           M 174.18,42.27
           C 174.18,42.27 164.66,42.27 164.66,42.27
             164.66,42.27 164.66,64.03 164.66,64.03
             164.66,65.87 165.02,66.20 166.42,66.40
             166.42,66.40 166.66,66.43 166.66,66.43
             166.66,66.43 166.66,67.63 166.66,67.63
             166.66,67.63 161.36,67.63 161.36,67.63
             159.77,67.63 158.97,66.87 158.97,65.23
             158.97,65.23 158.97,42.30 158.97,42.30
             158.97,42.30 152.11,42.30 152.11,42.30
             151.11,42.30 150.58,42.83 150.38,44.07
             150.38,44.07 150.35,44.30 150.35,44.30
             150.35,44.30 149.25,44.30 149.25,44.30
             149.25,44.30 149.25,37.57 149.25,37.57
             149.25,37.57 174.18,37.57 174.18,37.57
             174.18,37.57 174.18,42.27 174.18,42.27
             174.18,42.27 174.18,42.27 174.18,42.27 Z
           M 73.41,61.07
           C 73.41,61.07 74.61,61.07 74.61,61.07
             74.61,61.07 72.91,67.50 72.91,67.50
             72.91,67.50 56.41,67.50 56.41,67.50
             53.98,67.50 53.68,65.77 53.68,64.27
             53.68,64.27 53.68,41.27 53.68,41.27
             53.68,39.77 53.14,38.93 52.01,38.73
             52.01,38.73 51.78,38.70 51.78,38.70
             51.78,38.70 51.78,37.57 51.78,37.57
             51.78,37.57 73.54,37.57 73.54,37.57
             73.54,37.57 73.54,42.23 73.54,42.23
             73.54,42.23 59.43,42.23 59.43,42.23
             59.43,42.23 59.43,49.50 59.43,49.50
             59.43,49.50 71.81,49.50 71.81,49.50
             71.81,49.50 71.81,54.20 71.81,54.20
             71.81,54.20 59.43,54.20 59.43,54.20
             59.43,54.20 59.43,62.80 59.43,62.80
             59.43,62.80 70.18,62.80 70.18,62.80
             72.11,62.80 72.61,62.53 73.34,61.23
             73.34,61.23 73.41,61.10 73.41,61.10
             73.41,61.10 73.41,61.07 73.41,61.07 Z
           M 198.74,61.07
           C 198.74,61.07 200.00,61.07 200.00,61.07
             200.00,61.07 198.30,67.50 198.30,67.50
             198.30,67.50 181.80,67.50 181.80,67.50
             179.37,67.50 179.07,65.77 179.07,64.27
             179.07,64.27 179.07,41.27 179.07,41.27
             179.07,39.77 178.54,38.93 177.40,38.73
             177.40,38.73 177.17,38.70 177.17,38.70
             177.17,38.70 177.17,37.57 177.17,37.57
             177.17,37.57 198.94,37.57 198.94,37.57
             198.94,37.57 198.94,42.23 198.94,42.23
             198.94,42.23 184.76,42.23 184.76,42.23
             184.76,42.23 184.76,49.50 184.76,49.50
             184.76,49.50 197.14,49.50 197.14,49.50
             197.14,49.50 197.14,54.20 197.14,54.20
             197.14,54.20 184.76,54.20 184.76,54.20
             184.76,54.20 184.76,62.80 184.76,62.80
             184.76,62.80 195.51,62.80 195.51,62.80
             197.44,62.80 197.94,62.53 198.67,61.23
             198.67,61.23 198.74,61.10 198.74,61.10
             198.74,61.10 198.74,61.07 198.74,61.07 Z
           M 153.08,66.30
           C 153.08,66.30 153.08,67.53 153.08,67.53
             153.08,67.53 148.02,67.53 148.02,67.53
             146.09,67.53 145.49,66.80 144.96,65.23
             144.96,65.23 142.96,60.23 142.96,60.23
             142.96,60.23 130.75,60.23 130.75,60.23
             130.75,60.23 128.72,65.23 128.72,65.23
             128.32,66.43 127.79,67.53 126.12,67.53
             126.12,67.53 122.06,67.53 122.06,67.53
             122.06,67.53 134.38,37.57 134.38,37.57
             134.38,37.57 139.77,37.57 139.77,37.57
             139.77,37.57 150.65,64.33 150.65,64.33
             151.05,65.43 151.85,66.13 152.81,66.23
             152.81,66.23 153.08,66.30 153.08,66.30 Z
           M 136.91,44.23
           C 136.91,44.23 132.38,56.07 132.38,56.07
             132.38,56.07 141.40,56.07 141.40,56.07
             141.40,56.07 136.91,44.23 136.91,44.23 Z
           M 91.68,50.20
           C 95.64,51.87 99.17,53.80 99.10,59.27
             99.03,64.57 94.41,68.00 87.35,68.00
             87.35,68.00 87.35,68.00 87.35,68.00
             87.22,68.00 87.09,68.00 86.96,68.00
             85.16,67.97 83.00,67.63 81.13,67.10
             78.70,66.40 78.30,65.50 78.34,63.83
             78.34,63.83 78.34,60.77 78.34,60.77
             78.34,60.77 78.74,61.00 78.74,61.00
             81.20,62.37 84.73,63.43 87.12,63.47
             87.19,63.47 87.29,63.47 87.35,63.47
             87.35,63.47 87.35,63.47 87.35,63.47
             91.51,63.47 93.04,61.37 93.08,59.43
             93.11,56.97 89.98,55.70 87.89,54.87
             87.89,54.87 85.49,53.90 85.49,53.90
             82.03,52.47 78.07,50.23 78.14,45.47
             78.17,43.20 79.20,41.10 81.03,39.57
             83.09,37.83 86.06,36.93 89.58,37.00
             93.51,37.07 96.37,38.27 97.47,38.73
             97.47,38.73 97.67,38.83 97.67,38.83
             97.67,38.83 97.60,45.03 97.60,45.03
             97.60,45.03 96.57,45.00 96.57,45.00
             96.57,45.00 96.51,44.83 96.51,44.83
             96.11,43.70 95.01,42.77 93.48,42.23
             92.08,41.77 90.72,41.50 89.38,41.47
             86.02,41.40 84.06,42.70 84.03,44.97
             83.99,47.07 86.59,48.10 88.65,48.93
             88.95,49.03 89.22,49.17 89.48,49.27
             89.48,49.27 91.68,50.20 91.68,50.20M 9.05,4.23
           C 9.05,4.23 9.05,16.30 9.05,16.30
             9.05,17.33 9.25,17.50 10.05,17.63
             10.05,17.63 10.18,17.67 10.18,17.67
             10.18,17.67 10.18,18.47 10.18,18.47
             10.18,18.47 6.82,18.47 6.82,18.47
             5.92,18.47 5.46,18.00 5.46,17.07
             5.46,17.07 5.46,4.23 5.46,4.23
             5.46,4.23 1.96,4.23 1.96,4.23
             1.40,4.23 1.10,4.53 1.00,5.23
             1.00,5.23 0.97,5.37 0.97,5.37
             0.97,5.37 0.30,5.37 0.30,5.37
             0.30,5.37 0.30,1.40 0.30,1.40
             0.30,1.40 14.18,1.40 14.18,1.40
             14.18,1.40 14.18,4.23 14.18,4.23
             14.18,4.23 9.05,4.23 9.05,4.23 Z
           M 43.93,14.67
           C 43.93,14.67 44.66,14.67 44.66,14.67
             44.66,14.67 43.66,18.53 43.66,18.53
             43.66,18.53 34.24,18.53 34.24,18.53
             32.85,18.53 32.68,17.53 32.68,16.67
             32.68,16.67 32.68,3.63 32.68,3.63
             32.68,2.77 32.38,2.30 31.75,2.20
             31.75,2.20 31.61,2.17 31.61,2.17
             31.61,2.17 31.61,1.43 31.61,1.43
             31.61,1.43 44.03,1.43 44.03,1.43
             44.03,1.43 44.03,4.30 44.03,4.30
             44.03,4.30 36.27,4.30 36.27,4.30
             36.27,4.30 36.27,8.10 36.27,8.10
             36.27,8.10 43.00,8.10 43.00,8.10
             43.00,8.10 43.00,11.00 43.00,11.00
             43.00,11.00 36.27,11.00 36.27,11.00
             36.27,11.00 36.27,15.60 36.27,15.60
             36.27,15.60 42.10,15.60 42.10,15.60
             43.19,15.60 43.49,15.47 43.89,14.70
             43.89,14.70 43.93,14.60 43.93,14.60
             43.93,14.60 43.93,14.67 43.93,14.67 Z
           M 24.83,1.43
           C 24.83,1.43 29.48,1.43 29.48,1.43
             29.48,1.43 29.48,18.47 29.48,18.47
             29.48,18.47 25.89,18.47 25.89,18.47
             25.89,18.47 25.89,11.00 25.89,11.00
             25.89,11.00 19.80,11.00 19.80,11.00
             19.80,11.00 19.80,18.47 19.80,18.47
             19.80,18.47 16.21,18.47 16.21,18.47
             16.21,18.47 16.21,3.60 16.21,3.60
             16.21,2.73 15.91,2.27 15.27,2.17
             15.27,2.17 15.14,2.13 15.14,2.13
             15.14,2.13 15.14,1.40 15.14,1.40
             15.14,1.40 19.83,1.40 19.83,1.40
             19.83,1.40 19.83,8.10 19.83,8.10
             19.83,8.10 25.89,8.10 25.89,8.10
             25.89,8.10 25.89,3.60 25.89,3.60
             25.89,2.73 25.59,2.27 24.96,2.17
             24.96,2.17 24.83,2.13 24.83,2.13
             24.83,2.13 24.83,1.40 24.83,1.40
             24.83,1.40 24.83,1.43 24.83,1.43 Z
           M 42.33,45.90
           C 42.96,45.87 43.53,46.43 43.66,47.17
             43.66,47.87 43.13,48.40 42.50,48.40
             42.36,48.40 42.23,48.37 42.10,48.33
             42.03,48.57 42.00,48.80 41.93,49.03
             42.53,49.07 43.03,49.57 43.03,50.23
             43.03,50.90 42.50,51.43 41.86,51.43
             41.66,51.43 41.50,51.40 41.33,51.30
             41.26,51.47 41.23,51.67 41.16,51.87
             41.73,51.97 42.16,52.47 42.16,53.07
             42.16,53.73 41.63,54.27 41.00,54.27
             40.80,54.27 40.63,54.23 40.47,54.13
             40.00,55.63 39.50,57.13 39.00,58.60
             39.00,58.60 38.64,59.67 38.64,59.67
             37.70,62.40 37.00,64.60 36.57,66.30
             36.57,66.30 36.37,67.00 36.37,67.00
             36.37,67.00 9.42,67.00 9.42,67.00
             9.42,67.00 9.22,66.30 9.22,66.30
             8.79,64.60 8.09,62.40 7.15,59.67
             7.15,59.67 6.79,58.60 6.79,58.60
             6.29,57.13 5.79,55.63 5.32,54.13
             5.16,54.23 4.99,54.27 4.79,54.27
             4.16,54.27 3.63,53.73 3.63,53.07
             3.63,52.47 4.06,51.97 4.63,51.87
             4.56,51.67 4.53,51.50 4.46,51.30
             4.29,51.40 4.13,51.43 3.93,51.43
             3.29,51.43 2.76,50.90 2.76,50.23
             2.76,49.60 3.26,49.07 3.86,49.03
             3.79,48.80 3.76,48.57 3.69,48.33
             3.56,48.37 3.43,48.40 3.29,48.40
             2.66,48.40 2.13,47.83 2.13,47.17
             2.13,46.50 2.66,45.93 3.33,45.97
             3.33,45.87 3.33,45.77 3.33,45.67
             3.33,45.57 3.33,45.47 3.33,45.37
             3.29,45.37 3.23,45.37 3.19,45.37
             2.56,45.37 2.03,44.83 2.03,44.17
             2.03,43.50 2.56,42.97 3.19,42.97
             3.36,42.97 3.53,43.00 3.66,43.07
             3.73,42.83 3.79,42.57 3.89,42.27
             3.36,42.13 2.96,41.63 2.96,41.07
             2.96,40.40 3.49,39.87 4.13,39.87
             4.43,39.87 4.73,40.00 4.93,40.20
             5.09,39.97 5.26,39.73 5.46,39.53
             5.19,39.30 5.02,38.97 5.02,38.60
             5.02,37.93 5.56,37.40 6.19,37.40
             6.66,37.40 7.09,37.70 7.25,38.13
             7.52,37.97 7.82,37.83 8.09,37.70
             7.99,37.53 7.92,37.30 7.92,37.07
             7.92,36.40 8.45,35.87 9.08,35.87
             9.72,35.87 10.25,36.40 10.25,37.07
             10.25,37.10 10.25,37.17 10.25,37.20
             10.65,37.17 10.88,37.17 11.25,37.20
             11.21,37.10 11.21,37.00 11.21,36.90
             11.21,36.23 11.75,35.70 12.38,35.70
             13.01,35.70 13.54,36.23 13.54,36.90
             13.54,37.17 13.44,37.47 13.28,37.67
             13.54,37.77 13.84,37.90 14.11,38.07
             14.21,37.50 14.68,37.07 15.24,37.07
             15.87,37.07 16.41,37.60 16.41,38.27
             16.41,38.67 16.21,39.03 15.87,39.27
             16.44,39.73 17.04,40.30 17.64,40.93
             17.64,40.93 17.47,41.17 17.47,41.17
             15.21,39.83 13.38,39.00 11.18,39.00
             9.12,39.00 7.75,39.93 6.96,40.70
             5.86,41.77 5.22,43.53 5.22,45.43
             5.22,46.60 5.46,48.07 5.89,49.87
             5.89,49.87 8.89,49.87 8.89,49.87
             8.89,49.87 8.15,53.30 8.15,53.30
             8.15,53.30 10.52,52.30 10.52,52.30
             10.52,52.30 10.35,52.63 10.35,52.63
             9.52,54.40 9.82,56.47 10.35,57.70
             10.35,57.70 10.48,58.03 10.48,58.03
             10.48,58.03 9.15,57.47 9.15,57.47
             9.25,58.50 9.88,60.17 11.25,60.17
             12.21,60.17 12.91,59.47 13.31,58.13
             13.41,57.73 13.48,57.37 13.48,57.00
             13.48,56.47 13.34,56.07 13.11,55.97
             13.04,55.93 13.01,55.93 12.95,55.93
             12.51,55.93 11.88,56.70 11.68,57.70
             11.68,57.70 11.61,58.03 11.61,58.03
             11.61,58.03 11.45,57.73 11.45,57.73
             11.25,57.40 10.85,56.57 10.68,55.60
             10.62,55.33 10.58,54.97 10.58,54.63
             10.58,53.90 10.82,53.23 11.21,52.80
             11.48,52.50 11.85,52.33 12.21,52.33
             12.75,52.33 13.18,52.63 13.54,53.17
             13.58,53.23 13.61,53.27 13.64,53.30
             13.64,53.23 13.58,53.17 13.54,53.03
             13.24,52.30 13.04,51.63 13.04,50.83
             13.04,49.90 13.48,48.93 14.08,47.60
             14.18,47.37 14.28,47.13 14.38,46.90
             14.38,46.90 14.71,46.10 14.71,46.10
             14.71,46.10 15.04,46.90 15.04,46.90
             15.14,47.17 15.24,47.37 15.34,47.60
             15.94,48.93 16.37,49.90 16.37,50.83
             16.37,51.67 16.17,52.30 15.87,53.03
             15.84,53.13 15.81,53.23 15.77,53.30
             15.81,53.27 15.84,53.23 15.87,53.17
             16.24,52.60 16.67,52.33 17.20,52.33
             17.57,52.33 17.90,52.50 18.20,52.80
             18.60,53.23 18.84,53.90 18.84,54.63
             18.84,54.97 18.77,55.37 18.74,55.60
             18.60,56.53 18.17,57.40 17.97,57.73
             17.97,57.73 17.80,58.03 17.80,58.03
             17.80,58.03 17.74,57.70 17.74,57.70
             17.54,56.70 16.91,55.93 16.47,55.93
             16.41,55.93 16.34,55.93 16.31,55.97
             16.07,56.07 15.94,56.47 15.94,57.00
             15.94,57.37 15.97,57.77 16.11,58.13
             16.47,59.37 17.50,60.17 18.67,60.17
             19.30,60.17 20.80,59.93 21.43,57.93
             21.50,57.67 21.56,57.37 21.60,57.07
             21.60,57.07 19.00,58.17 19.00,58.17
             19.00,58.17 19.13,57.83 19.13,57.83
             19.63,56.60 19.97,54.53 19.13,52.77
             19.13,52.77 18.97,52.43 18.97,52.43
             18.97,52.43 21.16,53.37 21.16,53.37
             21.16,53.37 19.53,50.17 19.53,50.17
             19.53,50.17 21.06,50.17 21.06,50.17
             21.06,50.17 21.06,44.00 21.06,44.00
             20.20,43.40 19.67,42.37 19.67,41.23
             19.67,40.43 19.90,39.90 20.23,39.37
             20.33,39.23 20.53,38.97 20.90,38.57
             21.16,38.30 21.40,37.97 21.46,37.70
             21.46,37.70 21.46,37.67 21.46,37.60
             21.46,37.60 17.80,39.00 17.80,39.00
             17.80,39.00 17.80,32.73 17.80,32.73
             17.80,32.73 21.06,33.97 21.06,33.97
             21.06,33.97 19.73,29.97 19.73,29.97
             19.73,29.97 25.96,29.97 25.96,29.97
             25.96,29.97 24.63,33.97 24.63,33.97
             24.63,33.97 27.89,32.73 27.89,32.73
             27.89,32.73 27.89,39.00 27.89,39.00
             27.89,39.00 24.23,37.60 24.23,37.60
             24.23,37.63 24.23,37.70 24.23,37.70
             24.29,38.00 24.56,38.30 24.79,38.57
             25.12,38.93 25.36,39.20 25.46,39.37
             25.79,39.90 26.02,40.43 26.02,41.23
             26.02,42.33 25.49,43.37 24.63,44.00
             24.63,44.00 24.63,50.10 24.63,50.10
             24.63,50.10 26.12,50.10 26.12,50.10
             26.12,50.10 24.49,53.30 24.49,53.30
             24.49,53.30 26.69,52.37 26.69,52.37
             26.69,52.37 26.52,52.70 26.52,52.70
             25.69,54.47 25.99,56.53 26.52,57.77
             26.52,57.77 26.66,58.10 26.66,58.10
             26.66,58.10 24.06,57.00 24.06,57.00
             24.09,57.30 24.13,57.60 24.23,57.87
             24.86,59.90 26.36,60.10 26.99,60.10
             28.15,60.10 29.18,59.27 29.55,58.07
             29.65,57.67 29.72,57.30 29.72,56.93
             29.72,56.40 29.58,56.00 29.35,55.90
             29.28,55.87 29.25,55.87 29.18,55.87
             28.75,55.87 28.12,56.63 27.92,57.63
             27.92,57.63 27.85,57.97 27.85,57.97
             27.85,57.97 27.69,57.67 27.69,57.67
             27.49,57.33 27.09,56.50 26.92,55.53
             26.86,55.27 26.82,54.90 26.82,54.57
             26.82,53.83 27.05,53.17 27.45,52.73
             27.72,52.43 28.09,52.27 28.45,52.27
             28.99,52.27 29.42,52.57 29.78,53.10
             29.82,53.17 29.85,53.20 29.88,53.23
             29.88,53.17 29.82,53.10 29.78,52.97
             29.48,52.23 29.28,51.57 29.28,50.77
             29.28,49.83 29.72,48.87 30.32,47.53
             30.42,47.30 30.52,47.07 30.62,46.83
             30.62,46.83 30.95,46.03 30.95,46.03
             30.95,46.03 31.28,46.83 31.28,46.83
             31.38,47.07 31.48,47.30 31.58,47.53
             32.18,48.87 32.61,49.83 32.61,50.77
             32.61,51.60 32.41,52.23 32.11,52.97
             32.08,53.07 32.05,53.17 32.01,53.23
             32.05,53.20 32.08,53.17 32.11,53.10
             32.48,52.53 32.91,52.27 33.44,52.27
             33.81,52.27 34.14,52.43 34.44,52.73
             34.84,53.17 35.07,53.83 35.07,54.57
             35.07,54.90 35.01,55.30 34.98,55.53
             34.84,56.47 34.41,57.33 34.21,57.67
             34.21,57.67 34.04,57.97 34.04,57.97
             34.04,57.97 33.98,57.63 33.98,57.63
             33.78,56.63 33.14,55.87 32.71,55.87
             32.65,55.87 32.58,55.87 32.55,55.90
             32.31,56.00 32.18,56.40 32.18,56.93
             32.18,57.30 32.21,57.70 32.35,58.07
             32.75,59.43 33.44,60.10 34.41,60.10
             35.77,60.10 36.41,58.43 36.51,57.40
             36.51,57.40 35.17,57.97 35.17,57.97
             35.17,57.97 35.31,57.63 35.31,57.63
             35.81,56.40 36.14,54.33 35.31,52.57
             35.31,52.57 35.14,52.23 35.14,52.23
             35.14,52.23 37.50,53.23 37.50,53.23
             37.50,53.23 36.77,49.80 36.77,49.80
             36.77,49.80 39.77,49.80 39.77,49.80
             40.23,47.97 40.43,46.53 40.43,45.37
             40.43,43.47 39.77,41.70 38.70,40.63
             37.94,39.87 36.57,38.93 34.48,38.93
             32.28,38.93 30.45,39.77 28.19,41.10
             28.19,41.10 28.02,40.87 28.02,40.87
             28.62,40.23 29.22,39.67 29.78,39.20
             29.45,39.00 29.25,38.60 29.25,38.20
             29.25,37.53 29.78,37.00 30.42,37.00
             30.98,37.00 31.45,37.43 31.55,38.00
             31.81,37.83 32.11,37.70 32.38,37.60
             32.21,37.40 32.11,37.13 32.11,36.83
             32.11,36.17 32.65,35.63 33.28,35.63
             33.91,35.63 34.44,36.17 34.44,36.83
             34.44,36.93 34.44,37.03 34.41,37.13
             34.78,37.10 35.04,37.10 35.41,37.13
             35.41,37.10 35.41,37.03 35.41,37.00
             35.41,36.33 35.94,35.80 36.57,35.80
             37.20,35.80 37.74,36.33 37.74,37.00
             37.74,37.20 37.70,37.43 37.57,37.63
             37.84,37.77 38.14,37.90 38.40,38.07
             38.60,37.63 39.00,37.33 39.47,37.33
             40.10,37.33 40.63,37.87 40.63,38.53
             40.63,38.90 40.47,39.23 40.20,39.47
             40.40,39.70 40.57,39.90 40.73,40.13
             40.97,39.93 41.23,39.80 41.53,39.80
             42.16,39.80 42.70,40.33 42.70,41.00
             42.70,41.60 42.30,42.10 41.76,42.20
             41.86,42.50 41.93,42.77 42.00,43.00
             42.13,42.93 42.30,42.90 42.46,42.90
             43.09,42.90 43.63,43.43 43.63,44.10
             43.63,44.77 43.09,45.30 42.46,45.30
             42.43,45.30 42.36,45.30 42.33,45.30
             42.33,45.40 42.33,45.50 42.33,45.60
             42.33,45.70 42.33,45.80 42.33,45.90" />
  </svg>
);
