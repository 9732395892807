import React, { useState } from 'react';
import Draggable from "react-draggable";
import { HeatMapGrid } from "react-grid-heatmap";

import { useTheme } from "@mui/material/styles";
import Paper, {PaperProps} from "@mui/material/Paper";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';


import { ExploreFactor, ExploreFactorResult, ExploreFactorScore } from "@/interfaces";
import { ExploreFactorInfoDialog } from '@/components/molecules';


type GroupedResultType = {
  [key: string]: Array<number>;
}

function groupBy(array: Array<any>, property: string, value: string): GroupedResultType {
  return array.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj[value]);
    return acc
  }, {});
}

function sumValue(results: Array<ExploreFactorResult>, category: string) {
  return results.filter(x => x.category === category).map(x => x.num).reduce((a, b) => a + b, 0)
}

function heatMapColor(x: number, y: number, ratio: number) {
  const wt = 4 - x;
  const p = y + 1;
  if (p > 7 && wt > 3) {
    return '#00656d'
  } else if ((p > 5 && wt >= 3) || (p > 2 && wt > 3)) {
    return '#009cb1'
  } else if ((p > 0 && wt > 2) || (p > 3 && wt > 1)) {
    return '#26cae5'
  } else {
    return 'rgba(190, 229, 235, 0.9)'
  }
}

type ScoreRow = {
  category: string;
  factor_1: number;
  factor_2: number;
  factor_3: number;
  factor_4: number;
  score: number;
  total: number;
};

function createRow(fact: ExploreFactorScore): ScoreRow {
  return {
    category: fact.category,
    factor_1: Math.round(fact.a),
    factor_2: Math.round(fact.b),
    factor_3: Math.round(fact.c),
    factor_4: Math.round(fact.d),
    score: Math.round(fact.score),
    total: fact.num,
  };
}

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#factor-summary-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreFactorSummaryDialogProps = {
  factor: ExploreFactor;
  factorResult: Array<ExploreFactorResult>;
  factorScores: Array<ExploreFactorScore>;
  onClose: () => void;
}

export const ExploreFactorSummaryDialog: React.FC<ExploreFactorSummaryDialogProps> = (
  {
    factor,
    factorResult,
    factorScores,
    onClose
}) => {

  const theme = useTheme();
  const groupedResult: Array<Array<number>> = Object.values(groupBy(factorResult, 'wt', 'num')).reverse();

  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 64,
        right: 68,
        width: '600px',
        height: '580px', //564
        overflow: 'hidden',
        borderRadius: '6px',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // height: '100%',
          // overflow: 'hidden'
        }}
      >
        <CardHeader
          id='factor-summary-dialog'
          title={factor.name}
          subheader='Development Quality Score'
          sx={{ pb: 0 }}
          action={
            <IconButton onClick={handleInfoOpen}>
              <InfoIcon />
            </IconButton>
          }
        />
        <CardContent sx={{ overflowY: 'auto' }} >
          <Typography variant='caption' sx={{ mb: 1 }}>
            The following table shows how the sectors are distributed and how the buckets are assigned:
          </Typography>
          <Box mt={1}>
            <Typography variant='body2' align='center' sx={{ fontSize: '12px', ml: 6 }}>Decile</Typography>
            <HeatMapGrid
              yLabels = {['4 Factors', '3 Factors', '2 Factors', '1 Factor']}
              xLabels = {['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th']}
              // data={Array(4).fill(0).map(() => new Array(9).fill(0).map(() => Math.floor(Math.random() * 50 + 50)))}
              data={groupedResult}
              cellHeight={'24px'}
              cellRender={(x, y, value) => (
                value
              )}
              cellStyle={(x, y, ratio) => ({
                background: heatMapColor(x, y, ratio),
                fontSize: '12px',
                color: `#FFF`,
                border: '1px solid #212B3A'
              })}
              xLabelsStyle={() => ({ fontSize: '12px' })}
              yLabelsStyle={() => ({ fontSize: '12px', marginRight: '4px' })}
            />
          </Box>
          <Stack direction='row' spacing={3} mt={2} mb={2} justifyContent={'center'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box height={10} width={10} bgcolor={'rgba(190, 229, 235, 0.8)'} borderRadius={'2px'} mr={1}/>
              <Typography variant='caption'>{`Other (${sumValue(factorResult, 'Other')})`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box height={10} width={10} bgcolor={'#00B1CC'} borderRadius={'2px'} mr={1}/>
              <Typography variant='caption'>{`Low (${sumValue(factorResult, 'Low')})`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box height={10} width={10} bgcolor={'#008899'} borderRadius={'2px'} mr={1}/>
              <Typography variant='caption'>{`Medium (${sumValue(factorResult, 'Medium')})`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box height={10} width={10} bgcolor={'#00656d'} borderRadius={'2px'} mr={1}/>
              <Typography variant='caption'>{`High (${sumValue(factorResult, 'High')})`}</Typography>
            </Box>
          </Stack>
          <Typography variant='caption' sx={{ mb: 1 }}>
            The following table shows the average score each bucket has for each factor:
          </Typography>
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', py: 1, width: '20%', color: theme.palette.text.secondary}}>Bucket</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 1</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 2</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 3</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 4</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Average</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Sectors</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {factorScores.sort((a, b) => b.score - a.score).map((bucket, bucketIdx) => {
                const row = createRow(bucket);
                return (
                  <TableRow key={`bucket-score-row-${bucketIdx}`}>
                    <TableCell align='left' sx={{
                      fontSize: '12px',
                      fontWeight: 600,
                      py: 1,
                      color: row.category === 'High' ? '#00656d' : row.category === 'Medium' ? '#009cb1' : row.category === 'Low' ? '#26cae5' : 'rgba(190, 229, 235, 0.8)'
                    }}>
                      {row.category}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_1 > 100 ? theme.palette.success.light : row.factor_1 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_1}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_2 > 100 ? theme.palette.success.light : row.factor_2 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_2}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_3 > 100 ? theme.palette.success.light : row.factor_3 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_3}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_4 > 100 ? theme.palette.success.light : row.factor_4 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_4}
                    </TableCell>
                    <TableCell align='center' sx={{fontSize: '12px'}}>{row.score}</TableCell>
                    <TableCell align='center' sx={{fontSize: '12px'}}>{row.total}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
      <ExploreFactorInfoDialog open={infoOpen} onClose={handleInfoClose}/>
    </DraggablePaper>
  );
};
