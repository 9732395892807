import React, { useState, useEffect } from 'react';
import { useMap, GeoJSONSource, MapboxGeoJSONFeature } from "react-map-gl";
import { useAuth0 } from "@auth0/auth0-react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import PlaceIcon from '@mui/icons-material/Place';
import InfoIcon from '@mui/icons-material/Info';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

import { SimpleSelectDialog, SimplePointDialog, SimpleFactDialog, SimpleInfoDialog } from '@/components/molecules';
import { ExpandingChipList, ButtonIcon, MapLegend, Tooltip, Overlay } from "@/components/atoms";
import {
  SimpleFeatures,
  SimplePointFeature,
  SimpleFact,
  SimpleVariable,
  SimpleScore,
  SimpleScoreCard, Location
} from "@/interfaces";
import { getLads, getScores, getFact } from "@/services/ApiService";
import {times} from "lodash";
import { PostcodeSearchBar } from "@/components/atoms/PostcodeSearchBar";

const defaultSelection = [
  {category: 'Population Density', name: 'Population Density', id: 1},
];

function createLabel(variable: SimpleVariable): string {
  return variable.category.replace(/ \(.*\)/i, '') + ' | ' + variable.name
}

type SimpleOverlayProps = {
  features: SimpleFeatures;
  pointFeatures: Array<SimplePointFeature>;
  selected?: MapboxGeoJSONFeature;
  clear: () => void;
}

export const SimpleOverlay: React.FC<SimpleOverlayProps> = (
  {
    features,
    pointFeatures,
    selected,
    clear
  }) => {

  const { current: map } = useMap();
  const { getAccessTokenSilently } = useAuth0();
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [pointOpen, setPointOpen] = useState<boolean>(false);
  const [factOpen, setFactOpen] = useState<boolean>(false);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [selectedFeatures, setSelectedFeatures] = useState<Array<SimpleVariable>>(defaultSelection);
  const [selectedPoint, setSelectedPoint] = useState<string>('');
  const [pointData, setPointData] = useState<string>('');
  const [clusterMode, setClusterMode] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [fact, setFact] = useState<SimpleFact | undefined>(undefined);
  const [scorecard, setScorecard] = useState<SimpleScoreCard | undefined>(undefined);
  const [scores, setScores] = useState<Array<SimpleScore>>([]);
  const [locations, setLocations] = useState<Array<Location>>([]);

  const selectFeatures = {groups: features.groups.slice(1,), groupData: features.groupData.slice(1,), groupIcons: features.groupIcons}

  useEffect(() => {
    map && !firstTime && resetMap();
    map && scores.forEach(value => {
      map.setFeatureState(
        {id: value.geo_id, source: 'lad-source', sourceLayer: 'LAD_TCE_EWN'},
        {score: value.score}
      );
    });
    clear();
    setFactOpen(false);
    map && map.flyTo({
      center: [-4, 53.14],
      zoom: 5.77
    });
    setFirstTime(false);
  }, [map, scores]);

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLads(accessToken);
      setLocations(response);
    }
    fetchLocations().then();
  }, []);

  useEffect(() => {
    fact ? setFactOpen(true) : setFactOpen(false);
  }, [fact]);

  useEffect(() => {
    const updateScores = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getScores(
        'local_authority_districts',
        selectedFeatures.map(x => x.id).join('|'),
        accessToken
      );
      setScores(response);
    };
    selectedFeatures.length > 0 && updateScores().then();
  }, [selectedFeatures, getAccessTokenSilently]);

  useEffect(() => {
    const updateFact = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await getFact(
        'local_authority_districts',
        selected?.id || 0,
        accessToken
      );
      setFact(response.facts);
      setScorecard(response.scorecard);
    };
    selected && updateFact().then(() => setLoading(false));
  }, [selected]);

  const resetMap = () => {
    map && [...times(380)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'lad-source', sourceLayer: 'LAD_TCE_EWN'},
        {score: null}
      );
    });
  };

  const onSelect = (location: Location) => {
     map && map.flyTo({
       center: [factOpen ? location.longitude : location.longitude, location.latitude],
       zoom: 9
     });
    setFactOpen(false);
  }

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handlePointOpen = () => {
    setPointOpen(true);
  };

  const handlePointClose = () => {
    setPointOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleFactClose = () => {
    setFactOpen(false);
    clear();
  };

  const updateSelection = async (newCategory: number, newSelection: Array<SimpleVariable>) => {
    setSelectedCategory(newCategory);
    setSelectedFeatures(newSelection);
    handleSelectClose();
  };

  const updatePoint = (group: string, point: string) => {
    setSelectedPoint(point);
    updatePointSource(group, point);
    handlePointClose();
    clear();
  };

  const updatePointSource = (folder: string, key: string) => {
    const dir = folder.replace(/\s+/g, '_').toLowerCase();
    const label = key.replace(/\s+/g, '_').toLowerCase();
    setPointData(`/data/points/${dir}/points_${label}.geojson`);

    const clusterSource: GeoJSONSource | undefined = map && (map.getSource('points-cluster') as GeoJSONSource);
    const pointSource: GeoJSONSource | undefined = map && (map.getSource('points') as GeoJSONSource);
    if (clusterMode) {
      clusterSource && clusterSource.setData(`/data/points/${dir}/points_${label}.geojson`);
      pointSource && pointSource.setData('/data/points/empty/points_empty.geojson');
    } else {
      clusterSource && clusterSource.setData('/data/points/empty/points_empty.geojson');
      pointSource && pointSource.setData(`/data/points/${dir}/points_${label}.geojson`);
    }
  };

  const handleClear = () => {
    setSelectedCategory(0);
    setSelectedFeatures([]);
    setScores([]);
    setSelectedPoint('');
    updatePointSource('empty', 'empty');
    setSelectOpen(false);
    setFactOpen(false);
    resetMap();
  };

  const handleClusterToggle = () => {
    const newClusterMode = !clusterMode;

    const clusterSource: GeoJSONSource | undefined = map && (map.getSource('points-cluster') as GeoJSONSource);
    const pointSource: GeoJSONSource | undefined = map && (map.getSource('points') as GeoJSONSource);

    if (newClusterMode) {
      clusterSource && clusterSource.setData(pointData);
      pointSource && pointSource.setData('/data/points/empty/points_empty.geojson');
    } else {
      clusterSource && clusterSource.setData('/data/points/empty/points_empty.geojson');
      pointSource && pointSource.setData(pointData);
    }

    setClusterMode(newClusterMode);
  }

  return (
    <>
      <Box sx={{ mt: 2, ml: 2, width: '100%'}}>
        {loading && <Overlay />}
        <Stack direction="row" spacing={3}>
          <Tooltip title='Select scores to show on the map' placement='bottom'>
            <Button
              variant="contained"
              color="info"
              onClick={handleSelectOpen}
              endIcon={<TuneIcon />}
            >
              Selection
            </Button>
          </Tooltip>
          <Tooltip title='Select Point entities to overlay' placement='bottom'>
            <Button
              variant="contained"
              color="info"
              onClick={handlePointOpen}
              endIcon={<PlaceIcon />}
            >
              Overlay
            </Button>
          </Tooltip>
          <PostcodeSearchBar options={locations} disabled={false} onSelect={onSelect}/>
          <ButtonIcon onClick={handleInfoOpen} color='info' size='36px' Icon={InfoIcon} tooltip='More Info'/>
          { (selectedFeatures.length > 0 || selectedPoint !== '') &&
            <ButtonIcon onClick={handleClear} color='info' size='36px' Icon={DoDisturbIcon} tooltip='Clear All'/>
          }
          {selectedPoint !== '' &&
            <ButtonIcon onClick={handleClusterToggle} color='info' size='36px' Icon={BubbleChartIcon} tooltip='Toggle Cluster Mode'/>
          }
        </Stack>
      </Box>
      <Box sx={{ mt: 2, ml: 2, width: '100%'}}>
        { selectedFeatures.length > 0 && <ExpandingChipList data={selectedFeatures} createLabel={createLabel} color='info' /> }
      </Box>
      <MapLegend />
      <SimpleSelectDialog
        open={selectOpen}
        data={selectFeatures}
        category={selectedCategory}
        selection={selectedFeatures}
        onCancel={handleSelectClose}
        onApply={updateSelection}
      />
      <SimplePointDialog
        open={pointOpen}
        data={pointFeatures}
        selected={selectedPoint}
        onCancel={handlePointClose}
        onApply={updatePoint}
      />
      <SimpleInfoDialog
        open={infoOpen}
        onClose={handleInfoClose}
      />
      {factOpen && fact && scorecard && (
        <SimpleFactDialog
          fact={fact}
          scorecard={scorecard}
          onClose={handleFactClose}
        />
      )}
    </>
  );
};
