import React, { useState, useEffect } from 'react';
import { useMap, MapboxMap, MapRef } from "react-map-gl";
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Chip from '@mui/material/Chip';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import CheckIcon from '@mui/icons-material/Check';
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import { ButtonIcon, ButtonIconGroup, DrivetimeSlider, LocationSearchBar, Overlay } from '@/components/atoms';
import { getLocations, getLocationCatchment, getLocationDrivetime} from '@/services/ApiService';
import { Location, MarkerType } from '@/interfaces';


const LARGE_BASELINES = ['Region', 'Country'];


type CatchmentBaselineOverlayProps = {
  disabled: boolean;
  enable: () => void;
  selected: Array<Location>;
  setSelected: (value: Array<Location>) => void;
  baseline: Array<Location>;
  setBaseline: (value: Array<Location>, loc: Location | null) => void;
  marker: MarkerType | null;
  updateMap: (map: MapboxMap | MapRef, sectors: Array<Location>, mode:string, type: string) => void;
  clearMap: (map: MapboxMap | MapRef, mode: string) => void;
  setMarker: (value: MarkerType | null) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const CatchmentBaselineOverlay: React.FC<CatchmentBaselineOverlayProps> = ({
  disabled,
  enable,
  selected,
  setSelected,
  baseline,
  setBaseline,
  marker,
  updateMap,
  clearMap,
  setMarker,
  loading,
  setLoading
}) => {

  const { current: map } = useMap();
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [location, setLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [mode, setMode] = useState<string>('sector');
  const [drivetime, setDrivetime] = useState<number>(20);
  const [error, setError] = useState<string | null>(null);

  const ukLocation: Location = {geo_id: 0, geo_name: 'UK', latitude: 52.3555, longitude: -1.1743	, level:'Country'}

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLocations(true, accessToken);
      setLocations([ukLocation, ...response]);
    }
    fetchLocations().then();
  }, []);

  useEffect(() => {
    if (baseline.length === 0 && selected.length === 0) {
      setMode('sector');
      setLocation(null);
      setDrivetime(20);
    }
  }, [disabled]);

  useEffect(() => {
    switch (mode) {
      case 'drivetime':
        marker &&
        updateSelection(
          mode,
          {geo_id: 0, level: '', geo_name: '', latitude: marker.latitude, longitude: marker.longitude},
          drivetime
        );
        break;
      case 'manual':
        setDrivetime(20)
        updateSelection(
          mode,
          {geo_id: 0, level: '', geo_name: '', latitude: 0, longitude: 0}
        );
        break;
      case 'sector':
        setDrivetime(20);
        location && updateSelection(mode, location);
        break;
    }
  }, [mode, location, drivetime]);

  useEffect(() => {
    marker && mode === 'drivetime' && (
      updateSelection(
        mode,
        {geo_id: 0, level: '', geo_name: '', latitude: marker.latitude, longitude: marker.longitude},
        drivetime
      )
    );
  }, [marker])

  const onSelect = (location: Location) => {
    const zoom = location.geo_id === 0 ? 6 : 11;
    map && map.flyTo({center: [location.longitude, location.latitude], duration: 1000, zoom: zoom});
    setMode('sector');
    setLocation(location);
    setMarker({latitude: location.latitude, longitude: location.longitude});
  };

  const updateSelection = (mode: string, location: Location, drivetime?: number) => {
    const callApi = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      if (mode === 'drivetime') {
        return await getLocationDrivetime(
          location.latitude,
          location.longitude,
          drivetime ? drivetime : 20,
          accessToken
        );
      } else if (mode === 'manual') {
        return [];
      } else {
        return await getLocationCatchment(
          location.geo_id,
          location.level,
          accessToken
        );
      }
    }
    callApi().then((sectors) => {
      setLoading(false);
      if (map) {
        clearMap(map, 'baseline');
        if (sectors.length > 0) {
          setSelected(sectors);
          updateMap(map, sectors, 'baseline', 'add');
        } else {
          setSelected([]);
        }
      }
    }).catch((e) => {
      if ( map ) {
        setSelected([]);
        clearMap(map, 'baseline');
      }
      setError('drivetime');
    });
  };

  const handleApply = () => {
    setBaseline(selected, location);
    setSelected([]);
  };

  const handleEdit = () => {
    enable();
  }

  const handleClear = () => {
    map && clearMap(map, 'baseline');
    setLocation(null);
    setSelected([]);
    setBaseline([], null);
    setMarker(null);
  };

  const handleModeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setMode(value);
  };

  const handleDrivetimeChange = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    if (typeof value === 'number') {
      setDrivetime(value);
    }
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  return (
    <>
      {loading && <Overlay />}
      {(!disabled  || baseline.length > 0) &&
        <Box sx={{mt: 2, ml: 2, width: '100%'}}>
          <Typography
            variant='overline'
            sx={{ position: 'relative', color: theme.palette.primary.dark,  ml: '2px' }}
          >
            Baseline
          </Typography>
          <Stack direction="row" spacing={3}>
            <LocationSearchBar options={locations} showLabel={true} disabled={disabled} onSelect={onSelect} color="secondary"/>
            {baseline.length > 0 &&
              <Chip
                color='primary'
                icon={<LocationOnIcon />}
                label={`${baseline.length} Sectors`}
                sx={{ position: 'relative', height: '40px', borderRadius: '6px' }}/>
            }
            {!disabled && location &&
              <ButtonIconGroup
                value={mode}
                onClick={handleModeChange}
                options={[
                  'sector',
                  'drivetime',
                  'manual'
                ]}
                disabled={[
                  false,
                  location && LARGE_BASELINES.includes(location.level),
                  location && LARGE_BASELINES.includes(location.level)
                ]}
                icons={[
                  <SelectAllIcon/>,
                  <DirectionsCarIcon/>,
                  <TouchAppIcon/>
                ]}
                tooltips={['Contained', 'Drivetime', 'Manual']}
                color='primary'
                size='40px'
              />
            }
            {!disabled && marker && mode === 'drivetime' &&
              <DrivetimeSlider drivetime={drivetime} onDrivetime={handleDrivetimeChange} color='primary'/>
            }
            {baseline.length > 0 &&
              <ButtonIcon
                onClick={handleEdit}
                color='primary'
                size='40px'
                Icon={EditLocationAltIcon}
                tooltip='Edit Baseline'
              />
            }
            {selected.length > 0 &&
              <ButtonIcon
                onClick={handleClear}
                color='primary'
                size='40px'
                Icon={DoDisturbIcon}
                tooltip='Clear Selection'
              />
            }
            {selected.length > 0 &&
              <ButtonIcon
                onClick={handleApply}
                color='primary'
                size='40px'
                Icon={CheckIcon}
                tooltip='Create Baseline'
              />
            }
          </Stack>
        </Box>
      }
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        sx={{ mb: 2, ml: 2 }}
      >
        <Alert
          onClose={handleErrorClose}
          color='error'
          severity='error'
          sx={{ background: theme.palette.background.default, borderRadius: '6px'}}
        >
          {error === 'drivetime'
            ? 'Unable to calculate drivetime. Make sure the marker is near a road and try again.'
            : 'There has been an error creating the baseline. Please try again.'
          }
        </Alert>
      </Snackbar>
    </>
  );
};
