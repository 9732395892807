import React from 'react';

import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { LandingOverlay } from '@/components/molecules';

const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';

export const LandingMap = () => {

  return (
    <>
    <Map
      initialViewState={{
        longitude: -4.13,
        latitude: 53.14,
        zoom: 5.77
      }}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      interactive={false}
    >

    </Map>
    <LandingOverlay />
    </>
  );
}
