import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';
import { cloneDeep } from 'lodash';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { SimplePointFeature } from '@/interfaces';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-point-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimplePointDialogProps = {
  open: boolean;
  data: Array<SimplePointFeature>;
  selected: string;
  onCancel: () => void;
  onApply: (folder: string, point: string) => void;
};


export const SimplePointDialog: React.FC<SimplePointDialogProps> = (
  {
    open,
    data,
    selected,
    onCancel,
    onApply
  }) => {

  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedPoint, setSelectedPoint] = useState<string>(cloneDeep(selected));
  const [expanded, setExpanded] = useState<string | false>(false);
  useEffect(() => { setSelectedPoint(cloneDeep(selected)) }, [selected]);

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>, variable: string) => {
    setSelectedGroup(variable.split(' - ')[0]);
    setSelectedPoint(variable.split(' - ')[1]);
  };

  const handleExpand = (panel: string) => (e: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Dialog
      aria-labelledby="simple-point-dialog"
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth='xs'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          position: "fixed",
          top: 100,
          left: 0,
          maxHeight: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="simple-point-dialog"
      >
        Select points of interest to display
      </DialogTitle>
      <DialogContent sx={{ mb: 1, height: '100%', overflowY: 'scroll' }}>
        {data.map((pointFeature, featureIdx) => (
          <Accordion
            key={`point-panel-${featureIdx}`}
            elevation={4}
            expanded={expanded === pointFeature.class}
            onChange={handleExpand(pointFeature.class)}
          >
            <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
              <Typography
                color={( pointFeature.class === selectedGroup && pointFeature.types.includes(selectedPoint)) ? 'primary' : 'default'}
              >
                {pointFeature.class}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={`${selectedGroup} - ${selectedPoint}`}
                onChange={onSelect}
              >
                {pointFeature.types.map((pointType, typeIdx) => (
                  <FormControlLabel key={`point-label-${featureIdx}-${typeIdx}`} value={`${pointFeature.class} - ${pointType}`} control={<Radio size='small'/>} label={pointType} />
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onCancel}>
          Cancel
        </Button>
        <Button color='primary' onClick={() => onApply('empty', 'empty')}>
          Clear
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => onApply(selectedGroup, selectedPoint)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
