import mapboxgl from "mapbox-gl";

export const addCursorLogic = (map: mapboxgl.Map, layerId: string) => {
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });
};

export const addHoverLogic = (
  map: mapboxgl.Map,
  layerId: string,
  source: string,
  sourceLayer: string,
  id: string | number | undefined,
  popup: mapboxgl.Popup
) => {
  map.on('mousemove', layerId, (event) => {
    if (id) {
      map.setFeatureState(
        { id: id, source, sourceLayer },
        { hover: false }
      );
    }
    const feature = event.features && event.features[0];
    if (feature) {
      id = feature.id;
      map.setFeatureState(
        { id: feature.id, source, sourceLayer },
        { hover: true }
      );
      // Add a popup if selected
      if ('selected' in feature.state && feature.state.selected) {
        const lat = feature.properties && feature.properties.latitude;
        const lng = feature.properties && feature.properties.longitude;
        const contents = feature.properties && feature.properties.geo_code;
        popup.setLngLat([lng, lat]).setHTML(contents).addTo(map);
      }
    }
  });

  map.on('mouseleave', layerId, () => {
    if (id) {
      map.setFeatureState(
        { id: id, source, sourceLayer },
        { hover: false }
      );
    }
    id = undefined;
  });
};

export const addClickLogic = (
  map: mapboxgl.Map,
  layerId: string,
  source: string,
  sourceLayer: string,
  ids: Array<string | number>,
  point: string | number | undefined,
  setActive: (selection: Array<string | number>) => void,
  loading: boolean

) => {
  map.on('click', layerId, (event) => {

    event.preventDefault();
    if (event.originalEvent.cancelBubble) {
      return;
    }

    if (event.lngLat.lat === 0) {
      ids.forEach(id => clearSelectedId(map, id, source, sourceLayer));
      ids = [];
      setActive(ids);
    } else {
      if (event.features && event.features.length > 0) {
        const feature = event.features[0];

        if (feature.id) {
          if (event.originalEvent.ctrlKey) {
            if (ids.includes(feature.id)) {
              clearSelectedId(map, feature.id, source, sourceLayer);
              ids = ids.filter(x => x !== feature.id);
              setActive(ids);
            } else {
              ids = [...ids, feature.id];
              setActive(ids);
              setSelectedId(map, feature.id, source, sourceLayer);
            }
          } else {
            ids.forEach(id => clearSelectedId(map, id, source, sourceLayer));
            ids = [feature.id];
            setActive(ids);
            setSelectedId(map, feature.id, source, sourceLayer);
          }
        }
      }
    }
  });
}

export const addPointClickLogic = (
  map: mapboxgl.Map,
  label: string,
  source: string,
  id: string | number | undefined,
  polygons: Array<string | number>,
  setActivePoint: (id: string | number) => void,
  setActivePolygons: (selection: Array<string | number>) => void,
  loading: boolean

) => {
  map.on('click', label, (event) => {

    event.preventDefault();
    // Set points as the highest layer
    event.originalEvent.cancelBubble = true;

    // Clear previous selection
    if (id) {
      clearSelectedId(map, id, source, undefined);
    }
    if (polygons.length > 0) {
      polygons.forEach(id => clearSelectedId(map, id, 'postal-sector-source', 'Sector_TCE'));
      polygons = [];
    }

    // Set selected values
    if (event.features && event.features.length > 0) {
      id = event.features[0].id;
      id && setSelectedId(map, id, source, undefined);
      setActivePolygons([]);
      event.features[0].properties && setActivePoint(event.features[0].properties.feature_id);
    }
  });
}

export const clearSelectedId = (map: mapboxgl.Map, id: string | number, source: string, sourceLayer: string | undefined) => {
  map.setFeatureState(
    { id, source, sourceLayer },
    { selected: false }
  );
}

export const setSelectedId = (map: mapboxgl.Map, id: string | number, source: string, sourceLayer: string | undefined) => {
  map.setFeatureState(
    { id, source, sourceLayer },
    { selected: true }
  );
}


export const simulateClick = (map: mapboxgl.Map, lng: number, lat: number) => {

  const coords = new mapboxgl.LngLat(lng, lat);

  map.fire('click', {
    lngLat: coords,
    point: map.project(coords),
    originalEvent: {},
    preventDefault: () => null
  });
}


export const simulateClearClick = (map: mapboxgl.Map) => {
  map.fire('click', {
    lngLat: new mapboxgl.LngLat(0, 0),
    originalEvent: {},
    preventDefault: () => null
  });
}
