import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { ScoreCardCategory, ScoreCardGroup, Location, ScoreCardResponse } from "@/interfaces";
import { RadarChart, UnderlineText, ScorecardScore, ScorecardDoubleScore } from "@/components/atoms";
import { useTheme, lighten } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ScorecardCard } from "@/components/molecules";
import { toColor } from "@/utils/common";

type ScorecardGroupCardProps = {
  group: string;
  locations: Array<Location | null>;
  firstData: ScoreCardCategory[];
  secondData?: ScoreCardCategory[];
  groupScores: Array<ScoreCardGroup | undefined>;
};

export const ScorecardGroupCard: React.FC<ScorecardGroupCardProps> = (
  {
    group,
    locations,
    firstData,
    secondData,
    groupScores
  }
) => {

  const theme = useTheme();

  const getGroupNameScores = (scores: Array<ScoreCardGroup>, name: string): Array<ScoreCardCategory> => {
    return scores.filter(x => x.name === name)[0].scores || [];
  }

  const getCategoryNameScores = (scores: Array<ScoreCardGroup>, group: string, category: string): ScoreCardCategory | undefined => {
    const groupScores = scores.filter(x => x.name === group)
    if (groupScores.length > 0) {
      return groupScores[0].scores.filter(x => x.name === category)[0];
    } else {
      return undefined
    }
  }

  const getCategoryScores = (scores: Array<ScoreCardCategory>, category: string): ScoreCardCategory | undefined => {
    if (scores.length > 0) {
      return scores.filter(x => x.name === category)[0];
    } else {
      return undefined
    }
  }

  const flattenScores = (scores: Array<ScoreCardGroup>) => {
    return scores.filter(x => x.name !== 'Opportunity').map(x => x.scores.map(y => ({group_name: x.name, ...y}))).flat();
  }

  function cut(s: string, n: number) {
    var trimmedString = s.substr(0, n);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString
  }

  return (
    <Card elevation={0} sx={{ width: secondData ? '340px': '280px' }}>
      <CardHeader
        disableTypography
        title={
          // <Typography variant='h6' align='center'>{group}</Typography>
          groupScores[1] ?
            <ScorecardDoubleScore
              name={group}
              firstScore={groupScores[0]?.percentile_wt || 0}
              firstColor={toColor(groupScores[0]?.percentile_wt || 0)}
              secondScore={groupScores[1]?.percentile_wt || 0}
              secondColor={toColor(groupScores[1]?.percentile_wt || 0)}/>
            :
          <ScorecardScore name={group} score={groupScores[0]?.percentile_wt || 0} color={toColor(groupScores[0]?.percentile_wt || 0)} stretch={true}/>
        }
        sx={{ backgroundColor: lighten(theme.palette.background.default, 0.1) }}
      />
      {/*<CardContent sx={{ p: 1, borderWidth: '1px', borderStyle: 'solid', borderColor: lighten(theme.palette.background.default, 0.1) }}>*/}
      <CardContent sx={{ p: 1, borderWidth: '1px', borderStyle: 'solid', borderColor: lighten(theme.palette.background.default, 0.1) }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadarChart
                values={secondData ?
                  [firstData.flat().map(x => x.percentile_wt), secondData.flat().map(x => x.percentile_wt)]
                  : [firstData.flat().map(x => x.percentile_wt)]}
                labels={firstData.flat().map(x => x.name)}
                height={264}
                padding={0}
                circular={false}
              />
            </Grid>
            {locations[1] &&
              <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-end">
                <Stack direction="row" spacing={1}>
                  {locations.map((location, locationIdx) => (
                    <UnderlineText
                      text={location?.geo_name || ''}
                      color={locationIdx === 0 ? theme.palette.primary.main : theme.palette.secondary.main}
                      variant="overline"
                      textProps={{noWrap: true, align: "center", sx: {width: '65px'} }}
                    />
                  ))}
                </Stack>
              </Grid>
            }
            {firstData && firstData.map((category, categoryIdx) => (
              <Grid item xs={12} key={`score-card-${categoryIdx}`}>
                <ScorecardCard
                  key={`score-card-${categoryIdx}`}
                  category={category}
                  compare={secondData ? getCategoryScores(secondData, category.name) : undefined}
                  startExpanded={true}
                />
              </Grid>
            ))}
          </Grid>
        </>
      </CardContent>
    </Card>
  );
}
