import React from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#catchment-info-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type CatchmentInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};


export const CatchmentInfoDialog: React.FC<CatchmentInfoDialogProps> = (
  {
    open,
    onClose
  }) => {

  return (
    <Dialog
      aria-labelledby="catchment-info-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          maxHeight: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="catchment-info-dialog"
      >
        About
      </DialogTitle>
      <DialogContent>
        <Typography>
          This mode allows you to compare a catchment to a baseline.
          Both the catchment and the baseline are made up of one or more Postal Sectors.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography sx={{ mb: 2}}>
          First select a catchment.
          You can do this by holding the ctrl key and clicking on the map.
          Re-selecting a Postal Sector will remove it from the selection.
        </Typography>
        <Typography>
          Use the search bar to help you find locations, here you can type in the name of a location (for example
          a City, Local Authority District, Postal Area or even the name of a Crown Estate Asset and the map will
          move to that location and highlight the contained Postal Sector(s) to get you started.
          From here you can build up the catchment by selecting or de-selecting Postal Sectors.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography sx={{ mb: 2}}>
          You can change the way the catchment is created by toggling to a different mode:
        </Typography>
        <Typography gutterBottom sx={{ ml: 1 }}>
          - Contained: Select all Postal Sectors that are contained by your selected location. For example if you select
          a Local Authority District then all Postal Sectors that are geographically within it (or have a 50% overlap
          with it) will be selected. If you select a City or a Crown Estate Asset then only the Postal Sector that
          contains the centre will be included.
        </Typography>
        <Typography gutterBottom sx={{ ml: 1 }}>
          - Drivetime: Find all Postal Sectors that are reachable within a set drivetime from your location.
          You can adjust the drivetime using the slider provided and can move the marker for your location
          to define the starting point for the drivetime.
        </Typography>
        <Typography sx={{ ml: 1, mb: 2}}>
          - Manual: Remove any selected Postal Sectors and define the catchment yourself.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography>
          Once you have confirmed the catchment you now need to define the baseline.
          The process here is exactly the same as for the catchment but you now have the option to select larger
          geographies such as the whole UK or a Region.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
