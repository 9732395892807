import React from 'react';

import { ExploreFactScoreCard } from '@/components/molecules';
import { ExploreFact } from '@/interfaces';

type Scores = {
  [key: string]: any;
}

type FactLifestyleProps = {
  data: Scores;
};

export const FactLifestyle:  React.FC<FactLifestyleProps> = ({
  data
}) => {

  return (
    <>
      <ExploreFactScoreCard
        title='Interests'
        scores={data['Interests']['Top Interests'].sort((a: ExploreFact, b: ExploreFact) => b.value_avg - a.value_avg)}
        startExpanded={false}
      />
      <ExploreFactScoreCard
        title='Mindsets'
        scores={data['Mindsets']['Top Mindsets'].sort((a: ExploreFact, b: ExploreFact) => b.value_avg - a.value_avg)}
        startExpanded={false}
      />
      <ExploreFactScoreCard
        title='Causes'
        scores={data['Causes']['Top Causes'].sort((a: ExploreFact, b: ExploreFact) => b.value_avg - a.value_avg)}
        startExpanded={false}
      />
    </>
  );
};
