
export const sources = [
  {
    name: 'postal-district-source',
    url: 'mapbox://mpilarczykstarcount.postal_district_test',
  },
  {
    name: 'postal-sector-source',
    url: 'mapbox://mpilarczykstarcount.Sector_TCE'
    // url: 'mapbox://mpilarczykstarcount.postal_sector_test',
    // url: 'mapbox://adamsmithstarcount.1bsr73zh',
  }
];
