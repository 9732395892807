import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme } from '@mui/material/styles';
import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogContentText  from '@mui/material/DialogContentText';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";

import { saveMode } from '@/services/ApiService';

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#save-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SaveDialogProps = {
  open: boolean;
  mode: string;
  names: Array<string>;
  onCancel: () => void;
  onSave: (response: Array<any>) => void;
  buildResult: (name: string) => object;
};

export const SaveDialog: React.FC<SaveDialogProps> = (
{
  open,
  mode,
  names,
  onCancel,
  onSave,
  buildResult
}) => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    names.includes(name) ? setNameError(true) : setNameError(false);
  }, [name, names])

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSave = () => {
    const callApi = async () => {
      setSaving(true);
      const result = buildResult(name);
      const accessToken = await getAccessTokenSilently();
      const response = await saveMode(
        mode,
        result,
        accessToken
      );
      onSave(response);
    }
    callApi().then(() => {
      setSaving(false);
      onCancel();
    });
  };

  return (
    <Dialog
      aria-labelledby="save-dialog"
      open={open}
      fullWidth
      maxWidth='sm'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2
      }}
    >
      <DialogTitle
        id="save-dialog"
        sx={{ textTransform: 'capitalize' }}
      >
        {`Name This ${mode}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          {`To save this ${mode} you need to give it a name.`}
        </DialogContentText>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="overline">{`${mode} Name`}</Typography>
          {nameError ?
            <Typography variant="overline" sx={{ color: theme.palette.error.main }}>Name already exists</Typography>
            : null}
        </Box>
        <TextField
          variant='outlined'
          size='small'
          color={nameError ? 'error' : 'primary'}
          fullWidth
          value={name}
          onChange={onNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={saving}>
          Cancel
        </Button>
        <Button variant="contained" onClick={ nameError ? handleSave : handleSave } color="primary" disabled={ name === '' || saving }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
