import React from 'react';

import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#legend"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ExploreFactorMapLegend = () => {

  const theme = useTheme();

  return (
    <DraggablePaper
      id='legend'
      elevation={2}
      sx={{
        position: "fixed",
        bottom: 40,
        left: 16,
        width: '120px',
        p: 1,
        // background: 'transparent'
      }}
    >
      <Stack direction='column' spacing={1} justifyContent={'center'}>
        {/*<Typography variant='body1'>Legend</Typography>*/}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box height={10} width={10} bgcolor={'rgba(190, 229, 235, 0.8)'} borderRadius={'2px'} mr={1}/>
          <Typography variant='body2'>Other</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box height={10} width={10} bgcolor={'#00B1CC'} borderRadius={'2px'} mr={1}/>
          <Typography variant='body2'>Low </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box height={10} width={10} bgcolor={'#008899'} borderRadius={'2px'} mr={1}/>
          <Typography variant='body2'>Medium</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box height={10} width={10} bgcolor={'#00656d'} borderRadius={'2px'} mr={1}/>
          <Typography variant='body2'>High</Typography>
        </Box>
      </Stack>
    </DraggablePaper>
  );
}
