import React from 'react';

import { ExploreFactValueCard } from '@/components/molecules';


type Scores = {
  [key: string]: any;
}

type FactProximityProps = {
  data: Scores;
  level: string;
};

export const FactProximity:  React.FC<FactProximityProps> = ({
  data, level
}) => {

  return (
    <>
      <ExploreFactValueCard title='Driving' scores={data['Driving']} category='Driving' />
      <ExploreFactValueCard title='Walking' scores={data['Walking']} category='Walking' />
    </>
  );
};
