import { CsvBuilder } from 'filefy';
import { utils, writeFile } from 'xlsx';
import { chunk } from 'lodash';
import { ExploreFactor, ExploreFactorResponse, ExploreFilterResult, ExploreVariable, Sector, ExploreFact } from "@/interfaces";

export const downloadData = (data: any[], fields: string[], name: string) => {
  const builder = new CsvBuilder(`${name}.csv`);
  builder
    .setDelimeter(',')
    .setColumns(fields)
    .addRows(data)
    .exportFile();
};

export const buildFactExport = (fact: any) => {

  const wb = utils.book_new();

  const summary = [
    ['Group', 'Category', 'Name', 'Score'],
    ...fact['Summary']['Over Performing']['Top Scores'].map((item: ExploreFact) => [
      'Over Performing',
      item.type.replace(/^[0-9]+-? /m, '').split(' - ')[0],
      item.type.includes(' - ') ? item.type.replace(/^[0-9]+-? /m, '').split(' - ')[1] : '',
      Math.round(Number(item.value_avg))
    ]),
    ...fact['Summary']['Under Performing']['Bottom Scores'].map((item: ExploreFact) => [
      'Under Performing',
      item.type.replace(/^[0-9]+-? /m, '').split(' - ')[0],
      item.type.includes(' - ') ? item.type.replace(/^[0-9]+-? /m, '').split(' - ')[1] : '',
      Math.round(Number(item.value_avg))
    ])
  ]
  const summaryWS = utils.aoa_to_sheet(summary);
  summaryWS["!cols"] = [{ "wch": 15 }, { "wch": 15 }, { "wch": 15 }, { "wch": 15 }]
  utils.book_append_sheet(wb, summaryWS, "Summary");

  const lifestyle = [
    ['Category', 'Name', 'Score'],
    ...fact['Lifestyle']['Interests']['Top Interests'].map((item: ExploreFact) => [
      'Interests',
      item.type,
      Math.round(Number(item.value_avg))
    ]),
    ...fact['Lifestyle']['Mindsets']['Top Mindsets'].map((item: ExploreFact) => [
      'Mindsets',
      item.type,
      Math.round(Number(item.value_avg))
    ]),
    ...fact['Lifestyle']['Causes']['Top Causes'].map((item: ExploreFact) => [
      'Causes',
      item.type,
      Math.round(Number(item.value_avg))
    ])
  ]
  const lifestyleWS = utils.aoa_to_sheet(lifestyle);
  lifestyleWS["!cols"] = [{ "wch": 15 }, { "wch": 15 }, { "wch": 15 }, { "wch": 15 }]
  utils.book_append_sheet(wb, lifestyleWS, "Lifestyle");

  const groups = ['Proximity', 'Housing', 'Demographics', 'Other'];
  groups.forEach((group) => {
    const rows = [
      ['Group', 'Category', 'Name', 'Base', 'Value', 'Index'],
    ]
    Object.keys(fact[group]).forEach((category) => {
      Object.keys(fact[group][category]).forEach((source) => {
        fact[group][category][source].forEach((item: ExploreFact) => {
          rows.push([category, source, item.type.replace(/^[0-9]+-? /m, ''), item.base.toString(), item.value_avg.toString(), (item.value_avg / item.base).toString()])
        })
      })
    })
    const ws = utils.aoa_to_sheet(rows);
    ws["!cols"] = [{ "wch": 15 }, { "wch": 15 }, { "wch": 15 }, { "wch": 15 }, { "wch": 15 }, { "wch": 15 }]
    utils.book_append_sheet(wb, ws, group);
  })

  writeFile(wb, `Toolkit ${fact.name.includes(',') ? 'Sector' : fact.name} Export.xlsx`);
}



export const buildFilterExport = (
  filterSelection: Array<ExploreVariable>,
  filterOperator: boolean,
  filterResult: Array<ExploreFilterResult>,
  filterScores: Array<Sector>
) => {

  const summary = [
    ['Locational Toolkit Export'],
    [],
    ['Mode', 'Explore'],
    ['Type', 'Filter'],
    ['Sectors', filterScores?.length],
    ['Operator', filterOperator ? 'AND' : 'OR'],
    ['Variables', 'Group', 'Category', 'Variable'],
    ...filterSelection.map((item) => ['', item.category, item.source, item.type]),
  ];
  const summaryWS = utils.aoa_to_sheet(summary);
  summaryWS["!cols"] = [{ "wch": 25 }, { "wch": 10 }, { "wch": 10 }, { "wch": 10 }, { "wch": 10 }]

  const result =[
    ['Group', 'Category', 'Variable', 'Index'],
    ...filterResult.map((item) => [item.category, item.source, item.type, item.score.toFixed(2)])
  ];
  const resultWS = utils.aoa_to_sheet(result);
  resultWS["!cols"] = [{ "wch": 15 }, { "wch": 15 }, { "wch": 20 }, { "wch": 15 }]

  const sectors = [
    ['Sector', 'Score'],
    ...filterScores.map((item) => [item.sector, item.score.toFixed(0)])
  ];
  const sectorWS = utils.aoa_to_sheet(sectors);
  sectorWS["!cols"] = [{ "wch": 10 }, { "wch": 10 }]

  const wb = utils.book_new();
  utils.book_append_sheet(wb, summaryWS, "Summary");
  utils.book_append_sheet(wb, resultWS, "Correlated Variables");
  utils.book_append_sheet(wb, sectorWS, "Sectors");
  writeFile(wb, "Toolkit Filter Export.xlsx");
}

export const buildFactorExport = (
  factorSelection: ExploreFactor,
  factorResult: ExploreFactorResponse,
  factorScores: Array<Sector>,
  factorVariables: Array<Array<ExploreVariable>>
) => {

  const factorNames = factorSelection.factors.split(',');
  const resultGrid = chunk(factorResult.result, 9);

  const summary = [
    ['Locational Toolkit Export'],
    [],
    ['Mode', 'Explore'],
    ['Type', 'Development Quality Score'],
    ['Name', factorSelection.name],
    ['Sectors', factorScores?.length],
    ['Factors'],
    ...factorNames.map((item, idx) => [idx + 1, item])
  ];
  const summaryWS = utils.aoa_to_sheet(summary);
  summaryWS["!cols"] = [{ "wch": 25 }, { "wch": 20 }, { "wch": 30 }]

  const result = [
    [],
    ['The following table shows how the sectors are distributed and how the buckets are assigned'],
    [],
    ['Percentile', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th'],
      ...resultGrid.map((row, idx) => [`${4-idx} Factors`, ...row.map((item) => item.num)]),
    [],
    [],
    ['The following table shows the average score each bucket has for each factor'],
    [],
    ['Bucket', 'Factor 1', 'Factor 2', 'Factor 3', 'Factor 4', 'Average Score', 'Number of Sectors'],
    ...factorResult.scores.map((item) => [item.category, item.a.toFixed(0), item.b.toFixed(0), item.c.toFixed(0), item.d.toFixed(0), item.score.toFixed(0), item.num])
  ]
  const resultWS = utils.aoa_to_sheet(result);

  const sectors = [
    ['Sector', 'Score'],
    ...factorScores.map((item) => [item.sector, item.score.toFixed(0)])
  ];
  const sectorWS = utils.aoa_to_sheet(sectors);
  sectorWS["!cols"] = [{ "wch": 10 }, { "wch": 10 }]

  const wb = utils.book_new();
  utils.book_append_sheet(wb, summaryWS, "Summary");
  utils.book_append_sheet(wb, resultWS, "Scores");
  utils.book_append_sheet(wb, sectorWS, "Sectors");
  writeFile(wb, "Toolkit DQS Export.xlsx");
}
