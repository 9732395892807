import React from 'react';
import Draggable from "react-draggable";

import Paper, {PaperProps} from "@mui/material/Paper";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { CatchmentScoreGroup } from '@/interfaces';
import { CatchmentCompareCard } from '@/components/molecules';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#catchment-compare-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type CatchmentCompareDialogProps = {
  results: Array<CatchmentScoreGroup>;
}

export const CatchmentCompareDialog: React.FC<CatchmentCompareDialogProps> = ({
  results
}) => {
  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 96,
        right: 62,
        width: '600px',
        height: 'calc(100% - 96px - 36px)',
        overflow: 'hidden'
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader id='catchment-compare-dialog' title='Comparison' sx={{ pb: 0 }}/>
        <CardContent sx={{ overflowY: 'auto' }} >
          {results.map((group, groupIdx) => (
            <CatchmentCompareCard key={`compare-card-${groupIdx}`} group={group} groupIdx={groupIdx}/>
          ))}
        </CardContent>
      </Card>
    </DraggablePaper>
  );
};
