import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatScore } from "@/utils/common";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

type ScorecardScoreProps = {
  name: string,
  score: number,
  color: string,
  stretch?: boolean
};

export const ScorecardScore: React.FC<ScorecardScoreProps> = ({
  name,
  score,
  color,
  stretch
}) => {

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={stretch ? 'space-between': 'normal'}>
      <Typography variant="body1" mr={1}>{name}</Typography>
      {/*<Typography variant="body1" mr={1}>{score}</Typography>*/}
      {name !== 'Opportunity' &&
        <Chip
          size="small"
          label={formatScore(score, 'pct_score')}
          sx={{borderRadius: '6px', minWidth: '32px', backgroundColor: color, color: theme.palette.background.default}}
        />
      }
    </Box>
  )
}
