import React from 'react';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


type ExploreFilterInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};


export const ExploreFilterInfoDialog: React.FC<ExploreFilterInfoDialogProps> = (
  {
    open,
    onClose
  }) => {

  return (
    <Dialog
      aria-labelledby="explore-filter-info-dialog"
      open={open}
      onClose={onClose}
      maxWidth='sm'
      PaperProps={{
        elevation: 2,
        sx: {
          position: 'fixed',
          top: 64,
          right: 68,
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="explore-filter-info-dialog"
      >
        Applying a Filter
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ mb: 1 }}>
          A filter is built from the variables that you have selected by finding all Postcode Sectors that "over index"
          for all/any (depending on the operator selected) of the variables.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Each sector has a composite score that encompasses their individual score for each variable.
          This composite score is what is shown on the map, the higher the score the darker the color of the Sector.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          We then evaluate which other variables could be correlated to your filter by summarising their score over the
          filtered sectors, weighted by the composite score mentioned above. So sectors that score higher for the filter
          you have applied will have more of an impact on the resulting correlated variables.
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          Here we show the top 20 most correlated variables.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
