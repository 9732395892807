import React from 'react';

import { useTheme, lighten } from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { Location } from "@/interfaces";


type LocationSelectBarProps = {
  options: Array<Location>;
  selectedLocation: Location | null;
  onSelect: (location: Location) => void;
  showLabel?: boolean;
  disabled?: boolean;
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error"
};

export const LocationSelectBar: React.FC<LocationSelectBarProps> = (
  {
    options,
    selectedLocation,
    onSelect,
    showLabel,
    disabled,
    color
  }) => {

  const theme = useTheme();

  const filter = createFilterOptions<Location>({
    ignoreCase: true,
    stringify: (option) => option.geo_name}
  );

  const onChange = (event: React.SyntheticEvent, newValue: Location | string | null) => {
    if (newValue) {
      if (typeof newValue !== 'string') {
        onSelect(newValue);
      }
    }
  };

  const filterOptions = (options: Array<Location>, params: FilterOptionsState<Location>) => {
    if (params.inputValue.length > 0) {
      return filter(options, params);
    } else {
      return options;
    }
  };

  const getOptionLabel = (option: string | Location) => {
    if (typeof option === 'string') {
      return option
    } else {
      return option.geo_name
    }
  }

  const isOptionEqualTo = (option: Location, value: Location) => {
    if (option && value) {
      return option.geo_id === value.geo_id;
    } else {
      return true;
    }

  }

  return (
    <Autocomplete
      multiple={false}
      disabled={disabled}
      value={selectedLocation}
      onChange={onChange}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      selectOnFocus
      isOptionEqualToValue={isOptionEqualTo}
      renderOption={(props, option: Location) => (
        <ListItem {...props} key={`${option.level}-${option.geo_name}`}>
          {showLabel ?
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Chip size='small' label={option.level} sx={{ borderRadius: '4px', fontSize: '10px', height: '14px' }} />
              <Typography variant='body2' sx={{ ml: 1 }}>{option.geo_name}</Typography>
            </Box>
            :
            <Typography variant='body2'>{option.geo_name}</Typography>
          }
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          //{...params}
          ref={params.InputProps.ref}
          color={color}
          size='small'
          disabled={disabled}
          sx={{
            width: '280px',
            backgroundColor: theme.palette.background.default,
            borderRadius: '6px',
            '& .Mui-disabled': {
              backgroundColor: lighten(theme.palette.background.default, 0.2)
            },
          }}
          inputProps={params.inputProps}
          InputProps={{
            sx: {
              borderRadius: '6px'
            }
          }}
          placeholder='Select a Location'
        />
      )}
    />
  )
}
