

export const formatSimpleFact = (value: number | null, category: string) => {
  if (value !== null) {
    switch (category) {
      case 'Population':
        return toDensity(value);
      case 'Age':
        return toPercentage(value);
      case 'Gender':
        return toPercentage(value);
      case 'Lifestage':
        return toPercentage(value);
      case 'Household Size':
        return toPercentage(value);
      case 'UK Tenure':
        return toPercentage(value);
      case 'Deprivation Dimensions':
        return toPercentage(value);
      case 'Income':
        return toCurrency(value);
      case 'Employment':
        return toPercentage(value);
      case 'Top Interests':
        return toPercentage(value);
      case 'Top Mindsets':
        return toPercentage(value);
      case 'Top Causes':
        return toPercentage(value);
      case 'Home Ownership':
        return toPercentage(value);
      case 'House Price':
        return toBigCurrency(value);
      case 'House Price Growth':
        return toPercentage(value);
      case 'Rental Price':
        return toCurrency(value);
      case 'House Stock':
        return toPopDensity(value);
      case 'House Sales':
        return toPopDensity(value);
      case 'New Builds':
        return toPopDensity(value);
      case 'Electric Vehicles Grants':
        return toBigCurrency(value);
      case 'Walking / Cycling':
        return toPercentage(value);
      case 'Workplace Size':
        return toRoundedIfZero(value);
      case 'Workplace Industry':
        return toRoundedIfZero(value);
      case 'Sustainability':
        return toRounded(value);
      case 'Skills & Qualifications':
        return toDecimalOrPercentage(value);
      case 'Land Value':
        return toBigCurrency(value);
      case 'Land Use':
        return toPercentageDecimal(value);
      case 'Planning Performance':
        return toPercentageDecimal(value);
      case 'Protected Land':
        return toPercentage(value);
      case 'Development':
        return toPercentage(value);
      case 'Community Life':
        return toPercentageDecimal(value);
      case 'Biodiversity':
        return value < 1 ? toPercentage(value) : Number((Math.round(value * 100) / 100).toFixed(2));
      case 'Nearby University':
        return value > 1000 ? toBigCurrency(value) : Number((Math.round(value * 100) / 100).toFixed(2))

      default:
        return Number((Math.round(value * 100) / 100).toFixed(2));
    }
  } else {
    return 'NA'
  }
};

export const formatCatchmentFact = (value: number, category: string) => {
  switch (category) {
    case 'Top Scoring':
      return toLongDecimal(value);
    case 'Population':
      return toDensity(value);
    case 'Age Band':
      return toPercentage(value);
    case 'Gender':
      return toPercentage(value);
    case 'Income Band':
      return toPercentage(value);
    case 'Lifestage':
      return toPercentage(value);
    case 'Household Size':
      return toPercentage(value);
    case 'UK Tenure':
      return toPercentage(value);
    case 'Deprivation Dimensions':
      return toPercentage(value);
    case 'Interests':
      return toDensity(value);
    case 'Mindsets':
      return toDensity(value);
    case 'Causes':
      return toDensity(value);
    case 'Home Ownership':
      return toPercentage(value);
    case 'Price':
      return toBigCurrency(value);
    case '10 Year Growth':
      return toPercentage(value);
    case '5 Year Growth':
      return toPercentage(value);
    case 'Rental Price':
      return toCurrency(value);
    case 'House Stock':
      return toPopDensity(value);
    case 'Sales':
      return toPopDensity(value);
    case 'Sold Properties':
      return toPopDensity(value);
    case 'New Builds':
      return toPopDensity(value);
    case 'Driving':
      return toPopDensity(value);
    case 'Walking':
      return toPopDensity(value);
    case 'Number of Businesses':
      return toLongDecimal(value);
    case 'Workplace Industry':
      return toLongDecimal(value);
    case 'Growth of Crime':
      return toPercentage(value);
    case 'Number of Crimes':
      return toLongDecimal(value);
    case 'Development Score':
      return toRoundedIfZero(value);

    default:
      return Number((Math.round(value * 100) / 100).toFixed(3));
  }
};

export const formatExploreFact = (value: number, category: string) => {
  switch (category) {
    case 'Top Scoring':
      return toLongDecimal(value);
    case 'Population':
      return toDensity(value);
    case 'Age Band':
      return toPercentage(value);
    case 'Gender':
      return toPercentage(value);
    case 'Income Band':
      return toPercentage(value);
    case 'Lifestage':
      return toPercentage(value);
    case 'Household Size':
      return toPercentage(value);
    case 'UK Tenure':
      return toPercentage(value);
    case 'Deprivation Dimensions':
      return toPercentage(value);
    case 'Interests':
      return toDensity(value);
    case 'Mindsets':
      return toDensity(value);
    case 'Causes':
      return toDensity(value);
    case 'Home Ownership':
      return toPercentage(value);
    case 'Price':
      return toBigCurrency(value);
    case 'House Prices':
      return toBigCurrency(value);
    case '10 Year Growth':
      return toPercentage(value);
    case '5 Year Growth':
      return toPercentage(value);
    case 'Rental Price':
      return toCurrency(value);
    case 'House Stock':
      return toPopDensity(value);
    case 'House Stock (per person per km)':
      return toLongDecimal(value, 2);
    case 'Sales':
      return toPopDensity(value);
    case 'Sales (per person per km)':
      return toLongDecimal(value, 2);
    case 'Sold Properties':
      return toPopDensity(value);
    case 'Sold Properties (per person per km)':
      return toLongDecimal(value, 2);
    case 'New Builds':
      return toPopDensity(value);
    case 'New Builds (per person per km)':
      return toLongDecimal(value, 2);
    case 'Driving':
      return (value > 100) ? toRounded(value) : toRoundedIfZero(value);
    case 'Walking':
      return (value > 100) ? toRounded(value) : toRoundedIfZero(value);
    case 'Number of Businesses':
      return toLongDecimal(value);
    case 'Number of Businesses (per person per km)':
      return toLongDecimal(value);
    case 'Workplace Industry':
      return toLongDecimal(value);
    case 'Workplaces by Industry (per person per km)':
      return toLongDecimal(value);
    case 'Growth of Crime':
      return toPercentage(value);
    case 'Number of Crimes':
      return toLongDecimal(value);
    case 'Number of Crimes (per person per km)':
      return toLongDecimal(value);

    default:
      return Number((Math.round(value * 100) / 100).toFixed(3));
  }
};

export const toPercentage = (value: number) => {
  return `${(value * 100).toFixed(1)}%`;
};

export const toPercentile = (value: number) => {
  return `${Math.round(value * 100)}%`;
};

export const toPercentileScore = (value: number) => {
  return `${Math.round(value * 100)}`;
};

export const toPercentageDecimal = (value: number) => {
  return `${value.toFixed(1)}%`;
};

export const toDecimalOrPercentage = (value: number) => {
  if (value < 1) {
    return toPercentage(value);
  } else if (value < 100) {
    return toPercentageDecimal(value);
  } else {
    return toRounded(value);
  }
}

export const toCurrency = (value: number) => {
  return `£${value.toFixed(0)}`;
};

export const toPopDensity = (value: number) => {
  return `${value.toFixed(1)}`;
};

export const toDensity = (value: number) => {
  if (value < 1) {
    return `${value.toFixed(2)}`;
  } else {
    return `${value.toFixed(1)}`;
  }

};

export const toIndex = (value: number) => {
  return `${value.toFixed(2)}`;
};

export const toLongDecimal = (value: number, digits: number = 3) => {
  return value.toFixed(digits);
}

export const toRounded = (value: number) => {
  return Math.round(value);
}

export const toRoundedIfZero = (value: number) => {
  return (Math.round(value) === value) ? Math.round(value) : value.toFixed(1);
}

export const toBigCurrency = (value: number) => {

  if (value === 0) {
    return '£0'
  } else {

    var SI_SYMBOL = ['', 'k', 'm', 'B', 'T', 'P', 'E'];

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(value)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) return value;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = value / scale;

    // format number and add suffix
    return `£${scaled.toFixed(1) + suffix}`;
  }
}

export const toBigUnit = (value: number, unit: string) => {

  var SI_SYMBOL = ['', 'k', 'm', 'B', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  var tier = Math.log10(Math.abs(value)) / 3 | 0;

  // if zero, we don't need a suffix
  if(tier === 0) return value;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = value / scale;

  // format number and add suffix
  return `${scaled.toFixed(1) + suffix}${unit}`;
}

export const addThousandSep = (value: number) => {
  const thousandRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  const value_str = value.toFixed(0);
  let index = value_str.search(/[1-9]/);
  index = index === -1 ? value_str.length : index;
  return (
    value_str.substring(0, index) +
    value_str.substring(index, value_str.length).replace(thousandRegex, '$1,')
  );
};



export const formatScore = (value: number | null, unit?: string) => {
  if (value !== null) {
    if (unit) {
      switch (unit) {
        case '#':
          return toRounded(value);
        case '#NA':
          return value > 0 ? toRounded(value) : 'NA';
        case 'ratio':
          return toDensity(value);
        case '£':
          return toBigCurrency(value);
        case '£/ha':
          return toBigCurrency(value);
        case '£/week':
          return toCurrency(value) ;
        case '£/hour':
          return toCurrency(value);
        case '%':
          return toPercentageDecimal(value);
        case '%100':
          return toPercentage(value);
        case '% change y/y':
          return toPercentile(value);
        case '# per person per km':
          return toDensity(value);
        case '# per 1,000 stock':
          return toDensity(value);
        case 'Yes/No':
          return value > 0 ? 'Yes' : 'No';
        case 'Pos/Neg':
          return value > 0 ? 'Positive' : value < 0 ? 'Negative' : 'Neutral';
        case 'kWh':
          return toBigUnit(value, '');
        case 'tC02e':
          return toBigUnit(value, '');
        case 'pct':
          return toPercentile(value);
        case 'pct_score':
          return toPercentileScore(value);
        case 'index':
          return toIndex(value);

        default:
          return toRounded(value);
      }
    } else {
      return toRounded(value);
    }
  } else if (unit === 'Yes/No') {
    return 'NA';
  } else if (unit === '#') {
    return 0;
  } else {
    return 'NA';
  }
}

export const getOrdinal = (n: number) => {
  let ord = 'th';

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  }
  else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  }
  else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }

  return ord;
}

export const formatPercentile = (value: number | null) => {
  if (value === null) {
    return 'NA'
  } else {
    return Math.round(value * 100);
    // const pct = Math.round(value * 100);
    // const ord = getOrdinal(pct);
    // return `${pct}${ord} percentile`
  }
}

export function toColor(score: number | null){

  if (score === null) {
    return '#F4F4F4'
  } else {
    if (score < 0.05) {
      return '#F3546D'
    } else if (score < 0.10) {
      return '#F56D6E'
    } else if (score < 0.15) {
      return '#F6806F'
    } else if (score < 0.20) {
      return '#F78670'
    } else if (score < 0.25) {
      return '#F89571'
    } else if (score < 0.30) {
      return '#F9A071'
    } else if (score < 0.35) {
      return '#FAAE72'
    } else if (score < 0.40) {
      return '#FBBD6F'
    } else if (score < 0.45) {
      return '#FAC56D'
    } else if (score < 0.50) {
      return '#FAD16A'
    } else if (score < 0.55) {
      return '#FBD769'
    } else if (score < 0.60) {
      return '#F4E366'
    } else if (score < 0.65) {
      return '#E4DE66'
    } else if (score < 0.70) {
      return '#C7D666'
    } else if (score < 0.75) {
      return '#B2D067'
    } else if (score < 0.80) {
      return '#8CC567'
    } else if (score < 0.85) {
      return '#72BE68'
    } else if (score < 0.90) {
      return '#64BA68'
    } else if (score < 0.95) {
      return '#45B168'
    } else {
      return '#3caf69'
    }
  }
}
