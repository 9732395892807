import React from 'react';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from "@mui/material";

import { Tooltip } from '@/components/atoms';

type ButtonIconProps = {
  onClick: () => void;
  color: 'primary' | 'secondary' | 'info';
  size: string | number;
  Icon: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  tooltip?: string;
};

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  onClick,
  color,
  size,
  Icon,
  disabled,
  tooltip
  }) => {

  const theme = useTheme();

  return (
    <Tooltip title={tooltip} placement='bottom'>
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={onClick}
        sx={{
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          '&:hover': {
            backgroundColor: theme.palette[color].dark
          },
          borderRadius: '4px',
          boxShadow: theme.shadows[2],
          height: size,
          width: size,
        }}
      >
        <Icon fontSize='small'/>
      </IconButton>
    </Tooltip>
  )

}
