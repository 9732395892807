import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import './styles/styles.scss'

import { AuthContainer, ThemeContainer } from '@/containers';

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthContainer>
      <ThemeContainer>
        <div className='app-container'>
          <CssBaseline />
          <App />
        </div>
      </ThemeContainer>
    </AuthContainer>
  </BrowserRouter>,
  //</React.StrictMode>,
  document.getElementById("root")
);
