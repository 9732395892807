import React, { useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


type SelectedChipProps = {
  selected?: boolean;
  color: "info" | "primary" | "secondary" | "error" | "success";
}

const SelectedChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<SelectedChipProps>(({theme, selected, color}) => ({
  backgroundColor: selected ? theme.palette[color].dark : theme.palette[color].main
}));


type ChipListProps = {
  data: Array<any>;
  createLabel: (variable: any) => string;
  color: "info" | "primary" | "secondary" | "error" | "success";
};


export const ExpandingChipList: React.FC<ChipListProps> = ({data, createLabel, color}) => {

  const [open, setOpen] = useState(true);
  const theme = useTheme();

  const onExpand = () => {
    setOpen(!open);
  };

  return (
    <Box >
      <SelectedChip
        color={color}
        size='small'
        label={`${data.length} variables selected`}
        onClick={onExpand}
        onDelete={onExpand}
        deleteIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        selected={open}
        sx={{ borderRadius: '4px', mb: 1, boxShadow: theme.shadows[1]}}
      />
      <Collapse in={open}>
        {data.map((value, idx) => (
          <ListItem key={`selected-chip-${idx}`}>
            <Chip
              color={value.feature_id < 0 ? 'error' : color}
              size='small'
              label={createLabel(value)}
              sx={{ borderRadius: '4px'}}
            />
          </ListItem>
        ))}
      </Collapse>
    </Box>
  );

}
