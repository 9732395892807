import React from 'react';

import Card  from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { SimpleVariable } from '@/interfaces';

type SimpleVariableCardProps = {
  title: string;
  variables: Array<SimpleVariable>;
  selectedVariables: Array<SimpleVariable>;
  onSelect: (variable: SimpleVariable) => void;
};

export const SimpleVariableCard: React.FC<SimpleVariableCardProps> = (
  {
    title,
    variables,
    selectedVariables,
    onSelect
  }
) => {

  const selectedIds = selectedVariables.map(v => v.id);

  return (
    <Card elevation={4}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }}/>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        pt: 0,
        pb: 1,
        '&:last-child': {
          pb: 1
        }
      }}>
        {variables.map((variable, variableIdx) => {
          const selected = selectedIds.includes(variable.id);
          return (
            <Chip
              key={`variable-chip-${variableIdx}`}
              label={variable.name}
              size='small'
              disabled={selected}
              clickable={!selected}
              color={selected ? 'primary' : 'default'}
              onClick={() => onSelect(variable)}
              sx={{borderRadius: '4px', mr: 1, mb: 1}}

            />
          );
        })}
      </CardContent>
    </Card>
  );

}
