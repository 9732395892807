import React from 'react';

import Card  from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { ExploreVariable } from '@/interfaces';

type ExploreVariableCardProps = {
  title: string;
  variables: Array<ExploreVariable>;
  selection: Array<number>;
  onSelect: (variable: ExploreVariable) => void;
};

export const ExploreVariableCard: React.FC<ExploreVariableCardProps> = (
  {
    title,
    variables,
    selection,
    onSelect
  }
) => {

  return (
    <Card elevation={4}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }}/>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        pt: 0,
        pb: 1,
        '&:last-child': {
          pb: 1
        }
      }}>
        {variables.map((variable, variableIdx) => {
          const selected = selection.includes(variable.feature_id);
          return (
            <Chip
              key={`variable-chip-${variableIdx}`}
              label={variable.type}
              size='small'
              disabled={selected}
              clickable={!selected}
              onClick={() => onSelect(variable)}
              color={selected ? 'primary' : 'default'}
              sx={{borderRadius: '4px', mr: 1, mb: 1}}
            />
          );
        })}
      </CardContent>
    </Card>
  );

}
