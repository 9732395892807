import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';


import { ExploreVariableCard } from '@/components/molecules';
import { ExploreVariable } from "@/interfaces";
import { getIconFromName } from "@/utils/icons";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    key: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function createGroups(variables: Array<ExploreVariable>): Array<string> {
  const groupNames = variables.map((variable) => {
    return getGroupName(variable)
  })
  return Array.from(new Set(groupNames));
}

function getGroupName(variable: ExploreVariable): string {
  if (variable.group === 'Proximity') {
    return `${variable.group} - ${variable.category}`
  } else if (variable.group === 'Lifestyle') {
    return variable.category
  } else {
    return variable.group
  }
}

function getGroupData(data: Array<ExploreVariable>, group: string): Array<ExploreVariable> {
  if (group.includes('Proximity')){
    return data.filter(x => x.group === group.split(' - ')[0]).filter(x => x.category === group.split(' - ')[1])
  } else if (['Interests', 'Causes', 'Mindsets'].includes(group)) {
    return data.filter(x => x.category === group)
  } else {
    return data.filter(x => x.group === group)
  }
}

type ExploreFilterPanelProps = {
  selection: Array<ExploreVariable>;
  variables: Array<ExploreVariable>;
  selectVariable: (variable: ExploreVariable) => void;
  tableComponent?: React.ReactNode;
};

export const ExploreFilterPanel: React.FC<ExploreFilterPanelProps>  = (
  {
    selection,
    variables,
    selectVariable,
    tableComponent
  }
) => {

  const [tabId, setTabId] = useState<number>(0);
  const [groupData, setGroupData] = useState<Array<ExploreVariable>>([]);

  const groups: Array<string> = createGroups(variables);
  const categories = Array.from(new Set(groupData.map(v => v.source ? v.source : v.category)));

  const selectedIds = selection.map(v => v.feature_id);

  useEffect(() => {
    const selectedGroup = groups[tabId];
    selectedGroup ? setGroupData(getGroupData(variables, selectedGroup)) : setGroupData([]);
  }, [variables, tabId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
  };



  return (
    <Grid container spacing={2}  sx={{ height: '100%' }}>
      <Grid item xs={4}>
        <Tabs
          orientation="vertical"
          value={tabId}
          onChange={handleTabChange}
        >
          {groups.map( (group, groupIdx) => {
            const groupCount = selection.filter(x => getGroupName(x) === group).length;
            return(
              <Tab
                icon={getIconFromName(group, { fontSize: 'small' })}
                iconPosition="start"
                label={
                  groupCount > 0 ? `${group} [${groupCount}]` : group
                }
                {...a11yProps(groupIdx)}
                sx={{ minHeight: '42px', height: '42px', justifyContent: 'flex-start', alignItems: 'flex-end' }}
              />
            );
          })}
          <Tab
            icon={<DriveFolderUploadIcon fontSize='small' />}
            iconPosition="start"
            label='Load'
            {...a11yProps(groups.length)}
            sx={{ minHeight: '42px', height: '42px', justifyContent: 'flex-start', alignItems: 'flex-end' }}
          />
        </Tabs>
      </Grid>
      <Grid item container xs={8} spacing={2}>
        {tabId === groups.length ?
          <Grid item xs={12}>
            {tableComponent}
          </Grid>
          :
          <Stack spacing={2} mt={2} ml={2} sx={{ width: '100%' }}>
            {categories.map((category, categoryIdx) => {
              const categoryData = groupData.filter(x => x.source ? x.source === category : x.category === category);
              return (
                <ExploreVariableCard
                  key={`category-${categoryIdx}`}
                  title={category}
                  variables={categoryData}
                  selection={selectedIds}
                  onSelect={selectVariable}
                />
              )
            })}
          </Stack>
        }
      </Grid>
    </Grid>
  );
}
