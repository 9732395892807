import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

import { Sector } from "@/interfaces";


function toColor(value: number) {
  if (value >= 160) {
    return '#00656d'
  } else if (value >= 130) {
    return '#008899'
  } else if (value >= 120) {
    return '#00B1CC'
  } else if (value >= 110) {
    return '#5ACADB'
  } else {
    return '#BEE5EB'
  }
}


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#legend"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type MapLegendProps = {
  scores: Array<Sector>;
};

export const ExploreFilterMapLegend: React.FC<MapLegendProps> = ({ scores }) => {

  const theme = useTheme();

  const maxScore = scores.length > 0 ? Math.min(Math.round(Math.max.apply(Math, scores.map((x) => x.score))), 160) : 160;
  const minScore = scores.length > 0 ? Math.round(Math.min.apply(Math, scores.map((x) => x.score))) : 100;
  const midScore = Math.round(minScore + ((maxScore - minScore) / 2));

  return (
    <DraggablePaper
      id='legend'
      elevation={0}
      sx={{
        position: "fixed",
        bottom: 40,
        left: 16,
        width: '150px',
        background: 'transparent'
      }}
    >
      <Box>
        <Typography variant='caption' color={theme.palette.primary.contrastText} gutterBottom>
          Score
        </Typography>
      </Box>
      <Box sx={{
        width: '150px',
        height: '10px',
        borderRadius: '10px',
        backgroundImage: `linear-gradient(to right, ${toColor(minScore)} 0%, ${toColor(minScore + ((midScore - minScore) / 2))} 25%, ${toColor(midScore + ((maxScore - midScore) / 2))} 75%, ${toColor(maxScore)} 100%)`
        // backgroundImage: 'linear-gradient(to right, #BEE5EB 0%, #5ACADB 20%, #00B1CC 40%, #008899 60%, #00656d 75%)'
      }}/>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='caption' color={theme.palette.primary.contrastText}>{minScore}</Typography>
        <Typography variant='caption' color={theme.palette.primary.contrastText}>{midScore}</Typography>
        {/*<Typography variant='caption' color={theme.palette.primary.contrastText}>140</Typography>*/}
        <Typography variant='caption' color={theme.palette.primary.contrastText}>{maxScore}</Typography>
      </Box>
    </DraggablePaper>
  );
}
