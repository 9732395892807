import React, { useState } from "react";

import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { useTheme } from "@mui/material/styles";

import { ExploreFact } from "@/interfaces";
import Button from "@mui/material/Button";

type FactRow = {
  category: string;
  type: string
  index: number;
};

function createRow(fact: ExploreFact): FactRow {
  return {
    category: `${fact.type.replace(/^[0-9]+-? /m, '').split(' - ')[0]}`,
    type: fact.type.includes(' - ')
      ? `${fact.type.replace(/^[0-9]+-? /m, '').split(' - ')[1]}`
      : '',
    index: Math.round(Number(fact.value_avg))
  };
}

type FactScoreCardProps = {
  title: string;
  scores: Array<ExploreFact>;
  startExpanded: boolean;
};

export const ExploreFactScoreCard: React.FC<FactScoreCardProps> = (
  {
    title,
    scores,
    startExpanded
  }
) => {

  const theme = useTheme();
  const [expand, setExpand] = useState(startExpanded);
  const data = scores.slice(0, 20);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      {expand &&
        <CardContent sx={{ pb: 0 }}>
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 0.5,
                  width: '40%',
                  color: theme.palette.text.secondary
                }}>{data[0].type.includes(' - ') ? 'Category' : 'Name'}</TableCell>
                <TableCell sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 0.5,
                  width: '40%',
                  color: theme.palette.text.secondary
                }}>{data[0].type.includes(' - ') ? 'Name' : ''}</TableCell>
                <TableCell align='center' sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  pb: 0.5,
                  width: '10%',
                  color: theme.palette.text.secondary
                }}>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((variable, variableIdx) => {
                const row = createRow(variable);
                return (
                  <TableRow key={`fact-row-${variableIdx}`}>
                    <TableCell align='left' sx={{ fontSize: '12px', py: 0.5 }}>{row.category}</TableCell>
                    <TableCell align='left' sx={{ fontSize: '12px', py: 0.5 }}>{row.type}</TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      py: 0.5,
                      color: row.index > 100 ? theme.palette.success.light : theme.palette.error.main
                    }}>{row.index}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.length === 20 &&
            <Typography variant='caption' mt={4} sx={{ fontSize: '9px' }}>*Only showing top 20 scores</Typography>
          }
        </CardContent>
      }
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button size='small' color='primary' onClick={onExpand}>
          {expand ? 'Hide Scores' : 'Show Scores'}
        </Button>
      </CardActions>
    </Card>

  );
}
