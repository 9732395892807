import React from 'react';

import { ExploreFactValueCard } from '@/components/molecules';
import fixedVariables from "@/components/molecules/facts/fixedVariables.json";
import { ExploreFact } from "@/interfaces";

type Scores = {
  [key: string]: any;
}

type FactOtherProps = {
  data: Scores;
  level: string;
};

export const FactOther:  React.FC<FactOtherProps> = ({
  data, level
}) => {

  // Combine data into one object
  const newData = Object.values(data).reduce((res, cur) => Object.assign(res, cur), {});

  // Reorder some keys
  Object.keys(newData).forEach((source) => {
    const fixes = fixedVariables.filter(x => x.source === source);
    if (fixes.length > 0) {
      let sortData: Array<ExploreFact> = [];
      fixes[0].keys.forEach(x => sortData.push(newData[source].filter((y: ExploreFact) => y.type === x)[0]));
      newData[source] = sortData;
    }
  })

  // Fix some keys
  newData['Number of Crimes'] &&
    delete Object.assign(newData, {'Number of Crimes (per person per km)': newData['Number of Crimes'] })['Number of Crimes'];
  newData['Number of Businesses'] &&
    delete Object.assign(newData, {'Number of Businesses (per person per km)': newData['Number of Businesses'] })['Number of Businesses'];
  newData['Workplace Industry'] &&
    delete Object.assign(newData, {'Workplaces by Industry (per person per km)': newData['Workplace Industry'] })['Workplace Industry'];

  return (
    <>
      <ExploreFactValueCard title='Other' scores={newData} category={null} />
    </>
  );
};
