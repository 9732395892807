import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { useTheme, lighten } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { Location, ScoreCardCategory, ScoreCardGroup, ScoreCardResponse } from "@/interfaces";
import { getLads, getScorecard } from "@/services/ApiService";
import { RadarChart, LocationSelectBar, UnderlineHeader, UnderlineText, ScorecardScore } from "@/components/atoms";
import { ScorecardCard, ScorecardGroupCard } from "@/components/molecules";

type ScorecardOverlayProps = {
}

export const ScorecardOverlay: React.FC<ScorecardOverlayProps> = () => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [firstLocation, setFirstLocation] = useState<Location | null>(null);
  const [secondLocation, setSecondLocation] = useState<Location | null>(null);
  const [firstData, setFirstData] = useState<ScoreCardResponse | undefined>();
  const [secondData, setSecondData] = useState<ScoreCardResponse | undefined>();

  const [selectedGroup, setSelectedGroup] = useState<string>('Hard');

  const flattenScores = (scores: Array<ScoreCardGroup>) => {
    return scores.filter(x => x.name !== 'Opportunity').map(x => x.scores.map(y => ({group_name: x.name, ...y}))).flat();
  }

  const getGroupScores = (scores: Array<ScoreCardGroup>): Array<ScoreCardCategory> => {
    return scores.filter(x => x.name === selectedGroup)[0].scores || [];
  }

  const getGroupNameScores = (scores: Array<ScoreCardGroup>, name: string): Array<ScoreCardCategory> => {
    return scores.filter(x => x.name === name)[0].scores || [];
  }

  const getGroupScore = (scores: Array<ScoreCardGroup>, name: string): ScoreCardGroup | undefined => {
    return scores.filter(x => x.name === name)[0] || undefined
  }

  const getCategoryScores = (scores: Array<ScoreCardGroup>, category: string): ScoreCardCategory | undefined => {
    const groupScores = scores.filter(x => x.name === selectedGroup)
    if (groupScores.length > 0) {
      return groupScores[0].scores.filter(x => x.name === category)[0];
   } else {
      return undefined
    }
  }

  const getCategoryNameScores = (scores: Array<ScoreCardGroup>, group: string, category: string): ScoreCardCategory | undefined => {
    const groupScores = scores.filter(x => x.name === group)
    if (groupScores.length > 0) {
      return groupScores[0].scores.filter(x => x.name === category)[0];
    } else {
      return undefined
    }
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getLads(accessToken);
      setLocations(response);
    }
    fetchLocations().then();
  }, []);

  useEffect(() => {
    const fetchData = async (location: Location) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getScorecard(location.geo_id, accessToken);
      response && setFirstData(response);
    };
    firstLocation && fetchData(firstLocation).then(() => setLoading(false));
    setSecondLocation(null);
    setSecondData(undefined);

  }, [firstLocation]);

  useEffect(() => {
    const fetchData = async (location: Location) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently()
      const response = await getScorecard(location.geo_id, accessToken);
      response && setSecondData(response);
    };
    secondLocation && fetchData(secondLocation).then(() => setLoading(false));
  }, [secondLocation]);

  const onSelectFirst = (location: Location) => {
    setSecondLocation(null);
    setSecondData(undefined);
    setFirstLocation(location);
  }

  const onSelectSecond = (location: Location) => {
    setSecondLocation(location);
  }

  const selectGroup = (group: string) => {
    setSelectedGroup(group);
  }

  return (
    <Box width="100%" py={4} px="5%" sx={{ backgroundColor: theme.palette.background.paper, overflow: 'auto'}}>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h5">Location Scorecard</Typography>
        </Grid>
        <Grid item xs={12} mb={4}>
          <>
            <Stack direction="row" spacing={6}>
              <Box>
                <Typography variant="body2" gutterBottom>Select a location to see it's scores</Typography>
                <LocationSelectBar options={locations} selectedLocation={firstLocation} onSelect={onSelectFirst} color="info"/>
              </Box>
              {firstLocation && firstData && (
                <Box>
                  <Typography variant="body2" gutterBottom>(Optional) Select a location to compare against</Typography>
                  <LocationSelectBar options={locations} selectedLocation={secondLocation} onSelect={onSelectSecond} color="info"/>
                </Box>
              )}
              {/*{loading && <CircularProgress />}*/}
            </Stack>
          </>
        </Grid>
        {firstLocation && firstData && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} mb={1}>
              <Box display="flex" flexDirection="row">
                <UnderlineHeader text={firstLocation.geo_name} color={theme.palette.primary.main} />
                {secondLocation &&
                  <>
                    <Typography variant="h5" sx={{ mx: 1 }}>vs</Typography>
                    <UnderlineHeader text={secondLocation.geo_name} color={theme.palette.secondary.main} />
                  </>
                }
              </Box>
            </Grid>
            {/*<Grid item xs={12} lg={6} xl={4} display="flex" justifyContent="center" sx={{ pb: 3 }}>*/}
              {/*  <RadarChart*/}
              {/*    values={secondData ?*/}
              {/*      [flattenScores(firstData.scores).map(x => x.percentile_wt), flattenScores(secondData.scores).map(x => x.percentile_wt)]*/}
              {/*      : [flattenScores(firstData.scores).map(x => x.percentile_wt)]}*/}
              {/*    labels={flattenScores(firstData.scores).map(x => x.name)}*/}
              {/*    // labelColors={categoryScoreGroups.map(x => x === selectedGroup ? '#4ed4eb' : '#fff')}*/}
              {/*    // labelColors={categoryScoreGroups.map(x => toGroupColor(x))}*/}
              {/*  />*/}
            {/*</Grid>*/}
            {['Group Strategic Priorities', 'Strategic Sectors', 'Hard', 'Soft'].map((group) => (
              <Grid item>
                <ScorecardGroupCard
                  group={group}
                  locations={[firstLocation, secondLocation]}
                  firstData={getGroupNameScores(firstData.scores, group)}
                  secondData={secondData ? getGroupNameScores(secondData.scores, group) : undefined}
                  groupScores={[getGroupScore(firstData.scores, group), secondData && getGroupScore(secondData.scores, group)]}
                />
              </Grid>
              ))}
            {/*<Grid item xs={3}>*/}
            {/*  <Card elevation={0} sx={{ minWidth: "450px", maxWidth: "600px" }}>*/}
            {/*    <CardHeader*/}
            {/*      sx={{ p: 2, pb: 0 }}*/}
            {/*      disableTypography*/}
            {/*      title={*/}
            {/*      <Stack direction="row" justifyContent="space-between">*/}
            {/*        {firstData && firstData.scores.map((group, groupIdx) => (*/}
            {/*          <Box*/}
            {/*            key={`box-${groupIdx}`}*/}
            {/*            onClick={() => selectGroup(group.name)}*/}
            {/*            sx={{*/}
            {/*              cursor: 'pointer',*/}
            {/*              backgroundColor: selectedGroup === group.name ? lighten(theme.palette.background.default, 0.1) : null,*/}
            {/*              '&:hover': {*/}
            {/*                backgroundColor: theme.palette.background.default,*/}
            {/*                boxShadow: theme.shadows[2],*/}
            {/*              },*/}
            {/*              borderRadius: '4px',*/}
            {/*              p: 1*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <ScorecardScore name={group.name} score={group.percentile_wt} color={toPercentileColor(group.percentile_wt)}/>*/}
            {/*          </Box>*/}
            {/*        ))}*/}
            {/*      </Stack>*/}
            {/*      }*/}
            {/*    />*/}
            {/*  <CardContent>*/}
            {/*    <>*/}
            {/*      <Grid container spacing={2}>*/}
            {/*        {secondLocation &&*/}
            {/*          <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-end">*/}
            {/*            <Stack direction="row" spacing={2}>*/}
            {/*              <UnderlineText*/}
            {/*                text={firstLocation.geo_name}*/}
            {/*                color={theme.palette.primary.main}*/}
            {/*                variant="overline"*/}
            {/*                textProps={{noWrap: true, align: "center", sx: {width: '65px'} }}/>*/}
            {/*              {selectedGroup !== 'Opportunity' &&*/}
            {/*                <UnderlineText*/}
            {/*                  text={secondLocation.geo_name}*/}
            {/*                  color={theme.palette.secondary.main}*/}
            {/*                  variant="overline"*/}
            {/*                  textProps={{ noWrap: true, align: "center", sx: { width: '65px' } }} />*/}
            {/*              }*/}
            {/*            </Stack>*/}
            {/*          </Grid>*/}
            {/*        }*/}
            {/*        {firstData && getGroupScores(firstData.scores).map((category, categoryIdx) => (*/}
            {/*          <Grid item xs={12} key={`score-card-${categoryIdx}`}>*/}
            {/*            <ScorecardCard*/}
            {/*              key={`score-card-${categoryIdx}`}*/}
            {/*              category={category}*/}
            {/*              compare={secondData ? getCategoryScores(secondData.scores, category.name) : undefined}*/}
            {/*            />*/}
            {/*          </Grid>*/}
            {/*        ))}*/}
            {/*      </Grid>*/}
            {/*    </>*/}
            {/*  </CardContent>*/}
            {/*  </Card>*/}
            {/*</Grid>*/}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
