import React from 'react';

import Grid from '@mui/material/Grid';

import { SimpleScorecardCard } from '@/components/molecules';
import { SimpleScoreCard, SimpleScoreCardGroup } from "@/interfaces";
import { RadarChart } from "@/components/atoms";


type SimpleScorecardPanelProps = {
  scorecard: SimpleScoreCard;
};

export const SimpleScorecardPanel: React.FC<SimpleScorecardPanelProps>  = (
  {
    scorecard
  }) => {

  const createCategoryFromGroup = (scores: SimpleScoreCardGroup) => {
    return {
      id: scores.id,
      name: scores.name,
      value: scores.value,
      percentile: scores.percentile,
      percentile_wt: scores.percentile_wt,
      index: scores.index,
      scores: scores.scores.map((category) => ({
        feature_id: 0,
        feature_name: category.name,
        value: category.percentile,
        index: category.index,
        base: 0,
        percentile: category.percentile,
        percentile_wt: category.percentile_wt,
        description: '',
        unit: 'pct'
      }))
    }
  }

  const flattenScores = (scores: Array<SimpleScoreCardGroup>) => {
    return scores.map(x => x.scores.map(y => ({group_name: x.name, ...y}))).flat();
  }

  // const groupScore = scorecard.scores[0].scores.filter(x => x.index !== null).reduce((total, next) => total + next.index, 0) / scores.filter(x => x.index !== null).length;
  // console.log(toGroupScores(scorecard.scores.slice(0, 5)));

  return (
    <Grid container spacing={2} justifyContent="space-between" sx={{height: '100%', px: 2}}>
      <Grid item container xs={12} sm={6} md={3} alignContent="flex-start">
        {scorecard.scores[0].scores.map((category, categoryIdx) => (
          <Grid key={`0-${categoryIdx}`} xs={12} mb={2}>
            <SimpleScorecardCard key={`score-card-${categoryIdx}`} category={category} expand={true} border="right"/>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid item xs={12} display="flex" alignItems="flex-start" justifyContent="center">
          <RadarChart
            values={[flattenScores(scorecard.scores).map(x => x.percentile)]}
            labels={flattenScores(scorecard.scores).map(x => x.name)}
            height={300}
            width={350}
          />
        </Grid>
        <Grid item md={12} lg={6} display="flex" flexDirection="column" alignItems="center">
          <SimpleScorecardCard category={createCategoryFromGroup(scorecard.scores[0])}  expand={true} border="right"/>
        </Grid>
        <Grid item md={12} lg={6} display="flex" flexDirection="column" alignItems="center">
          <SimpleScorecardCard category={createCategoryFromGroup(scorecard.scores[1])}  expand={true} border="left"/>
        </Grid>
        <Grid item md={12} display="flex" flexDirection="column" alignItems="center">
          <SimpleScorecardCard category={createCategoryFromGroup(scorecard.scores[2])}  expand={true} border="top"/>
        </Grid>
      </Grid>
      {/*<Grid item xs={3}>*/}
      {/*  /!*<Grid xs={12} mb={2}>*!/*/}
      {/*  /!*  <SimpleScorecardCard name={''} scores={toGroupScores(scorecard.scores.slice(0, 5))} expand={true}/>*!/*/}
      {/*  /!*</Grid>*!/*/}
      {/*</Grid>*/}
      {/*<Grid item xs={3}>*/}
      {/*  /!*<Grid xs={12} mb={2} display="flex" justifyContent="flex-end">*!/*/}
      {/*  /!*  <SimpleScorecardCard name={''} scores={toGroupScores(scorecard.scores.slice(6, 12))} expand={true}/>*!/*/}
      {/*  /!*</Grid>*!/*/}
      {/*</Grid>*/}
      <Grid item container xs={12} sm={6} md={3} alignContent="flex-start">
        {scorecard.scores[1].scores.map((category, categoryIdx) => (
          <Grid key={`1-${categoryIdx}`} item xs={12} mb={2} display="flex" justifyContent="flex-end">
            <SimpleScorecardCard key={`score-card-${categoryIdx}`} category={category}  expand={true} border="left"/>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} md={12} justifyContent="center">
        {scorecard.scores[2].scores.map((category, categoryIdx) => (
          <Grid key={`2-${categoryIdx}`} item mx={2}>
            <SimpleScorecardCard key={`score-card-${categoryIdx}`} category={category}  expand={true} border="top"/>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
