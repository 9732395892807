import React from 'react';

import Card  from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { ExploreVariable } from '@/interfaces';

type ExploreFactorCardProps = {
  title: string;
  variables: Array<ExploreVariable>;
};

export const ExploreFactorCardNew: React.FC<ExploreFactorCardProps> = (
  {
    title,
    variables,
  }
) => {

  return (
    <Card elevation={4}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }}/>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        pt: 0,
        pb: 1,
        '&:last-child': {
          pb: 1
        }
      }}>
        {variables.map((variable, variableIdx) => {
          return (
            <Chip
              key={`variable-chip-${variableIdx}`}
              label={
                ['Proximity', 'Demographics', 'Lifestyle'].includes(variable.group) ?
                  variable.category + ' | ' + variable.type
                  : variable.source + ' | ' + variable.type
              }
              size='small'
              disabled={true}
              clickable={false}
              color='primary'
              sx={{borderRadius: '4px', mr: 1, mb: 1}}
            />
          );
        })}
      </CardContent>
    </Card>
  );

}
