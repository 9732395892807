import React from 'react';

import { PageLayout } from '@/layouts/PageLayout';
import { CatchmentMap } from '@/components/molecules';


export const Catchment: React.FC = () => {

  return (
    <PageLayout title='Catchment'>
      <div className='map-area'>
        <CatchmentMap/>
      </div>
    </PageLayout>
  )
}
