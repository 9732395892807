import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from "@mui/material/Chip";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ExploreFilterPanel, ExploreFilterLoadTable, SaveDialog } from "@/components/molecules";
import { ExploreFilter, ExploreVariable } from "@/interfaces";


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-filter-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreFilterDialogProps = {
  open: boolean;
  variables: Array<ExploreVariable>;
  filters: Array<ExploreFilter>;
  filterSelection: Array<ExploreVariable>;
  filterOperator: boolean;
  onCancel: () => void;
  onApply: (selection: Array<ExploreVariable>, operator: boolean) => void;
  updateFilters: (response: Array<any>) => void;
};

export const ExploreFilterDialogNew: React.FC<ExploreFilterDialogProps> = (
  {
    open,
    variables,
    filters,
    filterSelection,
    filterOperator,
    onCancel,
    onApply,
    updateFilters
  }) => {

  const [selection, setSelection] = useState<Array<ExploreVariable>>([]);
  const [operator, setOperator] = useState<boolean>(true);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelection(filterSelection);
  }, [filterSelection]);

  useEffect(() => {
    setOperator(filterOperator);
  }, [filterOperator])

  const onSelectVariable = (variable: ExploreVariable) => {
    const update = [...selection, variable]
    setSelection(update);
  };

  const onDeleteVariable = (value: number) => () => {
    const newSelection = selection.filter((v) => v.feature_id !== value);
    setSelection(newSelection);
  };

  const onLoadFilter = (row: ExploreFilter) => {
    const values = row.filter.split('|').map((x: string) => Number(x));
    const newSelection = variables.filter(x => values.includes(x.feature_id));
    const newOperator = row.operator === 'AND';
    onClear();
    setSelection(newSelection);
    setOperator(newOperator);
  }

  const changeOption = (id: number) => {
    const update = [...selection];
    update.forEach(v => {
      if (v.feature_id === id) {
        v.feature_id = v.feature_id * -1;
      }
    });
    setSelection(update);
  };

  const onClear = () => {
    setSelection([]);
    setOperator(true);
  };

  const onSwitchOperator = () => {
    setOperator(!operator);
  };

  const onClose = () => {
    onCancel();
    setSelection(filterSelection);
    setOperator(filterOperator);
  };

  const buildResult = (name: string) => {
    return {
      name,
      filter: selection.map(x => x.feature_id).join('|'),
      operator: operator ? 'AND' : 'OR'
    }
  };

  const onSaveOpen = () => { setSaveOpen(true); };
  const onSaveClose = () => { setSaveOpen(false); };

  return (
    <>
      <Dialog
        aria-labelledby="explore-filter-dialog"
        open={open}
        onClose={onCancel}
        fullWidth
        maxWidth='md'
        PaperComponent={DraggablePaper}
        PaperProps={{
          elevation: 2,
          sx: {
            position: "fixed",
            top: 85,
            left: -16,
            height: '80%',
            overflow: 'hidden',
            borderRadius: '6px',
          }
        }}
      >
        <DialogTitle
          id="explore-filter-dialog"
        >
          Define a Filter
        </DialogTitle>
        <DialogContent sx={{ height: '100%'}}>
          <ExploreFilterPanel
            selection={selection}
            variables={variables}
            selectVariable={onSelectVariable}
            tableComponent={<ExploreFilterLoadTable rows={filters} onLoad={onLoadFilter} updateFilters={updateFilters}/>}
          />
        </DialogContent>
        <Divider variant="middle" />
        <DialogContent sx={{ overflow: 'hidden', minHeight: '176px', overflowY: 'auto'}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='body2' mb={1}>Selected Variables</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size='small'
                  checked={operator}
                  onChange={onSwitchOperator}
                />
              }
              label={<Typography variant="overline">{operator ? 'AND' : 'OR'}</Typography>}
            />
          </FormGroup>
          </Box>
          <Box sx={{display: 'block', position: 'relative'}}>
            <Paper
              elevation={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                p: 1,
                boxShadow: 'none',
                minHeight: '40px'
              }}
            >
              {selection.map((variable, variableIdx) => (
                <Chip
                  key={`selection-${variableIdx}`}
                  label={
                    ['Proximity', 'Demographics', 'Lifestyle'].includes(variable.group) ?
                      variable.category + ' | ' + variable.type
                      : variable.source + ' | ' + variable.type
                  }
                  color={variable.feature_id > 0 ? 'primary' : 'error' }
                  size='small'
                  clickable={true}
                  onDelete={onDeleteVariable(variable.feature_id)}
                  onClick={() => changeOption(variable.feature_id)}
                  icon={
                    variable.feature_id > 0 ?
                      <CheckRoundedIcon />
                      :
                      <CloseRoundedIcon />
                  }
                  sx={{
                    m: 1, borderRadius: '6px'
                  }}
                />
              ))}
              <IconButton onClick={onClear} sx={{position: 'absolute', right: 0, top: 0}}>
                <CloseIcon />
              </IconButton>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={selection.length === 0}
            onClick={onSaveOpen}
          >
            Save
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={selection.length === 0}
            onClick={() => onApply(selection, operator)}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <SaveDialog
        open={saveOpen}
        mode='filter'
        names={filters.map(filter => filter.name)}
        onCancel={onSaveClose}
        onSave={updateFilters}
        buildResult={buildResult}
      />
    </>
  );
}
