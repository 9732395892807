import React, {useState, useEffect} from 'react';
import Draggable from 'react-draggable';
import { cloneDeep } from 'lodash';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import { ExploreFactorPanel } from '@/components/molecules';
import { ExploreFactor, ExploreVariable } from "@/interfaces";


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-factor-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreFactorDialogProps = {
  open: boolean;
  selection: ExploreFactor | undefined;
  variables: Array<ExploreVariable>;
  factors: Array<ExploreFactor>;
  onCancel: () => void;
  onApply: (factor: ExploreFactor, buckets: Array<string>) => void;
};


export const ExploreFactorDialogNew: React.FC<ExploreFactorDialogProps> = (
  {
    open,
    selection,
    variables,
    factors,
    onCancel,
    onApply
  }) => {

  const [selectedFactor, setSelectedFactor] = useState<ExploreFactor>(selection ? cloneDeep(selection) : factors[0]);
  const [buckets, setBuckets] = useState<Array<boolean>>([true, true, true, true]);

  const bucketNames = ['Other', 'Low', 'Medium', 'High']

  useEffect(() => {
    selection && setSelectedFactor(cloneDeep(selection))
  }, [selection]);

  const handleBucketChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const current = [...buckets];
    current[index] = event.target.checked;
    setBuckets(current);
  };

  const onSelect = (factor: ExploreFactor) => {
    setSelectedFactor(factor);
  };

  const onClose = () => {
    onCancel();
    selection && setSelectedFactor(selection);
  };

  return (
    <Dialog
      aria-labelledby="explore-factor-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          position: "fixed",
          top: 85,
          left: -16,
          height: '720px',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="explore-factor-dialog"
      >
        Select a Development Quality Score to Show
      </DialogTitle>
      <DialogContent sx={{ mb: 1, height: '100%'}}>
        <ExploreFactorPanel
          selection={selection}
          variables={variables}
          factors={factors}
          onSelect={onSelect}
        />
      </DialogContent>
      <Divider variant="middle" />
      <DialogContent sx={{ overflow: 'visible', height: '116px' }}>
        <Typography variant='body2' mb={1}>
          Choose the Groups you want to display on the map
        </Typography>
        <Box sx={{display: 'block', position: 'relative'}}>
          <Stack spacing={2} direction='row'>
            {bucketNames.map((name, idx) => (
              <FormControlLabel
                key={`bucket-select-${idx}`}
                label={name}
                control={<Checkbox checked={buckets[idx]} onChange={(e) => handleBucketChange(e, idx)} />}
              />
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={buckets.every(x => !x)}
          onClick={() => onApply(selectedFactor, bucketNames.filter((x, idx) => buckets[idx]))}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
