import React from 'react';

import { PageLayout } from '@/layouts/PageLayout';
import { LandingMap } from '@/components/molecules';

export const Landing: React.FC = () => {
  return (
    <PageLayout>
      <div className='map-area'>
        <LandingMap />
      </div>
    </PageLayout>
  );
};
