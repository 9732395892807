import React from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-info-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimpleInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};


export const SimpleInfoDialog: React.FC<SimpleInfoDialogProps> = (
  {
    open,
    onClose
  }) => {

  return (
    <Dialog
      aria-labelledby="simple-info-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          maxHeight: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="simple-info-dialog"
      >
        About
      </DialogTitle>
      <DialogContent>
        <Typography>
          This mode allows you to visualise variables for Local Authority Districts.
          Each variable is scored as a percentile from 0 to 100, where 0 is the lowest and 100 is the highest.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography>
          Once a variable is selected the map shows the percentile in a heatmap. When selecting multiple
          variables their percentiles are averaged.
          You can select a Local Authority District to see additional metrics and other useful data points.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography>
          You can also overlay points of interest on the map. These points are clustered together
          and clusters will break apart as you zoom into the map.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
