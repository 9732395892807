import React  from 'react';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import { SimpleFactGroup, SimpleFactScore } from '@/interfaces';
import { formatSimpleFact } from '@/utils/common';

type FactRow = {
  name: string;
  value: number;
  base: number;
  index: number;
};

function createRow(fact: SimpleFactScore): FactRow {
  return {
    name: fact.feature_name,
    value: fact.value,
    base: fact.base,
    index: Number((Math.round(fact.index * 100) / 100).toFixed(2))
  };
}

type SimpleFactCardProps = {
  factGroup: SimpleFactGroup;
  factGroupIdx: number;
};

export const SimpleFactCard: React.FC<SimpleFactCardProps> = (
  {
    factGroup,
    factGroupIdx
  }
) => {

  const theme = useTheme();

  return (
    <Box>
      <Typography mb={2}>{factGroup.name}</Typography>
      {factGroup.scores.map((category, categoryIdx) => (
        <div key={`fact-table-${factGroupIdx}-${categoryIdx}`}>
          <Typography
            key={`category-${factGroup.name}-${categoryIdx}`}
            variant='body2'
            color='primary'
            sx={{ mt: categoryIdx > 0 ? 3 : 0 }}
          >
            {category.name}
          </Typography>
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '55%', pt: 1, pb: 0.5 }}>Name</TableCell>
                <TableCell align="right" sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Base</TableCell>
                <TableCell align="right" sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Value</TableCell>
                <TableCell align="right" sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.text.secondary, width: '15%', pt: 1, pb: 0.5 }}>Index</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {category.scores.map((fact, factIdx) => {
                const row = createRow(fact);
                return (
                  <TableRow key={`fact-row-${factGroupIdx}-${categoryIdx}-${factIdx}`}>
                    <TableCell sx={{ fontSize: '12px', py: 0.5, pr: 0.5 }}>{row.name}</TableCell>
                    <TableCell align="right" sx={{ fontSize: '12px', py: 0.5, color: theme.palette.text.secondary }}>{formatSimpleFact(row.base, category.name)}</TableCell>
                    <TableCell align="right" sx={{ fontSize: '12px', py: 0.5 }}>{formatSimpleFact(row.value, category.name)}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: '12px',
                        color: row.index >= 1 ? theme.palette.success.light : theme.palette.error.main
                      }}
                    >
                      {row.index}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      ))}
    </Box>
  );
}
