import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type UnderlineHeaderProps = {
  text: string,
  color: string
};

export const UnderlineHeader: React.FC<UnderlineHeaderProps> = ({
  text,
  color,
}) => {

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" >{text}</Typography>
      <Box sx={{ height: '4px', width: '100%', backgroundColor: color }}/>
    </Box>
  )
}
