import React  from 'react';

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { LoadTable } from '@/components/atoms';
import { deleteMode } from "@/services/ApiService";
import { useAuth0 } from "@auth0/auth0-react";
import { ExploreFilter } from "@/interfaces";


type LoadTableProps = {
  rows: Array<any>;
  onLoad: (row: ExploreFilter) => void;
  updateFilters: (response: Array<ExploreFilter>) => void;
};

export const ExploreFilterLoadTable: React.FC<LoadTableProps> = ({rows, onLoad, updateFilters}) => {

  const { getAccessTokenSilently } = useAuth0();

  const handleDelete = (name: string) => {
    const callApi = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await deleteMode(
        'filter',
        name,
        accessToken
      );
      updateFilters(response);
    }
    callApi().then();
  };

  const renderName = (params: GridRenderCellParams) => {
    return (
      <Button
        disableRipple
        size='small'
        onClick={() => onLoad(params.row)}
        sx={{ ':hover': { backgroundColor: 'rgba(0,0,0,0)'} }}
      >
        {params.value}
      </Button>
    );
  }

  const renderLoadButton = (params: GridRenderCellParams) => {
    return (
      <IconButton disableRipple onClick={() => onLoad(params.row)}>
        <EditOutlinedIcon fontSize='small'/>
      </IconButton>
    );
  };

  const renderDeleteButton = (params: GridRenderCellParams) => {
    return (
      <IconButton disableRipple onClick={() => handleDelete(params.row.name)}>
        <DeleteOutlineOutlinedIcon fontSize='small'/>
      </IconButton>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      valueFormatter: (params) => { return (new Date(params.value).toUTCString()); },
      type: 'dateTime',
      flex: 3
    },
    {
      field: 'user',
      headerName: 'Load',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: renderLoadButton
    },
    {
      field: 'id',
      headerName: 'Delete',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: renderDeleteButton
    }
  ]

  return (
    <LoadTable rows={rows} columns={columns} />
  );

}
