import React from 'react';
import Draggable from 'react-draggable';

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogActions  from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-info-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExploreInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};


export const ExploreInfoDialog: React.FC<ExploreInfoDialogProps> = (
  {
    open,
    onClose
  }) => {

  return (
    <Dialog
      aria-labelledby="explore-info-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2,
        sx: {
          maxHeight: '80%',
          overflow: 'hidden',
          borderRadius: '6px',
        }
      }}
    >
      <DialogTitle
        id="explore-info-dialog"
      >
        About
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2}}>
          This mode allows you to explore granular locations and find Postal Sectors that meet your development criteria.
        </Typography>
        <Typography sx={{ mb: 3}}>
          The map shows Postal Sectors and the colours represent their score from low (light blue) to high (dark blue).
          The default map shows a Population Density score but as you build filters these colours will change.
          Sectors that do not meet the filter will have no color (white).
        </Typography>

        <Typography variant='h6' sx={{ mb: 2}}>
          Development Quality Scores
        </Typography>
        <Typography sx={{ mb: 1}}>
          Development Quality Scores are made up of 3/4 factors that define an sector's potential for development success.
        </Typography>
        <Typography sx={{ mb: 1}}>
          Each factor is made up of one of more variables that a sector needs to "over index" for to be included.
        </Typography>
        <Typography sx={{ mb: 1}}>
          Each sector is scored based on the number of factors they over index for (weight)
          and which decile their average index score across all factors sits in.
          A high weight and decile means the better the sector's development potential.
        </Typography>
        <Typography sx={{ mb: 3}}>
          The sectors are then assigned a bucket based on these two values.
          Sectors that dont over index for any factors arent included in the result.
        </Typography>

        <Typography variant='h6' sx={{ mb: 2}}>
          Applying a Filter
        </Typography>
        <Typography sx={{ mb: 1}}>
          A filter is built from the variables that you have selected by finding all Postcode Sectors that "over index"
          for all/any (depending on the operator selected) of the variables.
        </Typography>
        <Typography sx={{ mb: 1}}>
          Each sector has a composite score that encompasses their individual score for each variable.
          This composite score is what is shown on the map, the higher the score the darker the color of the Sector.
        </Typography>
        <Typography sx={{ mb: 3}}>
          Any sector that doesn't have a composite score that is above average isn't included in the result.
        </Typography>

        <Typography variant='h6' sx={{ mb: 2}}>
          Overlaying Points of Interest
        </Typography>
        <Typography>
          You can also overlay points of interest on the map by selecting them in the Overlay menu.
          You can change the color of each feature individually to distinguish them better on the map.
        </Typography>

      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
