import React, { useState, useCallback, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import Map, {
  Source, Layer, Marker, NavigationControl, AttributionControl,
  MapboxMap, MapLayerMouseEvent, MapRef, MarkerDragEvent
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { useTheme } from '@mui/material/styles';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {baseSectorLayer, hoverBaselineLayer, hoverCatchmentLayer} from './CatchmentLayers';
import { CatchmentOverlay, CatchmentBaselineOverlay, CatchmentCompareDialog } from '@/components/molecules';
import { Location, MarkerType, CatchmentScoreGroup } from "@/interfaces";
import { getComparisonPost } from '@/services/ApiService';

const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';

export const CatchmentMap = () => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [hovered, setHovered] = useState<number>(0);
  const [cursor, setCursor] = useState<string>('auto');
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<boolean>(false);

  const [catchmentMarker, setCatchmentMarker] = useState<MarkerType | null>(null);
  const [baselineMarker, setBaselineMarker] = useState<MarkerType | null>(null);

  const [mode, setMode] = useState<string>('catchment');
  const [selected, setSelected] = useState<Array<Location>>([]);
  const [catchment, setCatchment] = useState<Array<Location>>([]);
  const [baseline, setBaseline] = useState<Array<Location>>([]);
  const [results, setResults] = useState<Array<CatchmentScoreGroup>>([]);

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    if (mode !== 'compare') {
      const map = event.target;
      const feature = event.features && event.features[0];
      if (feature) {
        let newFeature: Location = {
          geo_id: feature.id ? Number(feature.id) : 0,
          geo_name: feature.properties ? feature.properties.sector : '',
          latitude: feature.properties ? feature.properties.latitude: 0,
          longitude: feature.properties ? feature.properties.longitude: 0,
          level: 'Postal Sector'
        };
        if (event.originalEvent.ctrlKey) {
          if (selected.some(value => value.geo_id === newFeature.geo_id)) {
            const selection = selected.filter(values => values.geo_id !== newFeature.geo_id);
            updateMap(map, [newFeature], mode, 'remove');
            setSelected(selection);
          } else {
            const selection = [...selected, newFeature];
            setSelected(selection);
            updateMap(map, selection, mode, 'add');
          }
        } else if (['catchment', 'baseline'].includes(mode)) {
          setInfo(true);
        }
      }
    }
  }, [selected, mode]);

  const onHover = useCallback(event => {
    if (mode !== 'compare') {
      const feature = event.features && event.features[0];
      // feature && feature._z >= 9 && setHovered(feature.id);
      feature && setHovered(feature.id);
    }
  }, [mode]);

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    mode === 'catchment' ?
      setCatchmentMarker({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat
      })
    : setBaselineMarker({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat
      });
  }, [mode]);

  const clearMap = (map: MapboxMap | MapRef, mode: string) => {
    let state: {[k: string]: boolean} = {};
    state[mode] = false;
    selected.forEach(sector => {
      map.setFeatureState(
        {id: sector.geo_id, source: 'postal-sector-source', sourceLayer: 'Sector_TCE'},
        state
      )
    });

    mode === 'catchment' &&
      catchment.forEach(sector => {
        map.setFeatureState(
          {id: sector.geo_id, source: 'postal-sector-source', sourceLayer: 'Sector_TCE'},
          state
        )
      });

    mode === 'baseline' &&
    baseline.forEach(sector => {
      map.setFeatureState(
        {id: sector.geo_id, source: 'postal-sector-source', sourceLayer: 'Sector_TCE'},
        state
      )
    });
  };

  const updateMap = (map: MapboxMap | MapRef, sectors: Array<Location>, mode: string, type: string) => {
    let state: {[k: string]: boolean} = {};
    state[mode] = type === 'add';
    sectors.forEach(sector => {
      map.setFeatureState(
        {id: sector.geo_id, source: 'postal-sector-source', sourceLayer: 'Sector_TCE'},
        state
      );
    })
  };

  const updateCatchment = (selection: Array<Location>) => {
    setCatchment(selection);
    if (selection.length > 0) setMode('baseline');
  };

  const updateBaseline = (selection: Array<Location>, location: Location | null) => {
    setBaseline(selection);
    setHovered(0);
    if (selection.length > 0) {
      if (location && ['Country', 'Region'].includes(location.level)) {
        onCompare(selection, location);
      } else {
        onCompare(selection, null);
      }
    }
  };

  const enableCatchmentMode = () => {
    setMode('catchment');
    setSelected(catchment);
    setCatchment([]);
    setBaseline([]);
    setBaselineMarker(null);
  }

  const enableBaselineMode = () => {
    setMode('baseline');
    setSelected(baseline);
    setBaseline([]);
  }

  const onCompare = (baselineSectors: Array<Location>, baselineLocation: Location | null) => {
    const callApi = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      return await getComparisonPost(
        catchment,
        baselineSectors,
        baselineLocation,
        accessToken
      );
    }
    callApi().then((response) => {
      setLoading(false);
      setResults(response);
      setMode('compare');
    })
  }

  const onMouseEnter = useCallback((e: MapLayerMouseEvent) => mode !== 'compare' && setCursor('pointer'), [mode]);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const catchmentFilter = useMemo(() =>
    ['==', ['id'], mode === 'catchment' ? hovered : null],
    [mode, hovered]);
  const baselineFilter = useMemo(() =>
    ['==', ['id'], mode === 'baseline' ? hovered : null],
    [mode, hovered]);

  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfo(false);
  };

  return (
    <Map
      initialViewState={{
        longitude: -2,
        latitude: 53.5,
        zoom: 9
      }}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      cursor={cursor}
      // onLoad={onLoad}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onMouseMove={onHover}
      interactiveLayerIds={['base-sector']}
      attributionControl={false}
      dragRotate={false}
    >
      <AttributionControl
        customAttribution='Contains public sector information licensed under the Open Government Licence v3.0.'
      />
      <NavigationControl
        position='bottom-right'
        showCompass={false}
        visualizePitch={false}
        style={{ marginBottom: '16px', marginRight: '16px' }}
      />
      {catchmentMarker &&
        <Marker
          longitude={catchmentMarker.longitude}
          latitude={catchmentMarker.latitude}
          color={theme.palette.secondary.main}
          anchor="bottom"
          draggable={mode === 'catchment'}
          onDragEnd={onMarkerDrag}
        />
      }
      {baselineMarker &&
        <Marker
          longitude={baselineMarker.longitude}
          latitude={baselineMarker.latitude}
          color={theme.palette.primary.main}
          anchor="bottom"
          draggable={mode === 'baseline'}
          onDragEnd={onMarkerDrag}
        />
      }
      <Source id='postal-sector-source' type='vector' url='mapbox://mpilarczykstarcount.Sector_TCE'>
        <Layer beforeId="waterway-label" {...baseSectorLayer} />
        <Layer beforeId="waterway-label" {...hoverCatchmentLayer} filter={catchmentFilter}/>
        <Layer beforeId="waterway-label" {...hoverBaselineLayer} filter={baselineFilter}/>
      </Source>
      <CatchmentOverlay
        disabled={mode !== 'catchment'}
        enable={enableCatchmentMode}
        selected={selected}
        setSelected={setSelected}
        catchment={catchment}
        setCatchment={updateCatchment}
        marker={catchmentMarker}
        updateMap={updateMap}
        clearMap={clearMap}
        setMarker={setCatchmentMarker}
        loading={loading}
        setLoading={setLoading}/>
      <CatchmentBaselineOverlay
        disabled={mode !== 'baseline'}
        enable={enableBaselineMode}
        selected={selected}
        setSelected={setSelected}
        baseline={baseline}
        setBaseline={updateBaseline}
        marker={baselineMarker}
        updateMap={updateMap}
        clearMap={clearMap}
        setMarker={setBaselineMarker}
        loading={loading}
        setLoading={setLoading}/>
      {mode === 'compare' &&
        <CatchmentCompareDialog results={results}/>
      }
      <Snackbar
        open={info}
        autoHideDuration={6000}
        onClose={handleInfoClose}
        sx={{ mb: 2, ml: 2 }}
      >
        <Alert
          onClose={handleInfoClose}
          color='error'
          severity='info'
          sx={{ background: theme.palette.background.default, borderRadius: '6px'}}
        >
          Hold ctrl to add/remove a sector
        </Alert>
      </Snackbar>
    </Map>
  );
}
