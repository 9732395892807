import React from 'react';

import { NavBar } from '@/components/molecules'

interface PageLayoutProps {
  title?: string;
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ title, children }) => {

  return (
    <div>
      <NavBar title={title}/>
      <div className='content'>
        {children}
      </div>
    </div>
  );
}
