import { FillLayer } from "react-map-gl";

export const baseSectorLayer: FillLayer = {
  id: 'base-sector',
  type: 'fill',
  source: 'postal-sector-source',
  'source-layer': 'Sector_TCE',
  paint: {
    'fill-outline-color': [
      'case', ['boolean', ['feature-state', 'highlight'], false], '#ccc4de',
      ['case', ['boolean', ['feature-state', 'catchment'], false], '#8A77B4',
        ['case', ['boolean', ['feature-state', 'baseline'], false], '#26cae5',
          'rgba(0,0,0,0)'
        ]
      ]
    ],
    'fill-color': [
      'case', ['boolean', ['feature-state', 'hover'], false], '#ffffff',
      ['case', ['boolean', ['feature-state', 'highlight'], false], '#ccc4de',
        ['case', ['boolean', ['feature-state', 'catchment'], false], '#8A77B4',
          ['case', ['boolean', ['feature-state', 'baseline'], false], '#82e1f2',
            'rgba(0,0,0,0)'
          ]
        ]
      ]
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.8,
      [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        1.0,
        0.8
      ]
    ]
  },
  // minzoom: 8,
  // maxzoom: 15
};

export const hoverCatchmentLayer: FillLayer = {
  id: 'highlight-catchment',
  type: 'fill',
  'source-layer': 'Sector_TCE',
  paint: {
    'fill-outline-color': '#8A77B4',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.8
  },
  minzoom: 9,
};

export const hoverBaselineLayer: FillLayer = {
  id: 'highlight-baseline',
  type: 'fill',
  'source-layer': 'Sector_TCE',
  paint: {
    'fill-outline-color': '#82e1f2',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.8
  },
  minzoom: 9,
};
