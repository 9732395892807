import React from 'react';
import Draggable from 'react-draggable';

import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { PointFact } from "@/interfaces";
import { FactPoint } from "@/components/molecules";


function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#explore-fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type ExplorePointFactDialogProps = {
  fact: PointFact;
  resultsOpen: boolean;
  onClose: () => void;
};


export const ExplorePointFactDialog: React.FC<ExplorePointFactDialogProps> = ({
    fact,
    resultsOpen,
    onClose,
  }) => {

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        top: resultsOpen ? 580 + 64 + 16 : 64,
        right: 68,
        width: '600px',
        maxHeight: resultsOpen ? 'calc(90% - 580px - 16px)' : '90%',
        // height: '90%',
        overflow: 'hidden',
        borderRadius: '6px',
    }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <CardHeader id='explore-fact-dialog' title={fact.name} sx={{ pb: 0 }}/>
        <CardContent sx={{ overflowY: 'scroll' }}>
          {fact.extra_data &&
            <FactPoint data={fact.extra_data} />
          }
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </CardActions>
      </Card>
    </DraggablePaper>
  );
}
