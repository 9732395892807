import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { PageLayout } from '@/layouts/PageLayout';
import { ExploreMapGL } from '@/components/molecules';
import { getPointFeatures } from "@/services/ApiService";
import { ExplorePoint } from '@/interfaces';


export const Explore: React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();
  const [pointFeatures, setPointFeatures] = useState<Array<ExplorePoint>>([]);

  useEffect(() => {
    const callApi = async () => {
      const accessToken = await getAccessTokenSilently();
      return await getPointFeatures(accessToken);
    };
    callApi().then((response) => setPointFeatures(response))
  }, []);

  const updateFeaturesSelected = (selected: string) => {
    const update = [...pointFeatures];
    update.forEach((point) => {
      if (selected === 'none') {
        point.selected = false
      } else if (point.type === selected) {
        point.selected = !point.selected
      }
    });
    setPointFeatures(update);
  };

  const updateFeatureColor = (feature: string, color: string) => {
    const update = [...pointFeatures];
    update.forEach((point) => {
      if (point.type === feature) {
        point.color = color
      }
    });
    setPointFeatures(update);
  };

  return (
    <PageLayout title='Explore'>
      <div className='map-area'>
        {pointFeatures.length > 0 &&
          <ExploreMapGL
            pointFeatures={pointFeatures}
            updateFeaturesSelected={updateFeaturesSelected}
            updateFeatureColor={updateFeatureColor}
          />
        }
      </div>
    </PageLayout>
  )
}
