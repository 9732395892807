import React, { useState } from 'react';
import Draggable from 'react-draggable';

import { useTheme } from "@mui/material/styles";
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';

import { SimpleFact, SimpleScoreCard, SimpleScoreCardCategory, SimpleScoreCardGroup } from "@/interfaces";
import { RadarChart, Tooltip } from "@/components/atoms";
import { SimpleFactCard, SimpleScorecardCard, SimpleScorecardPanel } from '@/components/molecules';
import { toTopFacts } from '@/utils/facts';
import { getIconFromName } from '@/utils/icons';
import { formatPercentile, formatScore, toColor } from "@/utils/common";

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#simple-fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type SimpleFactDialogProps = {
  fact: SimpleFact;
  scorecard: SimpleScoreCard;
  onClose: () => void;
};

const CLASS_COLORS: { [key: string]: string } = {
  'A': '#81c695',
  'B1': '#e5ffac',
  'B2': '#FBE566',
  'C': '#F3546D',
}

const CLASS_TEXT_COLORS: { [key: string]: string } = {
  'A': '#212b3a',
  'B1': '#212b3a',
  'B2': '#212b3a',
  'C': '#212b3a',
}


export const SimpleFactDialog: React.FC<SimpleFactDialogProps> = ({
    fact,
    scorecard,
    onClose,
  }) => {

  const theme = useTheme();
  const [expand, setExpand] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('card');

  const groups = fact.scores.map(x => x.name);
  const factGroupIdx = groups.indexOf(mode);
  const factGroup = fact.scores.filter(x => x.name === mode);

  const toggleMode = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
    if (newMode) {
      if (newMode === 'open') {
        setExpand(true);
      } else if (newMode === 'close') {
        setExpand(false);
      } else {
        setMode(newMode);
        setExpand(false);
      }
    }
  };

  const flattenScores = (scores: Array<SimpleScoreCardGroup>) => {
    return scores.map(x => x.scores.map(y => ({group_name: x.name, ...y}))).flat();
  }

  const width = expand ? '90%' : '40%';

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 64,
        right: 16,
        width: width,
        minWidth: expand ? '90%': '500px',
        height: 'calc(100% - 64px - 36px)',
        // overflowY: 'hidden',
        borderRadius: '6px',
    }}
    >
      <>
        <ToggleButtonGroup
          orientation="vertical"
          value={mode}
          color="primary"
          exclusive
          onChange={toggleMode}
          sx={{
            // width: 50,
            position: "absolute",
            // top: 64,
            left: -44,
            background: theme.palette.background.paper,
            borderRadius: '6px 0px 0px 6px'
          }}
        >
          <ToggleButton value="card">
            <Tooltip title="Place Based Scorecard" placement="left">
              <ScoreboardIcon fontSize="small" />
            </Tooltip>
          </ToggleButton>

          {/*{mode === 'card' && !expand &&*/}
          {/*  <ToggleButton*/}
          {/*    value="open"*/}
          {/*    sx={{*/}
          {/*      backgroundColor: theme.palette.primary.dark,*/}
          {/*      ':hover': {backgroundColor: theme.palette.primary.main}*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Tooltip title="Expand" placement="left">*/}
          {/*      <ArrowBackIosNewIcon fontSize="small" />*/}
          {/*    </Tooltip>*/}
          {/*  </ToggleButton>*/}

          {/*}*/}
          {/*{mode === 'card' && expand &&*/}
          {/*  <ToggleButton*/}
          {/*    value="close"*/}
          {/*    sx={{*/}
          {/*      backgroundColor: theme.palette.primary.dark,*/}
          {/*      ':hover': {backgroundColor: theme.palette.primary.main}*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Tooltip title="Close" placement="left">*/}
          {/*      <ArrowForwardIosIcon fontSize="small" />*/}
          {/*    </Tooltip>*/}
          {/*  </ToggleButton>*/}
          {/*}*/}
          {groups.map((group) => (
            <ToggleButton key={`toggle-${group}`} value={group}>
              <Tooltip title={group} placement="left">
                {getIconFromName(group, {fontSize: 'small'})}
              </Tooltip>
            </ToggleButton>

          ))}
        </ToggleButtonGroup>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <CardHeader
            id='simple-fact-dialog'
            disableTypography
            title={
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Chip
                    label={fact.class}
                    size="small"
                    sx={{
                      borderRadius: '4px',
                      mr: 1,
                      backgroundColor: CLASS_COLORS[fact.class],
                      color: CLASS_TEXT_COLORS[fact.class]
                    }}/>
                  <Typography variant="h5">
                    {fact.name}
                  </Typography>
                </Box>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            }
            sx={{ pb: 1 }}
          />
          {mode === 'card' ?
            <CardContent sx={{mt: 0, mb: 2, pr: 0, height: expand ? 'calc(100% - 48px - 52px)' : null, overflowY: 'auto'}}>
              {expand ?
                <SimpleScorecardPanel scorecard={scorecard} />
                :
                <>
                  <Grid
                    container
                    spacing={2}
                    flexDirection="column"
                    alignContent={expand ? "flex-start" : "center"}
                    alignItems="center"
                    sx={{ height: expand ? 'calc(100% - 52px - 32px)' : null, width: '100%' }}
                  >
                    <Grid item xs={12}>
                      <RadarChart
                        values={[
                          flattenScores(scorecard.scores.filter(x => ['Hard', 'Soft'].includes(x.name))).map(x => x.percentile_wt),
                          flattenScores(scorecard.scores.filter(x => ['Hard', 'Soft'].includes(x.name))).map(x => x.percentile)
                        ]}
                        labels={flattenScores(scorecard.scores.filter(x => ['Hard', 'Soft'].includes(x.name))).map(x => x.name)}
                        names={['Enterprise', 'Default']}
                        height={400}
                        width={400}
                        padding={0.1}
                      />
                    </Grid>
                    <Grid item xs={12} width="100%">
                      <Box  display="flex" flexDirection="row" justifyContent="flex-end">
                        <Tooltip title='Default scores' placement="top">
                          <Typography variant="caption" sx={{fontSize: '10px'}}>Default</Typography>
                        </Tooltip>
                        <Tooltip title='Weighted scores to align with strategic priorities' placement="top">
                        <Typography variant="caption" sx={{ml: '10px', fontSize: '10px'}}>Enterprise</Typography>
                        </Tooltip>
                      </Box>
                    </Grid>
                    {scorecard.scores.map((group, groupIdx) => (
                      <>
                        <Grid item xs={12} key={`group-${groupIdx}`} sx={{mt: groupIdx !== 0 ? 2 : 0}} width="100%">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{pr: 1}}>
                          <Typography variant="h6">{group.name}</Typography>
                            {group.name !== 'Opportunity' &&
                              <Box display="flex" flexDirection="row">
                                <Chip
                                  size="small"
                                  label={formatScore(['Strategic Sectors', 'Group Strategic Priorities'].includes(group.name) ? group.percentile_wt : group.percentile, 'pct_score')}
                                  sx={{ borderRadius: '6px', minWidth: '32px', backgroundColor: toColor(['Strategic Sectors', 'Group Strategic Priorities'].includes(group.name) ? group.percentile_wt : group.percentile), color: theme.palette.background.default }}
                                />
                                {['Hard', 'Soft'].includes(group.name) &&
                                  <Chip
                                    size="small"
                                    label={formatScore(group.percentile_wt, 'pct_score')}
                                    sx={{ ml: 2, borderRadius: '6px', minWidth: '32px', backgroundColor: toColor(group.percentile_wt), color: theme.palette.background.default }}
                                  />
                                }
                              </Box>
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            {group.description}
                          </Typography>
                        </Grid>
                        {group.scores.map((category, categoryIdx) => (
                          <Grid item key={`card-${groupIdx}-${categoryIdx}`} width={"100%"}>
                            <SimpleScorecardCard
                              key={`score-card-${categoryIdx}`}
                              category={category}
                              expand={expand}
                              fullWidth={true}
                              border="none"
                              opportunity={group.name === 'Opportunity'}
                              showCategoryScore={group.name !== 'Opportunitys'}
                              showEnterpriseScore={['Hard', 'Soft'].includes(group.name)}
                              showWeightedScore={['Strategic Sectors', 'Group Strategic Priorities'].includes(group.name)}
                            />
                          </Grid>
                        ))}
                        <Grid item xs={12} mt={2} width="100%">
                          <Divider light />
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={12} mt={2}>
                      <Typography variant="caption">* Areas that TCE can have an influence</Typography>
                    </Grid>
                  </Grid>
                </>
              }
            </CardContent>
            :
            <CardContent sx={{ mb: 2, overflowY: 'auto'}} >
              {['Interests', 'Mindsets', 'Causes'].includes(mode) ?
                <SimpleFactCard factGroup={toTopFacts(factGroup[0], 20)} factGroupIdx={factGroupIdx}/>
                :
                <SimpleFactCard factGroup={factGroup[0]} factGroupIdx={factGroupIdx}/>
              }
            </CardContent>
          }
        </Card>
      </>
    </DraggablePaper>
  );
}
