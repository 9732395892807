import React, { useState, useEffect } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme, alpha } from '@mui/material/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


import { SimpleScoreCardCategory } from '@/interfaces';
import { Tooltip } from '@/components/atoms';
import { formatScore, formatPercentile, toColor } from '@/utils/common';

type SimpleScorecardCardProps = {
  category: SimpleScoreCardCategory;
  expand: boolean;
  fullWidth?: boolean;
  border?: "left" | "right" | "top" | "bottom" | "none";
  opportunity?: boolean;
  showCategoryScore?: boolean;
  showEnterpriseScore?: boolean;
  showWeightedScore?: boolean;
};

export const SimpleScorecardCard: React.FC<SimpleScorecardCardProps> = (
  {
    category,
    expand,
    fullWidth,
    border,
    opportunity = false,
    showCategoryScore = true,
    showEnterpriseScore = true,
    showWeightedScore = false
  }
) => {

  useEffect(() => {
    setSubExpand(expand)
  }, [expand]);

  const theme = useTheme();
  const [subExpand, setSubExpand] = useState<boolean>(false);

  // const groupScore = scores.filter(x => x.index !== null).reduce((total, next) => total + next.index, 0) / scores.filter(x => x.index !== null).length;
  // const groupColor = toColor(groupScore);

  const onExpand = () => {
    setSubExpand(!subExpand);
  }

  return (
    <Card
      elevation={6}
      sx={{
        width: fullWidth ? '100%' : expand ? '220px' : '400px',
        borderLeft: showCategoryScore && border === 'left' ? `2px solid ${toColor(category.percentile)}` : 'none',
        borderRight: showCategoryScore && border === 'right' ? `2px solid ${toColor(category.percentile)}` : 'none',
        borderTop: showCategoryScore && border === 'top' ? `2px solid ${toColor(category.percentile)}` : 'none',
        borderBottom: showCategoryScore && border === 'bottom' ? `2px solid ${toColor(category.percentile)}` : 'none',
      }}
    >
      {!expand &&
        <CardActionArea onClick={onExpand} disabled={expand}>
          <CardHeader
            disableTypography
            title={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography>{category.name}</Typography>
              {!expand && showCategoryScore &&
                <Box display="flex" flexDirection="row">
                  <Chip
                    size="small"
                    label={formatScore(showWeightedScore ? category.percentile_wt : category.percentile, 'pct_score')}
                    sx={{borderRadius: '6px', minWidth: '32px', backgroundColor: alpha(toColor(showWeightedScore ? category.percentile_wt : category.percentile), 0.8), color: theme.palette.background.default}}
                  />
                  {showEnterpriseScore &&
                    <Chip
                      size="small"
                      label={formatScore(category.percentile_wt, 'pct_score')}
                      sx={{ml: 2, borderRadius: '6px', minWidth: '32px', backgroundColor: alpha(toColor(category.percentile_wt), 0.8), color: theme.palette.background.default}}
                    />
                  }
                </Box>
              }
            </Box>
            }
            sx={{
              p: 1
              // pb: subExpand ? 0 : 1
            }}
          />
        </CardActionArea>
      }
      {subExpand &&
        <CardContent sx={{ p: 1, '&:last-child': {pb: 1} }}>
          {category.scores.map((score, scoreIdx) => (
            <Box key={`score-card-${scoreIdx}`} display="flex" flexDirection="row" justifyContent="space-between" pr='10px'>
              <Tooltip title={score.description} placement="left">
                <Typography variant="body2" sx={{fontSize: '12px'}}>{`${score.feature_name}${showCategoryScore && score.influence || 0 > 0 ? '*' : ''}`}</Typography>
              </Tooltip>
              <Box display="flex" flexDirection="row" alignItems="center">
                {opportunity ?
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                      {formatScore(score.value, score.unit)}
                    </Typography>
                    <ArrowRightAltIcon fontSize="small" sx={{ mx: '2px' }}/>
                    <Typography variant="body2" sx={{fontSize: '12px'}}>
                      {formatScore(score.base, score.unit)}
                    </Typography>
                  </Box>
                :
                  <Typography variant="body2" sx={{fontSize: '12px'}}>
                    {formatScore(score.value, score.unit)}
                  </Typography>
                }
                <Box display="flex" flexDirection="row">
                  <Tooltip title={formatPercentile(showWeightedScore ? score.percentile_wt : score.percentile)} placement="right">
                    <Box sx={{ ml: 2, height: '12px', width: '12px', borderRadius: '2px', backgroundColor: alpha(toColor(showWeightedScore ? score.percentile_wt : score.percentile), 0.8) }} />
                  </Tooltip>
                  {showEnterpriseScore &&
                    <Tooltip title={formatPercentile(score.percentile_wt)} placement="right">
                      <Box sx={{ ml: '36' +
                          'px', height: '12px', width: '12px', borderRadius: '2px', backgroundColor: alpha(toColor(score.percentile_wt), 0.8) }} />
                    </Tooltip>
                  }
                </Box>
              </Box>
            </Box>
          ))}
        </CardContent>
      }
    </Card>
  );
}
