import React from 'react';

import fixedVariables from './fixedVariables.json';
import { ExploreFactValueCard } from '@/components/molecules';
import { ExploreFact } from "@/interfaces";

type Scores = {
  [key: string]: any;
}

type FactDemographicsProps = {
  data: Scores;
  level: string;
};

export const FactDemographics: React.FC<FactDemographicsProps> = ({
  data, level
}) => {

  const newData = Object.values(data).reduce((res, cur) => Object.assign(res, cur), {});
  Object.keys(newData).forEach((source) => {
    const fixes = fixedVariables.filter(x => x.source === source);
    if (fixes.length > 0) {
      let sortData: Array<ExploreFact> = [];
      fixes[0].keys.forEach(x => sortData.push(newData[source].filter((y: ExploreFact) => y.type === x)[0]));
      newData[source] = sortData;
    }
  })

  return (
    <>
      <ExploreFactValueCard title='Demographics' scores={newData} category={null} />
    </>
  );
};
