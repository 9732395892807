import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatScore } from "@/utils/common";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

type ScorecardDoubleScoreProps = {
  name: string,
  firstScore: number,
  firstColor: string,
  secondScore: number;
  secondColor: string;
};

export const ScorecardDoubleScore: React.FC<ScorecardDoubleScoreProps> = ({
  name,
  firstScore,
  firstColor,
  secondScore,
  secondColor
}) => {

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body1" mr={1}>{name}</Typography>
      {name !== 'Opportunity' &&
        <Stack direction="row" spacing={4}>
          <Chip
            size="small"
            label={formatScore(firstScore, 'pct_score')}
            sx={{borderRadius: '6px', minWidth: '32px', backgroundColor: firstColor, color: theme.palette.background.default}}
          />
          <Chip
            size="small"
            label={formatScore(secondScore, 'pct_score')}
            sx={{borderRadius: '6px', minWidth: '32px', backgroundColor: secondColor, color: theme.palette.background.default}}
          />
        </Stack>
      }
    </Box>
  )
}
