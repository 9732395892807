import React from 'react';
import Draggable from 'react-draggable';
import { useAuth0 } from "@auth0/auth0-react";

import Dialog  from '@mui/material/Dialog';
import DialogTitle  from '@mui/material/DialogTitle';
import DialogContent  from '@mui/material/DialogContent';
import DialogContentText  from '@mui/material/DialogContentText';
import DialogActions  from '@mui/material/DialogActions';
import Box  from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { deleteMode } from '@/services/ApiService';

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#load-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type LoadDialogProps = {
  open: boolean;
  mode: string;
  options: Array<any>;
  onCancel: () => void;
  onLoad: (row: object) => void;
  onDelete: (response: Array<any>) => void;
};

export const LoadDialog: React.FC<LoadDialogProps> = (
{
  open,
  mode,
  options,
  onCancel,
  onLoad,
  onDelete
}) => {

  const { getAccessTokenSilently } = useAuth0();

  const renderName = (params: GridRenderCellParams) => {
    return (
      <Button
        disableRipple
        onClick={() => onLoad(params.row)}
        sx={{ ':hover': { backgroundColor: 'rgba(0,0,0,0)'} }}
      >
        {params.value}
      </Button>
    );
  }

  const renderLoadButton = (params: GridRenderCellParams) => {
    return (
      <IconButton disableRipple onClick={() => onLoad(params.row)}>
        <EditOutlinedIcon/>
      </IconButton>
    );
  };

  const renderDeleteButton = (params: GridRenderCellParams) => {
    return (
      <IconButton disableRipple onClick={() => handleDelete(params.row.name)}>
        <DeleteOutlineOutlinedIcon/>
      </IconButton>
    );
  };

  const columns: GridColDef[] = [
    { field: 'name',
      headerName: 'Name',
      flex: 5,
      renderCell: renderName
    },
    { field: 'created_at', headerName: 'Created Date',
      valueFormatter: (params) => { return (new Date(params.value).toUTCString()); },
      type: 'dateTime',
      flex: 3
    },
    { field: 'user', headerName: 'Load',
      flex: 1, align: 'center', headerAlign: 'center', sortable: false,
      renderCell: renderLoadButton
    },
    { field: 'id', headerName: 'Delete',
      flex: 1, align: 'center', headerAlign: 'center', sortable: false,
      renderCell: renderDeleteButton
    }
  ]

  const handleDelete = (name: string) => {
    const callApi = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await deleteMode(
        mode,
        name,
        accessToken
      );
      onDelete(response);
    }
    callApi().then();
  };

  return (
    <Dialog
      aria-labelledby="load-dialog"
      open={open}
      fullWidth
      maxWidth='md'
      PaperComponent={DraggablePaper}
      PaperProps={{
        elevation: 2
      }}
    >
      <DialogTitle
        id="load-dialog"
        sx={{ textTransform: 'capitalize' }}
      >
        {`Your Saved ${mode}s`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          {`Select the ${mode} you wish to load.`}
        </DialogContentText>
        <Box sx={{ height: '400px' }}>
          <DataGrid
            rows={options}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu
            autoPageSize
            hideFooterSelectedRowCount
            sx={{
              border: 'none',
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none'
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-withBorder': {
                borderRight: 'none',
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
