import { SimpleFactGroup, SimpleFactCategory } from "@/interfaces";

export const toTopFacts = (group: SimpleFactGroup, n=10) => {
  const groupScores = group.scores.map((category) => category.scores).flat();
  const sortedGroupScores = groupScores.sort((a,b) => b.index - a.index);
  const newCategory: SimpleFactCategory = {name: `Top ${group.name}`, scores: sortedGroupScores.slice(0, n)};
  const newGroup: SimpleFactGroup = {'name': group.name, scores: [newCategory]};
  return newGroup
};

export function toIndexColor(score: number | null){
  if (score === null) {
    return '#F4F4F4'
  } else {
    if (score < 0.75) {
      return '#F3546D'
    } else if (score < 1) {
      return '#FBB073'
    } else if (score < 1.25) {
      return '#FBE566'
    } else {
      return '#3caf69'
    }
  }
}

export function toPercentileColor(score: number | null){
  if (score === null) {
    return '#F4F4F4'
  } else {
    if (score < 0.25) {
      return '#F3546D'
    } else if (score < 0.5) {
      return '#FBB073'
    } else if (score < 0.75) {
      return '#FBE566'
    } else {
      return '#3caf69'
    }
  }
}
