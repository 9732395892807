import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { LogoButton, UserProfile } from '@/components/atoms';

interface NavBarProps {
  title?: string;
}

export const NavBar: React.FC<NavBarProps> = ({title}) => {

  return (
    <AppBar position='relative' elevation={4} sx={{ height: '48px' }}>
      <Toolbar variant='dense' sx={{ justifyContent: 'space-between' }}>
        <Stack direction='row' spacing={4} sx={{ alignItems: 'center' }}>
        <LogoButton route='/'/>
          {title &&
            <Typography variant='h6' sx={{textTransform: 'uppercase'}}>{title}</Typography>
          }
        </Stack>
        <UserProfile />
      </Toolbar>
    </AppBar>
  );
}
