import React  from 'react';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ExploreFactorFact } from '@/interfaces';

type FactRow = {
  name: string;
  factor_1: number;
  factor_2: number;
  factor_3: number;
  factor_4: number;
  score: number;
  weight: number;
  percentile: number;
  category: string;
};

function createRow(fact: ExploreFactorFact): FactRow {
  return {
    name: fact.factor_name,
    factor_1: Math.round(fact.factor_1),
    factor_2: Math.round(fact.factor_2),
    factor_3: Math.round(fact.factor_3),
    factor_4: Math.round(fact.factor_4),
    score: Math.round(fact.score),
    weight: fact.weight,
    percentile: Math.round(fact.percentile),
    category: fact.category
  };
}

type FactorResultCardProps = {
  results: Array<ExploreFactorFact>;
};

export const ExploreFactorResultCard: React.FC<FactorResultCardProps> = (
  {
    results
  }
) => {

  const theme = useTheme();

  return (
    <Card elevation={4} sx={{ mb: 3 }}>
      <CardHeader title='Development Quality Scores' titleTypographyProps={{ variant: 'body1' }} sx={{ pb: 0 }}/>
      <CardContent>
        {results.length > 0 ?
          <Table size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 400, fontSize: '12px', pb: 1, width: '30%', color: theme.palette.text.secondary}}>Name</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 1</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 2</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 3</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Factor 4</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Weight</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Decile</TableCell>
                <TableCell align='center' sx={{fontWeight: 400, fontSize: '12px', width: '10%', color: theme.palette.text.secondary}}>Bucket</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((fact, factIdx) => {
                const row = createRow(fact);
                return (
                  <TableRow key={`fact-row-${factIdx}`}>
                    <TableCell align='left' sx={{fontSize: '12px', py: 1}}>{row.name}</TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_1 > 100 ? theme.palette.success.light : row.factor_1 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_1}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_2 > 100 ? theme.palette.success.light : row.factor_2 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_2}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_3 > 100 ? theme.palette.success.light : row.factor_3 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_3}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.factor_4 > 100 ? theme.palette.success.light : row.factor_4 === 100 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.factor_4}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.weight > 2 ? theme.palette.success.light : row.weight === 2 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.weight}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.percentile > 5 ? theme.palette.success.light : row.percentile === 5 ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.percentile}
                    </TableCell>
                    <TableCell align='center' sx={{
                      fontSize: '12px',
                      color: row.category === 'High' ? theme.palette.success.light : row.category === 'Medium' ? theme.palette.text.primary :theme.palette.error.main
                    }}>
                      {row.category}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          :
          <Typography
            variant='body2'
            align='center'
          >
            No Development Quality Score data available for this Sector
          </Typography>
        }
      </CardContent>
    </Card>
  );
}
